<div class="p-4 md:px-6">
  <div class="flex flex-col gap-2">
    <!-- Loading State -->
    @if (loading()) {
    <div class="col-span-full text-center py-12 bg-grey-50 rounded-lg">
      <div
        class="animate-spin rounded-full h-12 w-12 border-b-2 border-grey-900 mx-auto"
      ></div>
      <h3 class="mt-2 text-sm font-medium text-grey-900">
        Loading Insight Models...
      </h3>
    </div>
    }

    <!-- Error State -->
    @else if (error()) {
    <div class="col-span-full text-center py-12 bg-red-50 rounded-lg">
      <svg
        class="mx-auto h-12 w-12 text-red-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-red-900">
        Error Loading Insight Models
      </h3>
      <p class="mt-1 text-sm text-red-700">{{ error() }}</p>
      <button
        (click)="loadInsightModels()"
        class="mt-4 px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors duration-200"
      >
        Retry
      </button>
    </div>
    }

    <!-- Data State -->
    @else {
    <!-- Refresh button -->
    <div class="flex flex-row justify-between items-center">
      <h1 class="text-2xl font-bold text-grey-900">Insight Models</h1>
      <button
        class="px-4 py-2 text-sm font-medium text-grey-700 bg-white border border-grey-300 rounded-md hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        [disabled]="loading()"
        (click)="refreshData()">
        <lucide-angular
          [img]="refresh"
          class="h-4"
          [class.animate-spin]="loading()"
        />
      </button>
    </div>

    @for (insightModel of insightModels(); track insightModel) {
      <app-admin-model-display
        [insightModel]="insightModel"
      ></app-admin-model-display>
    } @empty {
    <div class="col-span-full text-center py-12 bg-grey-50 rounded-lg">
      <svg
        class="mx-auto h-12 w-12 text-grey-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-grey-900">No Insight Models</h3>
    </div>
    } }
  </div>
</div>
