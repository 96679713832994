<router-outlet></router-outlet>
@if (!isEditingOrCreating()) {
<div class="p-4 md:px-6">

  <!-- Loading State -->
  @if (loading()) {
  <div class="flex justify-center items-center p-8">
    <div class="w-8 h-8 border-4 border-primary-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
  }

  <!-- Error State -->
  @if (error()) {
    <app-error-display [error]="error() || ''" (retry)="refreshData()"></app-error-display>
  }

  <!-- Actions -->
  <div class="mb-4 flex justify-between items-center">
    <div class="flex flex-col gap-1">
      <h1 class="text-2xl font-bold text-grey-900">Question Sets</h1>
      <div class="text-xs text-grey-500">
        Last fetched: {{ lastFetchedTime() }}
      </div>
    </div>

    <div class="flex gap-2">
      <button (click)="addNewSet()"
        class="px-4 py-2 text-sm font-medium text-secondary-default bg-primary-default rounded-md hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
        New Question Set
      </button>

      <button
        class="px-4 py-2 text-sm font-medium text-grey-700 bg-white border border-grey-300 rounded-md hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        [disabled]="loading()"
        (click)="refreshData()">
        <lucide-angular
          [img]="refresh"
          class="h-4"
          [class.animate-spin]="loading()"
        />
      </button>
    </div>
  </div>

  <!-- Question Sets Grid -->
  <div class="flex flex-col gap-2">
    @for (set of questionSets(); track set._id) {
    <div class="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
      <div class="p-4">
        <div class="flex justify-between items-start">
          <div>
            <h3 class="text-lg font-semibold text-grey-900">{{ set.name }}</h3>
            <p class="mt-1 text-sm text-grey-600">{{ set.description }}</p>
          </div>
          <div class="flex gap-2">
            <button
              class="text-grey-400 hover:text-primary-600 transition-colors"
              (click)="editSet(set)">
              <lucide-angular
                [img]="edit"
                class="h-4"
              />

            </button>
            <button
              class="text-grey-400 hover:text-danger-600 transition-colors"
              (click)="deleteSet(set._id)">
              <lucide-angular
                [img]="delete"
                class="h-4"
              />
            </button>
          </div>
        </div>

        <div class="mt-2 flex gap-2">
          <div class="text-sm text-grey-600">
            <span class="font-medium">Questions:</span> {{ set.questions.length }}
          </div>
          <div class="text-sm text-grey-600">
            <span class="font-medium">Insight Model:</span> {{ set.targetInsightModel }}
          </div>
        </div>
      </div>
    </div>
    } @empty {
      <app-empty-state [message]="'No Question Sets available'" (create)="addNewSet()"></app-empty-state>
    }
  </div>
</div>
}
