import { Component, OnInit, Signal, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule, Router, NavigationEnd } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs/operators';
import { AssistantService } from '~/app/services/assistant/assistant.admin.service';
import {
  Assistant,
  AssistantInput,
  AssistantUpdate
} from '../../services/assistant/assistant.types';
import { Edit, LucideAngularModule, RefreshCw, Trash2 } from 'lucide-angular';
import { ErrorDisplayComponent } from '../components/error-display/error-display.component';
import { EmptyStateComponent } from '../components/empty-state/empty-state.component';

@Component({
  selector: 'app-admin-assistants',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LucideAngularModule,
    ErrorDisplayComponent,
    EmptyStateComponent
  ],
  templateUrl: './admin-assistants.component.html',
  styleUrl: './admin-assistants.component.scss'
})
export class AdminAssistantsComponent implements OnInit {
  readonly refresh = RefreshCw;
  readonly edit = Edit;
  readonly delete = Trash2;

  private _assistantService = inject(AssistantService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);

  // Signals from base service
  readonly assistants = this._assistantService.data;
  readonly loading = this._assistantService.loading;
  readonly error = this._assistantService.error;

  // Selected assistant signal
  readonly selectedAssistant = this._assistantService.selectedAssistant$;

  isEditingOrCreating!: Signal<boolean>;

  constructor() {
    const checkEditingState = () => {
      const childRoute = this._route.firstChild;
      return !!childRoute && (
        childRoute.snapshot.url[0]?.path === 'new' ||
        !!childRoute.snapshot.paramMap.get('id')
      );
    };

    // Initialize with current route state
    const initialState = checkEditingState();

    this.isEditingOrCreating = toSignal(
      this._router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => checkEditingState()),
        // Start with initial state
        startWith(initialState)
      ),
      { initialValue: initialState }
    );

  }

  async ngOnInit() {
    await this.loadAssistants();
  }

  async loadAssistants(forceRefresh = false): Promise<void> {
    try {
      await this._assistantService.getAssistants(forceRefresh);
    } catch (error) {
      // console.error('Failed to load assistants:', error);
    }
  }

  async refreshData(): Promise<void> {
    await this.loadAssistants(true);
  }

  async deleteAssistant(assistantId: string): Promise<void> {
    try {
      await this._assistantService.deleteAssistant(assistantId);
      // Optional: Show success message
    } catch (error) {
      console.error('Failed to delete assistant:', error);
    }
  }

  selectAssistant(assistant: Assistant | null): void {
    this._assistantService.setSelectedAssistant(assistant);
  }

  addNewAssistant(): void {
    this._router.navigate(['./new'], { relativeTo: this._route });
  }

  editAssistant(assistant: Assistant): void {
    this._assistantService.setSelectedAssistant(assistant);
    this._router.navigate(['./', assistant._id], { relativeTo: this._route });
  }

  async createAssistant(data: AssistantInput): Promise<void> {
    try {
      const newAssistant = await this._assistantService.createAssistant(data);
      // Optional: Show success message or navigate
    } catch (error) {
      console.error('Failed to create assistant:', error);
    }
  }

  async updateAssistant(id: string, updates: AssistantUpdate): Promise<void> {
    try {
      const updated = await this._assistantService.editAssistant(id, updates);
      // Optional: Show success message
    } catch (error) {
      console.error('Failed to update assistant:', error);
    }
  }

  async activateAssistant(id: string): Promise<void> {
    try {
      await this._assistantService.activateAssistant(id);
      // Optional: Show success message
    } catch (error) {
      console.error('Failed to activate assistant:', error);
    }
  }

}
