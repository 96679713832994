<div class="relative flex flex-col items-center justify-center min-h-screen">
  <div class="w-full max-w-sm flex items-center justify-between mb-6">
    <app-back-button destination="/"></app-back-button>
    <h2 class="text-2xl font-semibold text-grey-800">Login</h2>
    <app-back-button destination="/" class="invisible"></app-back-button>
  </div>

  <form (ngSubmit)="submit()" class="w-full max-w-sm">
    <div>
      <label class="block text-grey-700 text-sm font-bold mb-2" for="email">
        Email Address
      </label>
      <input
        type="email"
        id="email"
        name="email"
        [(ngModel)]="email"
        required
        email
        #emailInput="ngModel"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="you@example.com"
      />
    </div>
    <div
      *ngIf="emailInput.invalid && emailInput.touched"
      class="text-danger-500 text-sm"
    >
      Please enter a valid email address.
    </div>
    <button
      type="submit"
      [disabled]="emailInput.invalid"
      class="w-full bg-primary-600 text-white py-2 px-4 rounded focus:outline-none mt-4"
      [ngClass]="{
      'bg-blue-600 hover:bg-blue-700 cursor-pointer': !emailInput.invalid,
      'bg-blue-400 cursor-not-allowed': emailInput.invalid
      }"
    >
      Send me a Link
    </button>
    @if (infoMessage) {
      <div class="my-4 w-full text-danger-700 border border-danger-700 bg-danger-200 rounded p-2">{{ infoMessage }}</div>
    }
  </form>
</div>
