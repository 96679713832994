<div class="col-span-full text-center py-12 bg-danger-50 rounded-lg">
  <div class="flex flex-col items-center">
    <lucide-angular
      [img]="alertIcon"
      class="h-12 w-12 text-danger-400"
    />

    <h3 class="mt-2 text-sm font-medium text-danger-900">
      {{ title() }}
    </h3>

    @if (error()) {
      <p class="mt-1 text-sm text-danger-700">
        {{ error() }}
      </p>
    }

    <button
      (click)="retry.emit()"
      class="mt-4 px-4 py-2 bg-danger-100 text-danger-700 rounded-md hover:bg-danger-200 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-danger-500 focus:ring-offset-2"
    >
      Try Again
    </button>
  </div>
</div>
