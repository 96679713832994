import type { RegisteredComponent } from "@builder.io/sdk-angular";
import { InsightListComponent } from "./components/insights-dashboard/insight-list/insight-list.component";
import { InsightPropertyCardComponent } from "./components/insights-dashboard/insight-property-card/insight-property-card.component";
import { InsightsDashboardComponent } from "./components/insights-dashboard/insights-dashboard.component";
import { MenuComponent } from "./components/menu/menu.component";
import { SideMenuComponent } from "./components/insights-dashboard/side-menu/side-menu.component";

export const CUSTOM_COMPONENTS: RegisteredComponent[] = [
  {
    component: InsightListComponent,
    name: "InsightListComponent",
  },
  {
    component: InsightPropertyCardComponent,
    name: "InsightPropertyCardComponent",
    inputs: [
      {
        name: "description",
        type: "string",
      },
      {
        name: "title",
        type: "string",
      },
    ],
  },
  {
    component: InsightsDashboardComponent,
    name: "InsightsDashboardComponent",
  },
  {
    component: MenuComponent,
    name: "MenuComponent",
  },
  {
    component: SideMenuComponent,
    name: "SideMenuComponent",
  },
];
