import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminBaseService } from '../admin/admin-base.service';
import { firstValueFrom } from 'rxjs';
import { Assistant, AssistantInput, AssistantUpdate } from './assistant.types';

@Injectable({
  providedIn: 'root',
})
export class AssistantService extends AdminBaseService<
  Assistant,
  AssistantInput,
  AssistantUpdate
> {
  private _selectedAssistant = signal<Assistant | null>(null);
  readonly selectedAssistant$ = this._selectedAssistant.asReadonly();
  readonly baseModels = ['gpt-4o', 'gpt-4o-2024-11-20', 'gpt-4o-mini'];
  models = signal<string[]>(this.baseModels);

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async getModels(): Promise<{ jobs: any[] }> {
    try {
      const response = await firstValueFrom(
        this.httpClient.get<{ jobs: any[] }>(
          `${this.API_URL}/api/admin/finetuning`,
          { withCredentials: true }
        )
      );

      if (!response) {
        throw new Error('Failed to retreive models');
      }

      this.models.set([...this.models(), ...response.jobs.map((j) => j.model)]);
      return response;
    } catch (error) {

      throw this.handleError(error);
    }

  }

  async getAssistants(forceRefresh = false): Promise<Assistant[]> {
    return this.fetchData('assistants', forceRefresh);
  }

  async getAssistantById(id: string): Promise<Assistant> {
    try {
      const response = await firstValueFrom(
        this.httpClient.get<Assistant>(
          `${this.API_URL}/api/admin/assistants/${id}`,
          { withCredentials: true }
        )
      );

      if (!response) {
        throw new Error('No data received');
      }

      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async createAssistant(input: AssistantInput): Promise<Assistant> {
    try {
      return this.createItem('assistants', input);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async editAssistant(
    id: string,
    updates: AssistantUpdate
  ): Promise<Assistant> {
    return this.updateItem('assistants', id, updates);
  }

  async deleteAssistant(id: string): Promise<void> {
    return this.deleteItem('assistants', id);
  }

  async activateAssistant(id: string): Promise<Assistant> {
    try {
      const response = await firstValueFrom(
        this.httpClient.post<Assistant>(
          `${this.API_URL}/api/admin/assistants/${id}/activate`,
          {},
          { withCredentials: true }
        )
      );

      if (!response) {
        throw new Error('Failed to activate assistant');
      }

      // Update the assistant in our local state
      this._updateState({
        data: this._state().data.map((assistant) =>
          assistant._id === id ? { ...assistant, isActive: true } : assistant
        ),
      });

      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }


  setSelectedAssistant(assistant: Assistant | null): void {
    this._selectedAssistant.set(assistant);
  }

}
