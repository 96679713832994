import { Injectable, signal } from '@angular/core';
import { AdminBaseService } from '../admin/admin-base.service';
import { HttpClient } from '@angular/common/http';
import {
  Consultant,
  ConsultantInput,
  ConsultantUpdate,
} from './consultant.types';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService extends AdminBaseService<
  Consultant,
  ConsultantInput,
  ConsultantUpdate
> {
  private _selectedConsultant = signal<Consultant | null>(null);
  selectedConsultant$ = this._selectedConsultant.asReadonly();

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async getConsultants(forceRefresh = false): Promise<Consultant[]> {
    return this.fetchData('consultants', forceRefresh);
  }

  async getConsultantById(id: string): Promise<Consultant> {
    try {
      const response = await firstValueFrom(
        this.httpClient.get<Consultant>(
          `${this.API_URL}/api/admin/consultants/${id}`,
          { withCredentials: true }
        )
      );

      if (!response) {
        throw new Error('No data received');
      }

      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async editConsultant(
    id: string,
    updates: ConsultantUpdate
  ): Promise<Consultant> {
    return this.updateItem('consultants', id, updates);
  }

  async createConsultant(consultant: ConsultantInput): Promise<Consultant> {
    return this.createItem('consultants', consultant);
  }

  async deleteConsultant(id: string): Promise<void> {
    return this.deleteItem('consultants', id);
  }

  setSelectedConsultant(consultant: Consultant | null): void {
    this._selectedConsultant.set(consultant);
  }
}
