<div class="relative flex flex-col gap-2 border-[1px] rounded-sm p-4 my-4">
  @if (!submitted) {
    <h2 class="font-medium text-xl">How would you rate this chat?</h2>

    <div class="flex gap-1 items-center">
      @for(star of stars; track star) {
        <div
          class="relative cursor-pointer"
          (mousemove)="handleMouseMove($event, star)"
          (mouseleave)="hoveredRating = rating"
          (click)="handleClick($event, star)">

          <svg
            class="w-8 h-8"
            [class.text-primary-800]="star <= hoveredRating"
            [class.text-grey-300]="star > hoveredRating"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor">
            <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>
          </svg>

          @if(showHalfStar(star)) {
            <div class="absolute inset-0 w-1/2 overflow-hidden">
              <svg
                class="w-8 h-8 text-primary-800"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor">
                <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>
              </svg>
            </div>
          }
        </div>
      }
      <span class="ml-2 text-sm">
        {{ hoveredRating }} out of 5
      </span>
    </div>

    @if (rating > 0) {
      <label class="text-sm italic" for="additionalInfo">Comments (optional)</label>
      <textarea
        [(ngModel)]="comment"
        placeholder="Additional information (optional)"
        class="px-4 py-2 border-grey-200 focus:outline-none resize-none border-none"
        rows="1"
        (input)="autoGrow($event)"
      ></textarea>
      <button (click)="handleSubmit()" class="text-white bg-primary-default hover:bg-primary-300 focus:ring-4 focus:outline-none focus:ring-primary-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2 dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-700">Submit</button>
    }
  } @else {
    <h2 class="font-medium text-xl">Thank you for your feedback!</h2>
  }
</div>
