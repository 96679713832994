import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsightFocusService } from '~/app/services/insight-focus.service';
import { trigger, state, style, transition, animate, query, stagger } from '@angular/animations';

@Component({
  selector: 'app-insight-property-card',
  templateUrl: './insight-property-card.component.html',
  styleUrls: ['./insight-property-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
  host: {
    '[class.focused]': 'isFocused()',
    '[class.sub-focused]': 'hasSubFocus()'
  },
  animations: [
    trigger('popIn', [
      transition('* => *', [
        query(':enter', [
          style({ transform: 'scale(0.5)', opacity: 0, height: 0 }),
          stagger(100, [
            animate('150ms ease-in-out', style({ transform: 'scale(1)', opacity: 1, height: '*' }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class InsightPropertyCardComponent implements OnChanges, OnDestroy {
  @Input() title: string = '';
  @Input() description?: string = '';
  @Input() cardId: string = '';
  @Input() value?: any;

  displayValue: string = '';
  hasNestedValues: boolean = false;
  nestedValues: Array<{key: string, value: any}> = [];
  isArray: boolean = false;
  lastUpdated?: Date;

  constructor(private insightFocusService: InsightFocusService) {}

  ngOnChanges() {
    this.processValue();
  }

  private processValue() {
    if (this.value === null || this.value === undefined) {
      this.displayValue = this.title;
      this.hasNestedValues = false;
      this.isArray = false;
      return;
    }

    if (Array.isArray(this.value)) {
      this.displayValue = `${this.value.length} types`;
      this.isArray = true;
      this.hasNestedValues = false;
      this.nestedValues = this.value.map((item: any, index: number) => ({
        key: `Item ${index + 1}`,
        value: item
      }));
      return;
    }

    if (typeof this.value === 'object') {
      this.displayValue = this.value.name || this.title;
      this.description = this.value.description || this.description;
      this.hasNestedValues = true;
      this.isArray = false;
      this.nestedValues = Object.entries(this.value)
        .filter(([key]) => key !== 'name' && key !== 'description' && key !== 'list')
        .map(([key, value]) => ({
          key,
          value
        }));
      
      // Handle "list" key
      if (this.value.list && Array.isArray(this.value.list)) {
        this.isArray = true;
        this.nestedValues = this.value.list.map((item: any, index: number) => ({
          key: `Item ${index + 1}`,
          value: item
        }));
      }
      return;
    }

    // For primitive values
    this.displayValue = this.value.toString();
    this.hasNestedValues = false;
    this.isArray = false;
  }

  toggleFocus(event?: MouseEvent) {
    this.toggleFocusById(event, this.cardId);
  }

  toggleNestedFocus(event: MouseEvent, nestedCardId: string) {
    this.toggleFocusById(event, nestedCardId);
  }

  private toggleFocusById(event: MouseEvent | undefined, cardId: string) {
    if (event) {
      event.stopPropagation();
    }
    const focusedCardId = this.insightFocusService.getFocusedCardId();
    const newFocusedCardId = this.isFocused() && focusedCardId === cardId
      ? cardId.split('-').slice(0, -1).join('-') || null
      : cardId;
    this.insightFocusService.setFocusedCardId(newFocusedCardId);
  }

  isFocused(): boolean {
    const focusedCardId = this.insightFocusService.getFocusedCardId();
    return focusedCardId ? focusedCardId.startsWith(this.cardId) : false;
  }

  hasSubFocus(): boolean {
    const focusedCardId = this.insightFocusService.getFocusedCardId();
    return focusedCardId ? focusedCardId.startsWith(this.cardId + '-') : false;
  }

  ngOnDestroy() {
    if (this.isFocused()) {
      this.insightFocusService.setFocusedCardId(null);
    }
  }
}
