<div class="min-h-screen bg-gray-50 flex flex-col items-center justify-center text-center px-4">
  <div class="max-w-md">
    <h1 class="text-3xl font-bold text-gray-900 mb-4">
      Thank you for subscribing!
    </h1>
    <p class="text-gray-600">
      You will be redirected shortly...
    </p>
  </div>
</div>
