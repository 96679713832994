<!-- This code was generated by Builder.io -->
<div class="flex flex-row bg-white rounded-lg border border-solid border-zinc-200 h-full">
  @if (menuItems.length > 0) {
    <app-side-menu class="flex" [menuItems]="menuItems"></app-side-menu>
  }
  <main class="flex flex-col min-w-[240px] w-[1224px] max-md:max-w-full md:w-full h-full overflow-auto">
    <div class="flex flex-wrap flex-1 size-full max-md:max-w-full">
      <div class="w-1/2 max-md:w-full max-h-full p-4 overflow-auto">
        <app-insight-list></app-insight-list>
      </div>
      <div class="w-1/2 max-md:hidden max-h-full">
        <app-chat></app-chat>
      </div>
    </div>
  </main>
</div>
