import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Chat, defaultChat } from '../types/chat';
import { Message } from '../types/message';
import { AssistantService } from './assistant/assistant.user.service';
import { ClientEvents, ServerEvents, WebSocketService } from './web-socket.service';
import { Assistant } from '../types/assistant';
import { BaseService } from './base.service';
import { InsightFocusService } from './insight-focus.service';
import { InsightType } from '../types/insights';
import { Business } from './business/business.types';
import { BusinessService } from './business/business.user.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService implements OnDestroy {
  private waitingForResponse = new BehaviorSubject<boolean>(false);
  waitingForResponse$ = this.waitingForResponse.asObservable();

  private activeChats = new BehaviorSubject<Chat[]>([]);
  private currentChat = new BehaviorSubject<Chat>(defaultChat);
  activeChats$ = this.activeChats.asObservable();
  currentChat$ = this.currentChat.asObservable();

  private waitingForRating = new BehaviorSubject<boolean>(false);
  waitingForRating$ = this.waitingForRating.asObservable();

  chatAvailable = new BehaviorSubject<boolean>(false);
  chatAvailable$ = this.chatAvailable.asObservable();

  focusedBusiness: Business | null = null;

  constructor(
    private assistantService: AssistantService,
    private webSocketService: WebSocketService,
    private _insightFocusService: InsightFocusService,
    private _businessService: BusinessService
  ) {
    super();
    this.webSocketService.connect();
    this.initializeSocketEvents();
    this.subscribeToBusinessChanges();
    this.subscribeToInsightChanges();
  }

  private subscribeToBusinessChanges() {
    this.subscribe(this._businessService.focusedBusiness$, async (business) => {
      this.focusedBusiness = business;
      this.refreshChat();
    });
  }

  private subscribeToInsightChanges() {
    this.subscribe(this._insightFocusService.focusedInsightModelChange, async (insightType: string | null) => {
      console.log('chat.service.ts: Insight focus changed:', insightType); // Add logging
      console.log('Insight focus changed:', insightType); // Add logging
      if (insightType) {
        try {
          this.currentChat.value.assistant.insightType = insightType as InsightType;
          this.webSocketService.emit(ClientEvents.init, { insightType, businessId: this.focusedBusiness?._id });
          this.chatAvailable.next(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        this.chatAvailable.next(false);
        this.currentChat.next(defaultChat); // Reset the current chat
        this.activeChats.next([]); // Clear active chats
        this.waitingForResponse.next(false); // Reset waiting for response
      }
      this.refreshChat();
    });
  }

  private initializeSocketEvents() {
    this.subscribe(this.webSocketService.on([ServerEvents.init]), ({ assistantName }) => {
      this.currentChat.value.assistant.name = assistantName;
    });

    this.subscribe(this.webSocketService.on([ServerEvents.response]), ({ message, choices }) => {
      this.addMessage(this.currentChat.value, { role: "bot", message, choices, timestamp: new Date() });
      this.waitingForResponse.next(false);
    });

    this.subscribe(this.webSocketService.on([ServerEvents.result]), ({ message: result }) => {
      const insightType = this.currentChat.value.assistant.insightType;
      // this._insightFocusService.setFocusedInsightModel(this.currentChat.value.assistant.insightType);
      this._insightFocusService.setUpdatedFocusedInsight({ insightType, result });
      console.log(result);
      this.waitingForResponse.next(false);
      this.waitingForRating.next(true);
    });
  }

  private async initializeChat(assistant: Assistant) {

    const chatHistory = await this.assistantService.getAssistantHistory(this._insightFocusService.getFocusedInsightModel());

    const newChat: Chat = { assistant, messages: chatHistory };
    this.activeChats.next([...this.activeChats.value, newChat]);
    this.currentChat.next(newChat);
  }

  async addChat(assistant: Assistant): Promise<void> {
    await this.initializeChat(assistant);
  }

  async refreshChat() {
    if (this.focusedBusiness && this.currentChat.value.assistant.insightType) {
      const assistant = this.currentChat.value.assistant;
      await this.initializeChat(assistant);
    } else {
      this.currentChat.next(defaultChat);
      this.chatAvailable.next(false);
      this.activeChats.next([]); // Clear active chats
    }
  }

  removeChat(chat: Chat): void {
    const updatedChats = this.activeChats.value.filter(activeChat => activeChat !== chat);
    this.activeChats.next(updatedChats);

    if (this.currentChat.value === chat) this.currentChat.next(updatedChats[0]);
  }

  setWaitingForRating(waitingForRating: boolean): void {
    this.waitingForRating.next(waitingForRating);
  }

  setCurrentChat(chat: Chat): void {
    this.currentChat.next(chat);
  }

  sendMessageToAssistant(message: string) {
    setTimeout(() => this.waitingForResponse.next(true), 500);
    this.webSocketService.emit(ClientEvents.send, { message });
  }

  addMessage(chat: Chat, message: Message): void {
    const updatedChats = this.activeChats.value.map(activeChat => {
      if (activeChat === chat) activeChat.messages.push(message);
      return activeChat;
    });
    this.activeChats.next(updatedChats);
  }

  editMessage(event: { original: string; edited: string }) {
    this.webSocketService.emit(ClientEvents.edit, event );
  }

  async loadLastUsedChat() {
    if (this.activeChats.value.length > 0) return;
    const assistantId = await this.assistantService.getLastUsedAssistant();
    await this.addChat(assistantId);
  }
}
