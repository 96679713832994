import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { InsightModel } from '../../../../services/insights/insight.admin.service';

@Component({
  selector: 'app-admin-model-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './model-display.component.html',
  styleUrl: './model-display.component.scss',
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0',
        opacity: '0',
        visibility: 'hidden'
      })),
      state('expanded', style({
        height: '*',
        opacity: '1'
      })),
      transition('collapsed <=> expanded', [
        animate('200ms ease-out')
      ])
    ])
  ]
})
export class ModelDisplayComponent {
  insightModel = input.required<InsightModel>();
  expanded = false;

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}
