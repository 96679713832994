@if (!isEditingOrCreating()) {
<div class="p-4 md:px-6">
  <div class="flex flex-col gap-2">
    <!-- Header -->
    <div class="flex justify-between items-center">
      <h1 class="text-2xl font-bold">Businesses</h1>
      <div class="flex gap-2">
        <button
          (click)="createNew()"
          class="px-4 py-2 text-sm font-medium text-secondary-default bg-primary-default rounded-md hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          New Business
        </button>
        <button
          (click)="refresh()"
          class="px-4 py-2 text-sm font-medium text-grey-700 bg-white border border-grey-300 rounded-md hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          [disabled]="isLoading()"
        >
          <lucide-angular
            [img]="icons.refresh"
            class="h-4"
            [class.animate-spin]="isLoading()"
          />
        </button>
      </div>
    </div>

    @if (isLoading()) {
      <div class="flex justify-center p-4">
        <lucide-angular [img]="icons.refresh" class="animate-spin" />
      </div>
      } @else if (error()) {
      <app-error-display [error]="error() || ''" (retry)="refresh()" />
      } @else { @for (business of businesses(); track business._id) {
      <app-admin-business-card
        [business]="business"
        (edit)="edit($event)"
        (delete)="delete($event)"
      />
      } @empty {
      <app-empty-state (create)="createNew()"  message="No businesses found" />
      }
    }
  </div>
</div>
}
<router-outlet />
