@if (authState$ | async; as authState) {
  <header style="display: flex; justify-content: space-between; align-items: center; padding: 0 20px; height: 64px; background-color: white; border-bottom: 1px solid #ccc; position: sticky; top: 0; z-index: 10; width: 100%; box-sizing: border-box;">
    
    <!-- Menu and Logo Section -->
    <div style="display: flex; align-items: center; gap: 10px;">
      @if (authState.isLoggedIn) {
        <button style="background: none; border: none; cursor: pointer; padding: 4px;" (click)="toggleMenu()">
          <lucide-icon [img]="menuIcon" [size]="30"></lucide-icon>
        </button>
      }
      <button routerLink="/">
        <img src="/assets/svgs/bp-logo.svg" alt="Syzl Logo" style="height: 48px; width: auto;">
      </button>
    </div>

    <!-- Header Title -->
    <h1 style="position: absolute; left: 50%; transform: translateX(-50%); font-size: 1.5rem; font-weight: bold;">{{ currentTitle }}</h1>

    <!-- Share Button (Only if Logged In) -->
    @if (authState.isLoggedIn) {
      <button 
        style="display: flex; align-items: center; gap: 8px; background-color: white; border: 1px solid #ccc; padding: 8px 12px; cursor: pointer; border-radius: 4px;">
        <img 
          src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/4a8ee8e5fad75d36f90fd2204f73184ed467eb21e49f5e2cd21836b4e1bad313?apiKey=2657c647a72a40e5bd9f8e334f049b96&" 
          alt="" style="object-fit: contain; flex-shrink: 0; width: 16px; height: 16px;">
        <span>Share</span>
      </button>
    }
  </header>

  <!-- Pass isMenuOpen as an input -->
  @if (authState.isLoggedIn && isMenuOpen) {
    <app-header-menu></app-header-menu>
  }
}



  <!-- TODO: Display the current path (Primary [in large text] > secondary [in small text] > tertiary [in small text]) -->
  <!-- Primary Path = Current Route -->
  <!-- Secondary Path(s) = either sub-path or focused insight path -->