<header
  class="top-0 right-0 flex justify-between items-center space-x-4 w-full layout"
>
  <button routerLink="/">
    <img
      class="h-12 w-12"
      src="/assets/svgs/syzl-logo-black.svg"
      alt="Syzl Logo"
    />
  </button>

  @if (authState$ | async; as authState) { @if (authState.isLoggedIn) {
  <div
    class="flex justify-center items-center gap-1 text-sm text-gray-800 font-medium"
  >
    <lucide-angular [img]="userIcon"></lucide-angular>
    <span class="text-primary-default">{{ authState.email }}</span>
  </div>

  <div class="flex gap-2">

    @if (authState.role === 'admin') {
      <a
        class="text-primary-default font-compressed text-lg font-bold cursor-pointer hover:underline"
        routerLink="/admin/dashboard"
      >
        Admin
      </a>
    }

    @if (authState.isSubscribed) {
      <a
        class="text-primary-default font-compressed text-lg font-bold cursor-pointer hover:underline"
        routerLink="/subscribe"
      >
        Manage Subscription
      </a>
    }

    <a
      (click)="logout()"
      class="text-primary-default font-compressed text-lg font-bold cursor-pointer hover:underline"
    >
      Logout
    </a>
  </div>
  } @else {
  <button
    (click)="login()"
    class="px-4 py-2 text-2xl text-secondary-default bg-primary-default font-compressed uppercase font-bold"
  >
    Login
  </button>
  } }
</header>
