import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Chat, defaultChat } from '../types/chat';
import { Message } from '../types/message';
import { AssistantService } from './assistant/assistant.user.service';
import { ClientEvents, ServerEvents, WebSocketService } from './web-socket.service';
import { Assistant } from '../types/assistant';
import { BaseService } from './base.service';
import { InsightFocusService } from './insight-focus.service';
import { InsightType } from '../types/insights';
import { Business } from './business/business.types';
import { BusinessService } from './business/business.user.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService implements OnDestroy {
  private waitingForResponse = new BehaviorSubject<boolean>(false);
  waitingForResponse$ = this.waitingForResponse.asObservable();

  private activeChats = new BehaviorSubject<Chat[]>([]);
  private currentChat = new BehaviorSubject<Chat>(defaultChat);
  activeChats$ = this.activeChats.asObservable();
  currentChat$ = this.currentChat.asObservable();

  focusedBusiness: Business | null = null;

  constructor(
    private assistantService: AssistantService,
    private webSocketService: WebSocketService,
    private _insightFocusService: InsightFocusService,
    private _businessService: BusinessService

  ) {
    super();
    this.webSocketService.connect();
    this.initializeSocketEvents();
    this.subscribe(this._businessService.focusedBusiness$, async (business) => {
      this.focusedBusiness = business;
      this.webSocketService.emit(
        ClientEvents.init, { insightType: this.currentChat.value.assistant.insightType, businessId: this.focusedBusiness?._id }
      );
    });
    this.subscribe(this._insightFocusService.focusedInsightModelChange, async (insightType: string | null) => {
      if (insightType) {
        try {
          // Update this.currentChat.value.assistant.insightType
          this.currentChat.value.assistant.insightType = insightType as InsightType;
          // Emit ClientEvents.init with the updated insightType
          this.webSocketService.emit(
            ClientEvents.init, { insightType: this.currentChat.value.assistant.insightType, businessId: this.focusedBusiness?._id }
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  initializeSocketEvents() {
    this.subscribe(
      this.webSocketService.on([ServerEvents.init]),
      ({ assistantName }) => {
        this.currentChat.value.assistant.name = assistantName;
      }
    );

    this.subscribe(
      this.webSocketService.on([ServerEvents.response]),
      ({ message, choices }) => {
        console.log('=============MESSAGE=============');
        this.addMessage(this.currentChat.value, { role: "bot", message, choices, timestamp: new Date() });
        this.waitingForResponse.next(false);
      }
    );

    this.subscribe(
      this.webSocketService.on([ServerEvents.result]),
      ({ message: result }) => {
        console.log('=============RESULT=============');
        this._insightFocusService.setFocusedInsightModel(this.currentChat.value.assistant.insightType)
        console.log(result);


        this.waitingForResponse.next(false);
      }
    )
  }

  async addChat(assistant: Assistant): Promise<void> {
    const chatHistory = await this.assistantService.getAssistantHistory(assistant);
    const messages: Message[] = chatHistory.length > 0
      ? chatHistory
      : [{
        role: 'bot',
        message: `Hello! I'm the ${assistant?.name}. How can I help?`,
        timestamp: new Date()
      }];

    const newChat: Chat = { assistant, messages };
    this.activeChats.next([...this.activeChats.value, newChat]);
    this.currentChat.next(newChat);
  }

  removeChat(chat: Chat): void {
    const updatedChats = this.activeChats.value.filter(activeChat => activeChat !== chat);
    this.activeChats.next(updatedChats);

    if (this.currentChat.value === chat) this.currentChat.next(updatedChats[0]);
  }

  setCurrentChat(chat: Chat): void {
    this.currentChat.next(chat);
  }

  sendMessageToAssistant(message: string) {
    setTimeout(() => this.waitingForResponse.next(true), 500);
    this.webSocketService.emit(ClientEvents.send, { message });
  }

  addMessage(chat: Chat, message: Message): void {
    const updatedChats = this.activeChats.value.map(activeChat => {
      if (activeChat === chat) activeChat.messages.push(message);
      return activeChat;
    });
    this.activeChats.next(updatedChats);
  }

  async loadLastUsedChat() {
    if (this.activeChats.value.length > 0) return;
    const assistantId = await this.assistantService.getLastUsedAssistant();
    await this.addChat(assistantId);
  }
}
