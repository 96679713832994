import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { InsightListComponent } from './insight-list/insight-list.component';
import { AuthService } from '~/app/services/auth.service';
import { ChatComponent } from '../chat/chat.component';
import { InsightsService } from '~/app/services/insights/insights.user.service';
import { BusinessService } from '~/app/services/business/business.user.service';
import { InsightFocusService } from '~/app/services/insight-focus.service';

@Component({
  selector: 'app-insights-dashboard',
  templateUrl: './insights-dashboard.component.html',
  styleUrls: ['./insights-dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SideMenuComponent,
    InsightListComponent,
    ChatComponent,
  ],
})
export class InsightsDashboardComponent implements OnInit {
  menuItems: any[] = [];

  constructor(
    private _insightsService: InsightsService,
    private _businessService: BusinessService,
    private _insightFocusService: InsightFocusService,
    private _authService: AuthService
  ) {}

  async ngOnInit() {
    try {
      const insightModels = await this._insightsService.getInsightModels();

      if (!insightModels || Object.keys(insightModels).length === 0) {
        console.error('No insight models received');
        return;
      }

      this.menuItems = Object.values(insightModels).map((model: any) => {
        return {
          name: model.name,
          description: model.description,
          icon: model.icon,
          id: model.id,
        };
      });

      if (this.menuItems.length > 0) {
        console.log('Menu items:', this.menuItems);
        // this._insightFocusService.setFocusedInsightModel(this.menuItems[0].name);
      } else {
        console.warn('No menu items available to set initial focus');
      }

      const email = this._authService.getLatestAuthState().email;
      if (!email) {
        console.error('No email found in auth state');
        return;
      }


    } catch (error) {
      console.error('Error in ngOnInit:', error);
    }
  }
}
