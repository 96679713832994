// src/app/services/insights/insights.user.service.ts
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  public static readonly INSIGHT_TYPES = {};
  public static readonly INSIGHT_MODELS = {};

  private readonly _API_URL = environment.apiUrl;

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService
  ) {
    this.getInsightTypes();
  }

  private _userInsightsCache: { [email: string]: any } = {};

  async getUserInsights(): Promise<any> {
    // get user email from latest auth state
    const email = this._authService.getLatestAuthState().email;

    if (this._userInsightsCache[email]) {
      return this._userInsightsCache[email];
    }

    const response = await firstValueFrom(
      this._httpClient.get(`${this._API_URL}/api/v1/insight/${email}`, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error getting user insights:', error);
            return throwError(() => error);
          })
        )
    );

    this._userInsightsCache[email] = response;
    return response;
  }

  async getInsightDetailsById(insightId: string): Promise<any> {
    console.log('Getting insight details for ID:', insightId);
    const response = await firstValueFrom(
      this._httpClient.get(`${this._API_URL}/api/v1/insight/${insightId}`, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error getting user insights by model name:', error);
            return throwError(() => error);
          })
        )
    );
    return response;
  }

  async getInsightTypes(): Promise<Object> {
    if (Object.keys(InsightsService.INSIGHT_TYPES).length === 0) {
      const types = await this._requestTypes();
      Object.assign(InsightsService.INSIGHT_TYPES, types);
    }
    return InsightsService.INSIGHT_TYPES;
  }

  // add a method that gets insights by model name for the authenticated user
  async getInsightsByModelName(modelName: string): Promise<any> {
    const email = this._authService.getLatestAuthState().email;

    console.log(`Getting insights for user ${email} with model name: ${modelName}`);

    const response = await firstValueFrom(
      this._httpClient.get(`${this._API_URL}/api/v1/insight/${email}/${modelName}`, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error getting user insights by model name:', error);
            return throwError(() => error);
          })
        )
    );

    return response;
  }

  async getInsightModels(): Promise<Object> {
    if (Object.keys(InsightsService.INSIGHT_MODELS).length === 0) {
      const models = await this._requestInsightModels();
      Object.assign(InsightsService.INSIGHT_MODELS, models);
    }
    return InsightsService.INSIGHT_MODELS;
  }

  private async _requestTypes(): Promise<Object> {
    const response = await firstValueFrom(
      this._httpClient.get(`${this._API_URL}/api/v1/insight/types`, { withCredentials: false })
        .pipe(
          catchError(error => {
            console.error('Error getting all insight types:', error);
            return throwError(() => error);
          })
        )
    );
    return response;
  }

  private async _requestInsightModels(): Promise<Object> {  // Add this method
    const response = await firstValueFrom(
      this._httpClient.get(`${this._API_URL}/api/v1/insight/models`, { withCredentials: false })
        .pipe(
          catchError(error => {
            console.error('Error getting all insight models:', error);
            return throwError(() => error);
          })
        )
    );
    return response;
  }

}
