import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { BusinessService } from '../../services/business/business.user.service';
import { HlmDialogModule } from '@spartan-ng/ui-dialog-helm';
import { HlmFormFieldModule } from '@spartan-ng/ui-formfield-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { Business } from '~/app/services/business/business.types';
import { Router } from '@angular/router';



@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HlmFormFieldModule,
    HlmInputDirective,
    HlmDialogModule,
  ],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent implements OnInit {
  form: FormGroup;
  focusedBusiness: Business | null = null;

  constructor(private _businessService: BusinessService, private _router: Router) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('')
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      const userBusinesses = await this._businessService.getUserBusinesses();
  
      if (userBusinesses.length > 0) {
        this._businessService.setFocusedBusiness(userBusinesses[0]); // Set in BusinessService
      } else {
        this._businessService.setFocusedBusiness(null); // Clear if none
      }
    } catch (error) {
      console.error('Error fetching user businesses:', error);
    }
  }

  closeDialog() {
    this.form.reset();
    this._router.navigate(['/dashboard']);
  }

  async submitForm() {
    if (this.form.valid) {
      const businessData = this.form.value;
      try {
        // Step 1: Create a new business
        const newBusiness = await this._businessService.createNewBusiness(
          businessData.name,
          businessData.description
        );
  
        console.log('New Business Created:', newBusiness);
  
        // Step 2: Verify that the business was created
        const userBusinesses = await this._businessService.getUserBusinesses();
  
        // Step 3: Check if the newly created business exists
        const createdBusiness = userBusinesses.find(
          (biz) => biz.name === businessData.name && biz.description === businessData.description
        );
  
        if (createdBusiness) {
          console.log('Business successfully verified. Redirecting...');
          this._router.navigate(['/onboarding-success']);
        } else {
          console.error('Business creation verification failed.');
        }
      } catch (error) {
        console.error('Error saving business:', error);
      }
    }
  }
  
}
