import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LucideAngularModule, User, Bot, Check } from 'lucide-angular';
import { Message } from '../../../types/message';
import { AssistantService } from '../../../services/assistant/assistant.user.service';
import { Assistant } from '../../../types/assistant';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, DatePipe],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent {
  // lucide icons
  readonly UserIcon = User;
  readonly BotIcon = Bot;
  readonly checkIcon = Check;

  @Input() message!: Message;
  @Input() latestMessage!: boolean;
  @Input() currentAssistant!: Assistant;
  @Output() onChoicesSubmitted = new EventEmitter<string[]>();

  selectedChoices: string[] = [];

  constructor(private assistantService: AssistantService) { }

  getAssistantColors(assistantId: string) {
    return this.assistantService.getAssistantColors(assistantId);
  }

  toggleChoice(choice: string) {
    if (this.selectedChoices.includes(choice)) this.selectedChoices = this.selectedChoices.filter(c => c !== choice);
    else this.selectedChoices.push(choice);
  }
}
