import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertTriangle, LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-error-display',
  standalone: true,
  imports: [CommonModule, LucideAngularModule],
  templateUrl: './error-display.component.html'
})
export class ErrorDisplayComponent {
  // Inputs
  error = input.required<string>();
  title = input<string>('An error occurred');  // Optional with default

  // Outputs
  retry = output<void>();

  // Icons
  readonly alertIcon = AlertTriangle;
}
