<article
  class="flex relative flex-col p-4 w-full bg-white rounded-lg border-zinc-200 max-w-[559px] max-md:max-w-full"
  (click)="toggleFocus($event)">
  <div class="flex">
    <div
      class="flex z-0 flex-col w-full border-slate-950 max-md:max-w-full">
      <!-- card header -->
      <div class="flex w-full">
        <h4
          class="font-semibold tracking-tight leading-none text-zinc-950 max-md:max-w-full text-ellipsis max-w-full"
          style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
          {{ displayValue }}
        </h4>
      </div>
      <div class="flex w-full">
        <p class="mt-1.5 text-sm leading-5 text-zinc-500">
          {{ description }}
        </p>
      </div>

      <!-- Recursive rendering for nested objects -->
      <div *ngIf="hasNestedValues && !isArray && isFocused()"
        class="flex relative flex-col mt-4 pl-4 border-l-2 border-zinc-200"
        [@popIn]>
        <app-insight-property-card
          *ngFor="let item of nestedValues"
          [title]="item.key"
          [value]="item.value"
          [cardId]="cardId + '-' + item.key"
          class="mt-2 first:mt-0"
          (click)="toggleNestedFocus($event, cardId + '-' + item.key); $event.stopPropagation()">
        </app-insight-property-card>
      </div>

      <!-- Array rendering -->
      <div *ngIf="isArray && isFocused()"
        class="flex relative flex-col mt-4 pl-4 border-l-2 border-zinc-200"
        [@popIn]>
        <app-insight-property-card
          *ngFor="let item of nestedValues; let i = index"
          [title]="item.key"
          [value]="item.value"
          [cardId]="cardId + '-' + i"
          class="mt-2 first:mt-0"
          (click)="toggleNestedFocus($event, cardId + '-' + i); $event.stopPropagation()">
        </app-insight-property-card>
      </div>
    </div>
  </div>
  <span
    class="rounded-full absolute left-3 self-start px-1 text-sm font-medium leading-none bg-white text-zinc-900 top-[-7px]">
    {{ value ? title : 'InsightProperty' }}
  </span>
</article>
