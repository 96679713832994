import { Component, OnInit, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Check, LucideAngularModule, SendHorizontal } from 'lucide-angular';
import { HeaderComponent } from '../header/header.component';
import { MessageComponent } from './message/message.component';
import { BaseComponent } from '../base.component';
import { Chat } from '~/app/types/chat';
import { Assistant } from '~/app/types/assistant';
import { Message } from '~/app/types/message';
import { ChatService } from '~/app/services/chat.service';
import { AssistantService } from '~/app/services/assistant/assistant.user.service';
import { ChatHeaderComponent } from './header/header.component';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { StarRatingComponent } from '../star-rating/star-rating.component';


@Component({
  selector: 'app-chat',
  standalone: true,
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LucideAngularModule,
    HeaderComponent,
    MessageComponent,
    ChatHeaderComponent,
    HlmButtonDirective,
    StarRatingComponent
  ]
})
export class ChatComponent extends BaseComponent implements OnInit, AfterViewInit {
  // lucide icons
  readonly SendIcon = SendHorizontal;
  readonly checkIcon = Check;

  activeChats: Chat[] = [];
  currentChat!: Chat;
  messageInput = new FormControl('');
  assistants: Assistant[] = [];
  waitingForResponse: boolean = false;
  waitingForRating = true;

  ratingSubmitted = false;

  loadingMessage: Message = {
    role: 'bot',
    message: '...',
    timestamp: new Date()
  };

  showChoices: boolean = false;
  selectedChoices: string[] = [];
  currentAssistant!: Assistant;
  chatAvailable: boolean = false;

  @Output() choicesSubmitted = new EventEmitter<string[]>();

  constructor(
    private chatService: ChatService,
    private assistantService: AssistantService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.chatService.activeChats$, chats => this.activeChats = chats);
    this.subscribe(this.chatService.currentChat$, chat => {
      this.currentChat = chat;
      this.showChoices = false; // Reset choices when chat changes
      if (!chat.assistant.id) {
        this.messageInput.disable();
      } else {
        this.messageInput.enable();
      }
    });
    this.subscribe(this.chatService.waitingForResponse$, waitingForResponse => this.waitingForResponse = waitingForResponse);
    this.subscribe(this.chatService.waitingForRating$, waitingForRating => this.waitingForRating = waitingForRating);
    this.subscribe(this.assistantService.assistants$, assistants => this.assistants = assistants);
    this.subscribe(this.chatService.chatAvailable$, chatAvailable => this.chatAvailable = chatAvailable);
  }

  async ngAfterViewInit(): Promise<void> {
    // await this.chatService.loadLastUsedChat();
  }

  toggleChoice(choice: string): void {
    const index = this.selectedChoices.indexOf(choice);
    if (index === -1) {
      this.selectedChoices.push(choice);
    } else {
      this.selectedChoices.splice(index, 1);
    }
  }

  getAssistantColors(assistantId: string): any {
    return this.assistantService.getAssistantColors(assistantId);
  }

  getCurrentChatData(): Chat | undefined {
    return this.activeChats.find(chat => chat === this.currentChat);
  }

  getLatestMessage(): Message | undefined {
    const chat = this.getCurrentChatData();
    if (!chat || chat.messages.length === 0) return;
    const latestMessage = chat.messages[chat.messages.length - 1];
    this.showChoices = !!latestMessage.choices?.length;
    return latestMessage;
  }

  addChat(assistant: Assistant): void {
    this.chatService.addChat(assistant);
  }

  removeChat(chat: Chat): void {
    this.chatService.removeChat(chat);
  }

  setCurrentChat(chat: Chat): void {
    this.chatService.setCurrentChat(chat);
  }

  handleChoicesSubmitted(): void {
    this.choicesSubmitted.emit(this.selectedChoices);
  }

  async sendMessage() {
    const userInput = this.messageInput.value?.trim();
    if (!userInput || !this.chatAvailable) return;

    const userMessage: Message = {
      role: 'user',
      message: userInput,
      timestamp: new Date()
    };

    if (!this.currentChat) return;
    this.chatService.addMessage(this.currentChat, userMessage);
    this.messageInput.reset();
    this.resetTextareaHeight();

    this.chatService.sendMessageToAssistant(userInput);
  }

  resetTextareaHeight(): void {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.style.height = 'inherit';
    }
  }

  toggleShowChoices(): void {
    this.showChoices = !this.showChoices;
    if (!this.showChoices) {
      this.selectedChoices = [];
    }
  }

  autoGrow(event: any): void {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;
  }

  handleEnterKey(event: any): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    } else if (event.key === 'Enter' && event.metaKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }
}

