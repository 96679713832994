import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminBaseService } from '../admin/admin-base.service';
import { firstValueFrom } from 'rxjs';
import { Business, BusinessInput, BusinessUpdate } from './business.types';

@Injectable({
  providedIn: 'root',
})
export class BusinessService extends AdminBaseService<
  Business,
  BusinessInput,
  BusinessUpdate
> {
  private _selectedBusiness = signal<Business | null>(null);
  readonly selectedBusiness$ = this._selectedBusiness.asReadonly();

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async getBusinesses(forceRefresh = false): Promise<Business[]> {
    return this.fetchData('businesses', forceRefresh);
  }

  async getBusinessById(id: string): Promise<Business> {
    try {
      const response = await firstValueFrom(
        this.httpClient.get<Business>(
          `${this.API_URL}/api/admin/businesses/${id}`,
          { withCredentials: true }
        )
      );

      if (!response) {
        throw new Error('No data received');
      }

      return response;

    } catch (error) {
      throw this.handleError(error);
    }
  }

  async createBusiness(input: BusinessInput): Promise<Business> {
    try {
      return this.createItem('businesses', input);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async editBusiness(
    id: string,
    updates: BusinessUpdate
  ): Promise<Business> {
    return this.updateItem('businesses', id, updates);
  }

  async deleteBusiness(id: string): Promise<void> {
    return this.deleteItem('businesses', id);
  }

  setSelectedBusiness(business: Business | null): void {
    this._selectedBusiness.set(business);
  }
}
