import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToSpaces',
  standalone: true,
})
export class CamelCaseToSpacesPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Add space before capital letters and trim any extra spaces
    const spaced = value.replace(/([A-Z])/g, ' $1').trim();

    // Ensure first letter is capitalized
    return spaced.charAt(0).toUpperCase() + spaced.slice(1);
  }
}
