import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminBaseService } from '../admin/admin-base.service';
import { firstValueFrom } from 'rxjs';
import { QuestionSet, QuestionSetInput, QuestionSetUpdate } from './question-set.types';

@Injectable({
  providedIn: 'root'
})
export class QuestionSetService extends AdminBaseService<
  QuestionSet,
  QuestionSetInput,
  QuestionSetUpdate
> {
  private _selectedSet = signal<QuestionSet | null>(null);
  readonly selectedSet$ = this._selectedSet.asReadonly();

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async getQuestionSets(forceRefresh = false): Promise<QuestionSet[]> {
    return this.fetchData('question-sets', forceRefresh);
  }

  async getQuestionSetById(id: string): Promise<QuestionSet> {
    try {
      const response = await firstValueFrom(this.httpClient.get<QuestionSet>(
        `${this.API_URL}/api/admin/question-sets/${id}`,
        { withCredentials: true }
      ));

      if (!response) {
        throw new Error('No data received');
      }

      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async editQuestionSet(
    id: string,
    updates: QuestionSetUpdate
  ): Promise<QuestionSet> {
    return this.updateItem('question-sets', id, updates);
  }

  async createQuestionSet(
    set: QuestionSetInput
  ): Promise<QuestionSet> {
    return this.createItem('question-sets', set);
  }

  async deleteQuestionSet(id: string): Promise<void> {
    return this.deleteItem('question-sets', id);
  }

  setSelectedSet(set: QuestionSet | null): void {
    this._selectedSet.set(set);
  }
}
