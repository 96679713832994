import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AssistantService } from '~/app/services/assistant/assistant.admin.service';
import { Assistant, AssistantInput, AssistantUpdate } from '~/app/services/assistant/assistant.types';
import { ConsultantService } from '~/app/services/consultant/consultant.admin.service';
import { QuestionSetService } from '~/app/services/question-sets/question-set.service';
import { CamelCaseToSpacesPipe } from '~/app/pipes/camel-case-to-spaces.pipe';
import { InsightsService } from '~/app/services/insights/insights.user.service';
import { InsightType } from '~/app/types/insights';

interface AssistantForm {
  _id: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  consultantId: FormControl<string>;
  questionSetId: FormControl<string>;
  gptModel: FormControl<string | null>;
  targetInsightModelName: FormControl<string>;
  isActive: FormControl<boolean>;
  instructions: FormControl<string | null>;
}

@Component({
  selector: 'app-assistant-form',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, CamelCaseToSpacesPipe],
  templateUrl: './assistant-form.component.html',
  styleUrl: './assistant-form.component.scss',
})
export class AssistantFormComponent implements OnInit, OnDestroy {
  private _assistantService = inject(AssistantService);
  private _consultantService = inject(ConsultantService);
  private _questionSetService = inject(QuestionSetService);
  private _insightService = inject(InsightsService);
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);

  isEditing = false;
  currentAssistant = this._assistantService.selectedAssistant$;

  // Add signals for related data
  readonly consultants = this._consultantService.data;
  readonly questionSets = this._questionSetService.data;
  readonly models = this._assistantService.models;

  targetInsightModels: InsightType[] = [];

  assistantForm = new FormGroup<AssistantForm>({
    _id: new FormControl('', { nonNullable: true }),
    name: new FormControl('', { nonNullable: true }),
    description: new FormControl('', { nonNullable: true }),
    consultantId: new FormControl('', { nonNullable: true }),
    questionSetId: new FormControl('', { nonNullable: true }),
    gptModel: new FormControl(''),
    targetInsightModelName: new FormControl('', { nonNullable: true }),
    isActive: new FormControl(false, { nonNullable: true }),
    instructions: new FormControl('')
  });

  async ngOnInit() {
    await Promise.all([
      this._consultantService.getConsultants(),
      this._questionSetService.getQuestionSets(),
      this._assistantService.getModels()
    ]);

    const fetched = await this._insightService.getInsightTypes();
    this.targetInsightModels = Object.values(fetched);

    if (!this.currentAssistant()) {
      const id = this._route.snapshot.paramMap.get('id');
      if (id) {
        const loadedAssistant = await this._assistantService.getAssistantById(id);
        if (loadedAssistant) {
          this.setAssistantForm(loadedAssistant);
        }
      }
    } else {
      this.setAssistantForm(this.currentAssistant() as Assistant);
    }
  }

  ngOnDestroy() {
    this._assistantService.setSelectedAssistant(null);
  }

  setAssistantForm(assistant: Assistant) {
    this.assistantForm.patchValue({
      _id: assistant._id,
      name: assistant.name,
      description: assistant.description,
      consultantId: assistant.consultantId,
      questionSetId: assistant.questionSetId,
      targetInsightModelName: assistant.targetInsightModelName,
      isActive: assistant.isActive,
      instructions: assistant.instructions,
      gptModel: assistant.gptModel
    });

    this.isEditing = true;
  }

  async onSubmit() {
    if (!this.assistantForm.valid) return;

    const formData = this.assistantForm.getRawValue();

    if (!this.isEditing) {
      // Create new assistant
      const newAssistant: AssistantInput = {
        name: formData.name,
        description: formData.description,
        consultantId: formData.consultantId,
        questionSetId: formData.questionSetId,
        targetInsightModelName: formData.targetInsightModelName,
        instructions: formData.instructions
      };

      const created = await this._assistantService.createAssistant(
        newAssistant
      );
      if (!created) {
        this.notifyUser('Failed to create assistant');
        return;
      }

      this.notifyUser('Assistant created successfully', 'success');
      setTimeout(async () => {
        await this._router.navigate(['../', created._id], {
          relativeTo: this._route,
        });
      }, 4000);

      this._assistantService.setSelectedAssistant(created);
    } else {
      // Edit existing assistant
      const updateData: AssistantUpdate = {
        name: formData.name,
        description: formData.description,
        consultantId: formData.consultantId,
        questionSetId: formData.questionSetId,
        targetInsightModelName: formData.targetInsightModelName,
        instructions: formData.instructions,
        gptModel: formData.gptModel
      };

      const edited = await this._assistantService.editAssistant(
        formData._id,
        updateData
      );
      if (!edited) {
        this.notifyUser('Failed to edit assistant');
        return;
      }

      this._assistantService.setSelectedAssistant(edited);
      this.notifyUser('Assistant saved successfully', 'success');
    }
  }

  async toggleActivation() {
    if (!this.isEditing) return;

    try {
      await this._assistantService.activateAssistant(
        this.assistantForm.get('_id')!.value
      );
      this.notifyUser('Assistant activation status updated', 'success');
    } catch (error) {
      this.notifyUser('Failed to update assistant activation status');
    }
  }

  showToast = false;
  toastConfig = {
    message: '',
    type: 'error' as 'error' | 'success',
  };

  notifyUser(message: string, type: 'error' | 'success' = 'error') {
    this.showToast = true;
    this.toastConfig = { message, type };
    setTimeout(() => {
      this.showToast = false;
      this.toastConfig = { message: '', type: 'error' };
    }, 3000);
  }
}
