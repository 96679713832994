import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { provideIcons } from '@ng-icons/core';
import { lucideUndo2 } from '@ng-icons/lucide';
import { HlmIconModule } from '@spartan-ng/ui-icon-helm';
import {
  HlmMenuComponent,
  HlmMenuGroupComponent,
  HlmMenuItemRadioComponent,
  HlmMenuItemRadioDirective,
  HlmMenuLabelComponent,
} from '@spartan-ng/ui-menu-helm';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrl: './header-menu.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    HlmMenuComponent,   
    HlmMenuLabelComponent,
    HlmMenuItemRadioComponent,
    HlmMenuGroupComponent,
    HlmMenuItemRadioDirective,
    HlmIconModule,
  ],
  providers: [provideIcons({ lucideUndo2 })]
})
export class HeaderMenuComponent implements OnInit, OnDestroy{
  panelPositions = ['Dashboard', 'Onboarding'] as const;
  selectedPosition: 'dashboard' | 'onboarding' = 'dashboard';
  private _routerSubscription?: Subscription;
  
  constructor(private router: Router) {}

  ngOnInit() {
    this._routerSubscription = this.router.events // Listen to route changes and update selectedPosition
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.updateSelectedPosition(event.urlAfterRedirects);
      });

    this.updateSelectedPosition(this.router.url); // Initialize the selected position based on the current route
  }

  ngOnDestroy() {
    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
  }

  navigateTo(route: 'dashboard' | 'onboarding') {
    this.selectedPosition = route;
    this.router.navigate([`/${route}`]);
  }

  updateSelectedPosition(url: string) {
    if (url.includes('/dashboard')) {
      this.selectedPosition = 'dashboard';
    } else if (url.includes('/onboarding')) {
      this.selectedPosition = 'onboarding';
    }
  }
}
