import { Component, computed, inject, Signal } from '@angular/core';
import { QuestionSetService } from '../../services/question-sets/question-set.service';
import { QuestionSet } from '../../services/question-sets/question-set.types';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule, Router, NavigationEnd } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs/operators';
import { Edit, LucideAngularModule, RefreshCw, Trash2 } from 'lucide-angular';
import { EmptyStateComponent } from '../components/empty-state/empty-state.component';
import { ErrorDisplayComponent } from '../components/error-display/error-display.component';

@Component({
  selector: 'app-admin-question-sets',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LucideAngularModule,
    EmptyStateComponent,
    ErrorDisplayComponent
  ],
  templateUrl: './admin-question-sets.component.html',
  styleUrl: './admin-question-sets.component.scss'
})
export class AdminQuestionSetsComponent {
  readonly refresh = RefreshCw;
  readonly edit = Edit;
  readonly delete = Trash2;

  private _questionSetService = inject(QuestionSetService);

  // Access signals from service
  readonly questionSets = this._questionSetService.data;
  readonly loading = this._questionSetService.loading;
  readonly error = this._questionSetService.error;
  readonly lastFetched = this._questionSetService.lastFetched;

  // Computed value for displaying last fetched time
  readonly lastFetchedTime = computed(() => {
    const timestamp = this.lastFetched();
    if (!timestamp) return 'Never';
    return new Date(timestamp).toLocaleTimeString();
  });

  // Computed values
  readonly hasQuestionSets = computed(() => this.questionSets().length > 0);
  isEditingOrCreating: Signal<boolean>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.loadQuestionSets();
    // Create a function to check if we're in edit/create mode
    const checkEditingState = () => {
      const childRoute = this._route.firstChild;
      return !!childRoute && (
        childRoute.snapshot.url[0]?.path === 'new' ||
        !!childRoute.snapshot.paramMap.get('id')
      );
    };

    // Initialize with current route state
    const initialState = checkEditingState();

    this.isEditingOrCreating = toSignal(
      this._router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => checkEditingState()),
        // Start with initial state
        startWith(initialState)
      ),
      { initialValue: initialState }
    );
  }

  async loadQuestionSets(): Promise<void> {
    try {
      await this._questionSetService.getQuestionSets();
    } catch (error) {
      this._showError('Failed to load question sets');
    }
  }

  async retryLoad(): Promise<void> {
    await this.loadQuestionSets();
  }

  async refreshData(): Promise<void> {
    try {
      await this._questionSetService.getQuestionSets(true);
      this._showSuccess('Data refreshed successfully');
    } catch (error) {
      this._showError('Failed to refresh data');
    }
  }

  editSet(set: QuestionSet): void {
    this._questionSetService.setSelectedSet(set);
    this._router.navigate(['./', set._id], { relativeTo: this._route });
  }

  async deleteSet(id: string): Promise<void> {
    try {
      await this._questionSetService.deleteQuestionSet(id);
      this._showSuccess('Question set deleted successfully');
    } catch (error) {
      this._showError('Failed to delete question set');
    }
  }

  addNewSet(): void {
    this._router.navigate(['./new'], { relativeTo: this._route });
  }

  private _showError(message: string): void {
  }

  private _showSuccess(message: string): void {
    console.debug(message);
  }
}
