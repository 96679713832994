import * as i0 from '@angular/core';
import { inject, signal, effect, Directive, Input, booleanAttribute, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/cdk/menu';
import { CdkContextMenuTrigger, CdkMenuBar, CdkMenuGroup, CdkMenuItemCheckbox, CdkMenuItemRadio, CdkMenuItem, CdkMenuTrigger, CdkMenu } from '@angular/cdk/menu';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
class BrnContextMenuTriggerDirective {
  set align(value) {
    this._align.set(value);
  }
  set brnCtxMenuTriggerFor(value) {
    this._cdkTrigger.menuTemplateRef = value;
  }
  set brnCtxMenuTriggerData(value) {
    this._cdkTrigger.menuData = value;
  }
  constructor() {
    this._cdkTrigger = inject(CdkContextMenuTrigger, {
      host: true
    });
    this._align = signal(undefined);
    // once the trigger opens we wait until the next tick and then grab the last position
    // used to position the menu. we store this in our trigger which the brnMenu directive has
    // access to through DI
    this._cdkTrigger.opened.pipe(takeUntilDestroyed()).subscribe(() => setTimeout(() =>
    // eslint-disable-next-line
    this._cdkTrigger._spartanLastPosition =
    // eslint-disable-next-line
    this._cdkTrigger.overlayRef._positionStrategy._lastPosition));
    effect(() => {
      const align = this._align();
      if (!align) return;
      this._cdkTrigger.menuPosition = [{
        originX: align,
        originY: 'bottom',
        overlayX: align,
        overlayY: 'top'
      }, {
        originX: align,
        originY: 'top',
        overlayX: align,
        overlayY: 'bottom'
      }];
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnContextMenuTriggerDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnContextMenuTriggerDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnContextMenuTriggerDirective,
      selectors: [["", "brnCtxMenuTriggerFor", ""]],
      inputs: {
        align: "align",
        brnCtxMenuTriggerFor: "brnCtxMenuTriggerFor",
        brnCtxMenuTriggerData: "brnCtxMenuTriggerData"
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.CdkContextMenuTrigger])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnContextMenuTriggerDirective, [{
    type: Directive,
    args: [{
      selector: '[brnCtxMenuTriggerFor]',
      standalone: true,
      hostDirectives: [CdkContextMenuTrigger]
    }]
  }], () => [], {
    align: [{
      type: Input
    }],
    brnCtxMenuTriggerFor: [{
      type: Input
    }],
    brnCtxMenuTriggerData: [{
      type: Input
    }]
  });
})();
class BrnMenuBarDirective {
  /** @nocollapse */static {
    this.ɵfac = function BrnMenuBarDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuBarDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnMenuBarDirective,
      selectors: [["", "brnMenuBar", ""]],
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.CdkMenuBar])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuBarDirective, [{
    type: Directive,
    args: [{
      selector: '[brnMenuBar]',
      standalone: true,
      hostDirectives: [CdkMenuBar]
    }]
  }], null, null);
})();
class BrnMenuGroupDirective {
  /** @nocollapse */static {
    this.ɵfac = function BrnMenuGroupDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuGroupDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnMenuGroupDirective,
      selectors: [["", "brnMenuGroup", ""]],
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.CdkMenuGroup])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuGroupDirective, [{
    type: Directive,
    args: [{
      selector: '[brnMenuGroup]',
      standalone: true,
      hostDirectives: [CdkMenuGroup]
    }]
  }], null, null);
})();
class BrnMenuItemCheckboxDirective {
  constructor() {
    this._cdkMenuItem = inject(CdkMenuItemCheckbox, {
      host: true
    });
    this._checked = signal(this._cdkMenuItem.checked);
    this._disabled = signal(this._cdkMenuItem.disabled);
    this.triggered = this._cdkMenuItem.triggered;
  }
  set checked(value) {
    this._cdkMenuItem.checked = value;
    this._checked.set(value);
  }
  get checked() {
    return this._checked();
  }
  set disabled(value) {
    this._cdkMenuItem.disabled = value;
    this._disabled.set(value);
  }
  get disabled() {
    return this._disabled();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnMenuItemCheckboxDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuItemCheckboxDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnMenuItemCheckboxDirective,
      selectors: [["", "brnMenuItemCheckbox", ""]],
      hostVars: 3,
      hostBindings: function BrnMenuItemCheckboxDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("disabled", ctx._disabled());
          i0.ɵɵclassProp("checked", ctx._checked());
        }
      },
      inputs: {
        checked: [2, "checked", "checked", booleanAttribute],
        disabled: [2, "disabled", "disabled", booleanAttribute]
      },
      outputs: {
        triggered: "triggered"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵHostDirectivesFeature([i1.CdkMenuItemCheckbox])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuItemCheckboxDirective, [{
    type: Directive,
    args: [{
      selector: '[brnMenuItemCheckbox]',
      standalone: true,
      hostDirectives: [CdkMenuItemCheckbox],
      host: {
        '[class.checked]': '_checked()',
        '[disabled]': '_disabled()'
      }
    }]
  }], null, {
    checked: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    triggered: [{
      type: Output
    }]
  });
})();
class BrnMenuItemRadioDirective {
  constructor() {
    this._cdkMenuItem = inject(CdkMenuItemRadio, {
      host: true
    });
    this._checked = signal(this._cdkMenuItem.checked);
    this._disabled = signal(this._cdkMenuItem.disabled);
    this.triggered = this._cdkMenuItem.triggered;
  }
  set checked(value) {
    this._cdkMenuItem.checked = value;
    this._checked.set(value);
  }
  get checked() {
    return this._checked();
  }
  set disabled(value) {
    this._cdkMenuItem.disabled = value;
    this._disabled.set(value);
  }
  get disabled() {
    return this._disabled();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnMenuItemRadioDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuItemRadioDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnMenuItemRadioDirective,
      selectors: [["", "brnMenuItemRadio", ""]],
      hostVars: 3,
      hostBindings: function BrnMenuItemRadioDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("disabled", ctx._disabled());
          i0.ɵɵclassProp("checked", ctx._checked());
        }
      },
      inputs: {
        checked: [2, "checked", "checked", booleanAttribute],
        disabled: [2, "disabled", "disabled", booleanAttribute]
      },
      outputs: {
        triggered: "triggered"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵHostDirectivesFeature([i1.CdkMenuItemRadio])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuItemRadioDirective, [{
    type: Directive,
    args: [{
      selector: '[brnMenuItemRadio]',
      standalone: true,
      hostDirectives: [CdkMenuItemRadio],
      host: {
        '[class.checked]': '_checked()',
        '[disabled]': '_disabled()'
      }
    }]
  }], null, {
    checked: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    triggered: [{
      type: Output
    }]
  });
})();
class BrnMenuItemDirective {
  constructor() {
    this._cdkMenuItem = inject(CdkMenuItem, {
      host: true
    });
    this._disabled = signal(this._cdkMenuItem.disabled);
    this.triggered = this._cdkMenuItem.triggered;
  }
  set disabled(value) {
    this._cdkMenuItem.disabled = value;
    this._disabled.set(value);
  }
  get disabled() {
    return this._disabled();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnMenuItemDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuItemDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnMenuItemDirective,
      selectors: [["", "brnMenuItem", ""]],
      hostVars: 1,
      hostBindings: function BrnMenuItemDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("disabled", ctx._disabled());
        }
      },
      inputs: {
        disabled: [2, "disabled", "disabled", booleanAttribute]
      },
      outputs: {
        triggered: "triggered"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵHostDirectivesFeature([i1.CdkMenuItem])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuItemDirective, [{
    type: Directive,
    args: [{
      selector: '[brnMenuItem]',
      standalone: true,
      hostDirectives: [CdkMenuItem],
      host: {
        '[disabled]': '_disabled()'
      }
    }]
  }], null, {
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    triggered: [{
      type: Output
    }]
  });
})();
class BrnMenuTriggerDirective {
  set align(value) {
    this._align.set(value);
  }
  constructor() {
    this._cdkTrigger = inject(CdkMenuTrigger, {
      host: true
    });
    this._align = signal(undefined);
    // once the trigger opens we wait until the next tick and then grab the last position
    // used to position the menu. we store this in our trigger which the brnMenu directive has
    // access to through DI
    this._cdkTrigger.opened.pipe(takeUntilDestroyed()).subscribe(() => setTimeout(() =>
    // eslint-disable-next-line
    this._cdkTrigger._spartanLastPosition =
    // eslint-disable-next-line
    this._cdkTrigger.overlayRef._positionStrategy._lastPosition));
    effect(() => {
      const align = this._align();
      if (!align) return;
      this._cdkTrigger.menuPosition = [{
        originX: align,
        originY: 'bottom',
        overlayX: align,
        overlayY: 'top'
      }, {
        originX: align,
        originY: 'top',
        overlayX: align,
        overlayY: 'bottom'
      }];
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnMenuTriggerDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuTriggerDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnMenuTriggerDirective,
      selectors: [["", "brnMenuTriggerFor", ""]],
      inputs: {
        align: "align"
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: i1.CdkMenuTrigger,
        inputs: ["cdkMenuTriggerFor", "brnMenuTriggerFor"]
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuTriggerDirective, [{
    type: Directive,
    args: [{
      selector: '[brnMenuTriggerFor]',
      standalone: true,
      hostDirectives: [{
        directive: CdkMenuTrigger,
        inputs: ['cdkMenuTriggerFor: brnMenuTriggerFor']
      }]
    }]
  }], () => [], {
    align: [{
      type: Input
    }]
  });
})();
class BrnMenuDirective {
  constructor() {
    this._host = inject(CdkMenu);
    this._state = signal('open');
    this._side = signal('top');
    this.setSideWithDarkMagic();
    // this is a best effort, but does not seem to work currently
    // TODO: figure out a way for us to know the host is about to be closed. might not be possible with CDK
    this._host.closed.pipe(takeUntilDestroyed()).subscribe(() => this._state.set('closed'));
  }
  setSideWithDarkMagic() {
    /**
     * This is an ugly workaround to at least figure out the correct side of where a submenu
     * will appear and set the attribute to the host accordingly
     *
     * First of all we take advantage of the menu stack not being aware of the root
     * object immediately after it is added. This code executes before the root element is added,
     * which means the stack is still empty and the peek method returns undefined.
     */
    const isRoot = this._host.menuStack.peek() === undefined;
    setTimeout(() => {
      // our menu trigger directive leaves the last position used for use immediately after opening
      // we can access it here and determine the correct side.
      // eslint-disable-next-line
      const ps = this._host._parentTrigger._spartanLastPosition;
      const side = isRoot ? ps.originY : ps.originX === 'end' ? 'right' : 'left';
      this._side.set(side);
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnMenuDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnMenuDirective,
      selectors: [["", "brnMenu", ""], ["", "brnSubMenu", ""]],
      hostVars: 2,
      hostBindings: function BrnMenuDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-state", ctx._state())("data-side", ctx._side());
        }
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.CdkMenu])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuDirective, [{
    type: Directive,
    args: [{
      selector: '[brnMenu],[brnSubMenu]',
      standalone: true,
      host: {
        '[attr.data-state]': '_state()',
        '[attr.data-side]': '_side()'
      },
      hostDirectives: [CdkMenu]
    }]
  }], () => [], null);
})();
const BrnMenuItemImports = [BrnMenuGroupDirective, BrnMenuItemDirective, BrnMenuItemRadioDirective, BrnMenuItemCheckboxDirective];
const BrnMenuImports = [BrnMenuTriggerDirective, BrnMenuDirective, ...BrnMenuItemImports];
const BrnMenuBarImports = [...BrnMenuImports, BrnMenuBarDirective];
const BrnContextMenuImports = [...BrnMenuImports, BrnContextMenuTriggerDirective];
class BrnMenuItemModule {
  /** @nocollapse */static {
    this.ɵfac = function BrnMenuItemModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuItemModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnMenuItemModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuItemModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnMenuItemImports],
      exports: [...BrnMenuItemImports]
    }]
  }], null, null);
})();
class BrnMenuModule {
  /** @nocollapse */static {
    this.ɵfac = function BrnMenuModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnMenuModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnMenuImports],
      exports: [...BrnMenuImports]
    }]
  }], null, null);
})();
class BrnMenuBarModule {
  /** @nocollapse */static {
    this.ɵfac = function BrnMenuBarModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnMenuBarModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnMenuBarModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnMenuBarModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnMenuBarImports],
      exports: [...BrnMenuBarImports]
    }]
  }], null, null);
})();
class BrnContextMenuModule {
  /** @nocollapse */static {
    this.ɵfac = function BrnContextMenuModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnContextMenuModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnContextMenuModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnContextMenuModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnContextMenuImports],
      exports: [...BrnContextMenuImports]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BrnContextMenuImports, BrnContextMenuModule, BrnContextMenuTriggerDirective, BrnMenuBarDirective, BrnMenuBarImports, BrnMenuBarModule, BrnMenuDirective, BrnMenuGroupDirective, BrnMenuImports, BrnMenuItemCheckboxDirective, BrnMenuItemDirective, BrnMenuItemImports, BrnMenuItemModule, BrnMenuItemRadioDirective, BrnMenuModule, BrnMenuTriggerDirective };
