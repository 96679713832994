import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, RouterLink, RouterLinkActive, RouterOutlet, NavigationEnd } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, RouterOutlet]
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  navItems = [
    { path: 'assistants', label: 'Assistants' },
    { path: 'insights', label: 'Insights' },
    { path: 'consultants', label: 'Consultants' },
    { path: 'business', label: 'Businesses' },
    { path: 'question-sets', label: 'Question Sets' }
  ];

  activeTab = 'insights';
  private _destroy$ = new Subject<void>();
  constructor(private _router: Router) { }

  ngOnInit() {
    // Set initial active tab based on current route
    const currentPath = this._router.url.split('/').pop();
    this.activeTab = currentPath || 'insights';

    // Subscribe to route changes
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this._destroy$)
    ).subscribe((event: NavigationEnd) => {
      this.activeTab = event.url.split('/').pop() || 'insights';
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isActive(path: string): boolean {
    return this.activeTab === path;
  }
}
