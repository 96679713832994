import { Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { EmailSentComponent } from './components/login/sent/sent.component';
import { VerifyComponent } from './components/login/verify/verify.component';
import { InsightsDashboardComponent } from './components/insights-dashboard/insights-dashboard.component';
import { sessionGuard } from './guards/session.guard';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { adminGuard, adminRedirectGuard } from './guards/admin.guard';
import { loginGuard } from './guards/login.guard';
import { AdminInsightsComponent } from './admin/admin-insights/admin-insights.component';
import { AdminQuestionSetsComponent } from './admin/admin-question-sets/admin-question-sets.component';
import { QuestionSetFormComponent } from './admin/admin-question-sets/components/question-set-form/question-set-form.component';
import { AdminConsultantsComponent } from './admin/admin-consultants/admin-consultants.component';
import { AdminAssistantsComponent } from './admin/admin-assistants/admin-assistants.component';
import { AssistantFormComponent } from './admin/admin-assistants/components/assistant-form/assistant-form.component';
import { ConsultantFormComponent } from './admin/admin-consultants/components/consultant-form/consultant-form.component';
import { FigmaImportsPage } from './components/figma-imports.component';
import { AdminBusinessComponent } from './admin/admin-business/admin-business.component';
import { BusinessFormComponent } from './admin/admin-business/components/admin-business-form/admin-business-form.component';
import { CheckoutComponent } from './components/stripe/checkout/checkout.component';
import { SuccessComponent } from './components/stripe/success/success.component';
import { environment } from '~/environments/environment';
import { subscriptionGuard } from './guards/subscription.guard';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { OnboardingSuccessComponent } from './components/onboarding-success/onboarding-success.component';
import { UpdateBusinessComponent } from './components/update-business/update-business.component';


export const routes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: 'verify',
        component: VerifyComponent,
        title: `Verify Login`,
      },
      {
        path: 'sent',
        component: EmailSentComponent,
        title: `Email Sent`,
      },
      {
        path: '',
        component: LoginComponent,
        canActivate: [loginGuard],
        title: `Login`,
      },
    ],
  },
  {
    path: 'dashboard',
    component: InsightsDashboardComponent,
    canActivate: [sessionGuard, subscriptionGuard],
    title: `Dashboard`,
  },
  { 
    path: 'onboarding', 
    component: OnboardingComponent,
    canActivate: [sessionGuard, subscriptionGuard],
    title: 'Blueprint | Onboarding',
  },
  { 
    path: 'update-business', 
    component: UpdateBusinessComponent,
    canActivate: [sessionGuard, subscriptionGuard],
    title: 'Blueprint | Update-Business',
  },
  { 
    path: 'onboarding-success', 
    component: OnboardingSuccessComponent,
    canActivate: [sessionGuard, subscriptionGuard],
    title: 'Blueprint | Onboarding-Success',
  },
  {
    path: 'subscribe',
    children: [
      {
        path: 'success',
        component: SuccessComponent,
        title: `Success`,
      },
      {
        path: '',
        component: CheckoutComponent,
        canActivate: [sessionGuard],
        title: `Subscribe`,
      }
    ]
  },
  {
    path: '',
    component: LandingComponent,
    canActivate: [sessionGuard],
    title: `Welcome`,
  },
  {
    path: 'admin',
    children: [
      {
        path: 'login',
        component: AdminLoginComponent,
        canActivate: [adminRedirectGuard],
        title: `Admin Login`,
      },
      {
        path: 'dashboard',
        component: AdminDashboardComponent,
        canActivate: [adminGuard],
        title: `Admin Dashboard`,
        children: [
          {
            path: 'assistants',
            component: AdminAssistantsComponent,
            title: `Admin Assistants`,
            children: [
              {
                path: 'new',
                component: AssistantFormComponent,
                title: `New Assistant`,
              },
              {
                path: ':id',
                component: AssistantFormComponent,
                title: `Edit Assistant`,
              },
            ],
          },
          {
            path: 'business',
            component: AdminBusinessComponent,
            title: `Admin Business`,
            children: [
              {
                path: 'new',
                component: BusinessFormComponent,
                title: `New Business`,
              },
              {
                path: ':id',
                component: BusinessFormComponent,
                title: `Edit Business`,
              }
            ]
          },
          {
            path: 'insights',
            component: AdminInsightsComponent,
            title: `Admin Insights`,
          },
          {
            path: 'consultants',
            component: AdminConsultantsComponent,
            title: `Admin Consultants`,
            children: [
              {
                path: 'new',
                component: ConsultantFormComponent,
                title: `New Consultant`,
              },
              {
                path: ':id',
                component: ConsultantFormComponent,
                title: `Edit Consultant`,
              },
            ]
          },
          {
            path: 'question-sets',
            component: AdminQuestionSetsComponent,
            title: `Question Sets`,
            children: [
              {
                path: 'new',
                component: QuestionSetFormComponent,
                title: `New Question Set`,
              },
              {
                path: ':id',
                component: QuestionSetFormComponent,
                title: `Edit Question Set`,
              },
            ],
          },
        ],
      },
    ],
  },
  environment.production
    ? {
      path: 'figma-imports',
      redirectTo: '**',
    }
    : {
      path: 'figma-imports',
      component: FigmaImportsPage,
      title: `Figma Imports`,
    },
  {
    path: '**',
    redirectTo: 'login',
  },
];
