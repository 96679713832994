import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private _titleSubject = new BehaviorSubject<string>('');               // Holds the current title
  public title$: Observable<string> = this._titleSubject.asObservable(); // Observable for subscription

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.listenToRouteChanges();
  }

  setTitle(newTitle: string) {
    this._titleSubject.next(newTitle);                                   // Updates the current title
  }

  private listenToRouteChanges() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {      
        let newTitle = 'Default Title';    // Extract title from either string or symbol key    
        const symbolKeys = Object.getOwnPropertySymbols(data);  // Check for symbol keys
        if (symbolKeys.length) {
          newTitle = data[symbolKeys[0]];  // Use first symbol key
        } else if (data['title']) {
          newTitle = data['title'];        // Fallback for string key
        }
        this.setTitle(newTitle);  // Update title via BehaviorSubject
      });
  }
}
