import { Component, inject } from '@angular/core';
import { InsightModel, InsightModelService } from '../../services/insights/insight.admin.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ModelDisplayComponent } from './components/model-display/model-display.component';
import { LucideAngularModule, RefreshCwIcon } from 'lucide-angular';

@Component({
  selector: 'app-admin-insights',
  standalone: true,
  imports: [CommonModule, RouterModule, ModelDisplayComponent, LucideAngularModule],
  templateUrl: './admin-insights.component.html',
  styleUrl: './admin-insights.component.scss',
})
export class AdminInsightsComponent {
  readonly refresh = RefreshCwIcon;
  private _adminInsightService = inject(InsightModelService);

  readonly insightModels = this._adminInsightService.data;
  readonly loading = this._adminInsightService.loading;
  readonly error = this._adminInsightService.error;

  constructor() {
    this.loadInsightModels();
  }

  async loadInsightModels(): Promise<void> {
    try {
      await this._adminInsightService.getInsightModels();
    } catch (error) {
      // Handle error if needed
      // console.error('Failed to load insight models:', error);
    }
  }

  // Optional: Add refresh functionality
  async refreshData(): Promise<void> {
    try {
      await this._adminInsightService.getInsightModels(true);
    } catch (error) {
      console.error('Failed to refresh insight models:', error);
    }
  }

  getRequiredFieldsCount(model: InsightModel): number {
    return model.schemaFields.filter((f: any) => f.isRequired).length;
  }
}
