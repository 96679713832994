  <div class="relative flex flex-col items-center justify-center min-h-screen">
      <h1>Login</h1>
      <form [formGroup]="loginForm" class="w-[50vw] flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <label class="font-bold text-grey-700 text-sm" for="email">Email Address</label>
          <input
            type="email"
            id="email"
            formControlName="email"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          @if (submitted && loginForm.get('email')?.errors; as errors) {
            @if (errors['required']) {
              <div class="error-message">Email is required</div>
            }
            @if (errors['email']) {
              <div class="error-message">Must be a valid email address</div>
            }
          }
        </div>
        <div class="flex flex-col gap-2">
          <label class="font-bold text-grey-700 text-sm" for="password">Password</label>
          <input
            type="password"
            id="password"
            formControlName="password"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          @if (submitted && loginForm.get('password')?.errors; as errors) {
            @if (errors['required']) {
              <div class="error-message">Password is required</div>
            }
            @if (errors['minlength']) {
              <div class="error-message">Password must be at least 6 characters</div>
            }
          }
        </div>
        @if (loading) {
          <button type="button" disabled>Sending...</button>
        } @else {
          <button
            [disabled]="loginForm.invalid"
            class="w-full bg-primary-600 text-secondary-default font-compressed text-2xl py-2 px-4 rounded focus:outline-none mt-4"
            (click)="onSubmit()"
          >
            Login
          </button>
        }
      </form>
    </div>
