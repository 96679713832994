import { Component, computed, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  NavigationEnd,
} from '@angular/router';
import {
  LucideAngularModule,
  EditIcon,
  RefreshCwIcon,
  Trash2Icon,
} from 'lucide-angular';
import { BusinessService } from '../../services/business/business.admin.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs/operators';
import type { Business } from '../../services/business/business.types';
import { EmptyStateComponent } from '../components/empty-state/empty-state.component';
import { AdminBusinessCardComponent } from './components/business-card/business-card.component';
import { ErrorDisplayComponent } from '../components/error-display/error-display.component';

@Component({
  selector: 'app-admin-business',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LucideAngularModule,
    EmptyStateComponent,
    ErrorDisplayComponent,
    AdminBusinessCardComponent
  ],
  templateUrl: './admin-business.component.html',
  styleUrls: ['./admin-business.component.scss'],
})
export class AdminBusinessComponent implements OnInit {
  private readonly _businessService = inject(BusinessService);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  // UI state
  readonly icons = {
    refresh: RefreshCwIcon,
    edit: EditIcon,
    delete: Trash2Icon,
  };

  // Data signals
  readonly businesses = computed(() => this._businessService.data());
  readonly isLoading = computed(() => this._businessService.loading());
  readonly error = computed(() => this._businessService.error());

  // Navigation state
  private checkEditingState(): boolean {
    const childRoute = this._route.firstChild;
    return (
      !!childRoute &&
      (childRoute.snapshot.url[0]?.path === 'new' ||
        !!childRoute.snapshot.paramMap.get('id'))
    );
  }

  readonly isEditingOrCreating = toSignal(
    this._router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(() => this.checkEditingState()),
      startWith(this.checkEditingState())
    ),
    { initialValue: this.checkEditingState() }
  );

  async ngOnInit() {
    await this.refresh();
  }

  // Actions
  async refresh(forceRefresh = true) {
    await this._businessService.getBusinesses(forceRefresh);
  }

  createNew() {
    this._router.navigate(['./new'], { relativeTo: this._route });
  }

  edit(business: Business) {
    this._businessService.setSelectedBusiness(business);
    this._router.navigate(['./', business._id], { relativeTo: this._route });
  }

  async delete(id: string) {
    if (await this.confirmDelete()) {
      await this._businessService.deleteBusiness(id);
      await this.refresh();
    }
  }

  private confirmDelete(): Promise<boolean> {
    return Promise.resolve(
      window.confirm('Are you sure you want to delete this business?')
    );
  }
}
