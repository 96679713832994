<div class="bg-white border-b px-6 py-4">

  <div class="flex gap-2 overflow-x-auto pb-2">
    <button
      *ngFor="let chat of activeChats"
      (click)="setCurrentChat.emit(chat)"
      [class]="
        'flex items-center gap-2 px-4 py-2 rounded-full text-sm ' +
        (currentChat === chat
          ? getAssistantColors(chat.assistant.id).bg + ' text-white'
          : 'bg-gray-100 text-gray-600 hover:bg-gray-200')
      "
    >
      <span>{{ getAssistantName(chat.assistant.id) }}</span>
      <lucide-icon
        *ngIf="activeChats.length > 1"
        [img]="XIcon"
        [size]="14"
        (click)="$event.stopPropagation(); removeChat.emit(chat)"
        class="hover:bg-black/10 rounded"
      ></lucide-icon>
    </button>
  </div>
</div>
