import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import io from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { Observable, Observer } from 'rxjs';

export enum ServerEvents {
  error = 'error',
  response = 'response',
  result = 'result',
  init = 'init'
}

export enum ClientEvents {
  init = 'init',
  send = 'send'
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: Socket;

  constructor() {
    this.socket = io(environment.apiUrl, { withCredentials: true });
  }

  connect(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.socket.on('connect', () => {
        console.log('Connected to WebSocket');
      });

      this.socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket');
        observer.complete();
      });

      this.socket.on('error', (error) => {
        console.error('WebSocket error:', error);
        observer.error(error);
      });
    });
  }

  emit(event: ClientEvents, data?: any): void {
    this.socket.emit(event, data);
  }

  on(events: ServerEvents[]): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      events.forEach((event) => {
        this.socket.on(event, (data) => {
          observer.next(data);
        });
      });

      return () => {
        events.forEach((event) => {
          this.socket.off(event);
        });
      };
    });
  }
}
