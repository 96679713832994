import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatService } from '~/app/services/chat.service';
import { ClientEvents, WebSocketService } from '~/app/services/web-socket.service';

@Component({
  selector: 'app-star-rating',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './star-rating.component.html',
  styleUrl: './star-rating.component.scss'
})
export class StarRatingComponent {

  private _webSocketService = inject(WebSocketService);
  private _chatService = inject(ChatService);
  stars = [1, 2, 3, 4, 5];
  rating = 0;
  comment = '';
  hoveredRating = 0;
  submitted = false;

  handleMouseMove(event: MouseEvent, starNumber: number) {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left;
    const halfWidth = rect.width / 2;

    this.hoveredRating = x < halfWidth ? starNumber - 0.5 : starNumber;
  }

  handleClick(event: MouseEvent, starNumber: number) {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left;
    const halfWidth = rect.width / 2;

    this.rating = x < halfWidth ? starNumber - 0.5 : starNumber;
    this.hoveredRating = this.rating;
  }

  showHalfStar(starNumber: number): boolean {
    return Math.ceil(this.hoveredRating) === starNumber &&
           this.hoveredRating % 1 !== 0;
  }

  autoGrow(event: any): void {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;
  }

  handleSubmit(): void {
    this._webSocketService.emit(ClientEvents.rate, { rating: this.rating, comment: this.comment });
    this.submitted = true;
    setTimeout(() => {
      this._chatService.setWaitingForRating(false);
    }, 3000)
  }

}
