import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { take, firstValueFrom } from 'rxjs';
import { isPlatformServer } from '@angular/common';

export const loginGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);

  // During SSR, allow access without checking auth
  if (isPlatformServer(platformId)) return true;

  try {
    // Client-side auth flow
    await authService.loginWithToken();
    const isAdmin = await authService.isAdmin();
    const authState = await firstValueFrom(authService.authState$.pipe(take(1)));

    if (authState.isLoggedIn) {
      if (isAdmin) {
        return router.createUrlTree(['/admin/dashboard']);
      }
      return router.createUrlTree(['/dashboard']);
    }

    return true;
  } catch (error) {
    console.error('Login guard error:', error);
    return true; // Allow access to login page on error
  }
};
