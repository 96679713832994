@if (authState$ | async; as authState) {
  <ng-container *ngFor="let menuItem of menuItems">
    <button
      [class.enabled]="authState.isLoggedIn"
      (click)="setFocusedInsightModel(menuItem)" 
      [class.active]="menuItem.isActive"
      class="flex gap-2 justify-center items-center p-2.5 mt-1 w-full rounded-full min-h-[40px]"
    >
      <hlm-icon size='sm' [name]="menuItem.icon"/>
    </button>
  </ng-container>
}
