import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { StripeService } from 'ngx-stripe';
import { ActivatedRoute } from '@angular/router';
import { CURRENCIES, Currency, PaymentService, Price } from '../../../services/payment.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent implements OnInit {
  protected currency: Currency = 'cad';
  protected CURRENCIES: Currency[] = CURRENCIES;
  protected prices!: Price[];
  protected subscribed!: boolean;

  constructor(
    private authService: AuthService,
    private paymentService: PaymentService,
    private stripeService: StripeService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.prices = await this.paymentService.getSubscriptions();
    await this.updateSubscriptionStatus();
  }

  async updateSubscriptionStatus() {
    const { email, isSubscribed } = this.authService.getLatestAuthState();
    const { priceId, subscribed, cancelAt, renewAt } = await this.paymentService.getUserSubscription(email);
    if (subscribed) {
      this.prices.forEach(price => {
        if (price.id === priceId) price.subscribed = cancelAt
          ? { cancelAt: new Date(cancelAt * 1000) }
          : { renewAt: new Date(renewAt * 1000) };
      });
    }
    this.subscribed = isSubscribed;
    console.log(this.subscribed);
  }

  async setCurrency(event: any) {
    this.currency = event.target.value;
  }

  async checkout(priceId: string) {
    const currentPath = this.route.snapshot.url.join('/');
    const { email } = await firstValueFrom(this.authService.authState$);
    const { sessionId } = await this.paymentService.createCheckoutSession(priceId, this.currency, currentPath + 'subscribe/success', currentPath + 'subscribe', email);
    await firstValueFrom(this.stripeService.stripe.redirectToCheckout({ sessionId }));
  }

  async cancel() {
    const { email } = await firstValueFrom(this.authService.authState$);
    await this.paymentService.cancelSubscription(email);
    await this.updateSubscriptionStatus();
  }

  async renew() {
    const { email } = await firstValueFrom(this.authService.authState$);
    await this.paymentService.renewSubscription(email);
    await this.updateSubscriptionStatus();
  }
}
