import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessService } from '~/app/services/business/business.user.service';
import { BusinessWithRole } from '~/app/services/business/business.types';
import { FormsModule } from '@angular/forms';
import { HlmCardContentDirective, HlmCardDescriptionDirective, HlmCardDirective, HlmCardFooterDirective, HlmCardHeaderDirective, HlmCardTitleDirective } from '@spartan-ng/ui-card-helm';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-business',
  standalone: true,
  templateUrl: './update-business.component.html',
  styleUrls: ['./update-business.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    HlmCardDirective,
    HlmCardHeaderDirective,
    HlmCardTitleDirective,
    HlmCardContentDirective,
    HlmCardFooterDirective,
    HlmCardDescriptionDirective,
  ]
})
export class UpdateBusinessComponent implements OnInit {
  businesses: BusinessWithRole[] = [];
  isLoading = true;

  constructor(private _businessService: BusinessService, private _router: Router) {}

  ngOnInit(): void {
    this.loadBusinesses();
  }

  async loadBusinesses() {
    try {
      this.businesses = await this._businessService.getUserBusinesses();
    } catch (error) {
      console.error('Error loading businesses:', error);
    } finally {
      this.isLoading = false;
    }
  }

  async updateBusiness(business: BusinessWithRole) {
    const trimmedName = business.name.trim();
    if (!trimmedName) {
      alert('Business name cannot be empty.');
      return;
    }
  
    try {
      // Destructure and exclude 'role' from the business object
      const { role, ...businessData } = business;
  
      const updatedBusiness = await this._businessService.updateUserBusiness(
        business._id,
        { ...businessData, name: trimmedName } // Send only fields expected by Business
      );
  
      alert(`Business "${updatedBusiness.name}" updated successfully!`);
  
      // Update local list
      const index = this.businesses.findIndex(b => b._id === updatedBusiness._id);
      if (index !== -1) {
        // Retain the role while updating the rest
        this.businesses[index] = { ...updatedBusiness, role: business.role };
      }
    } catch (error) {
      console.error('Error updating business:', error);
      alert('Failed to update business.');
    }
  }  
}
