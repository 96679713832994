 <article
  class="flex relative flex-col p-4 w-full bg-white rounded-lg border border-solid border-zinc-200 max-w-[559px] max-md:max-w-full"
  (click)="toggleFocus()">
  <div class="flex">
    <div
      class="flex z-0 flex-col w-full whitespace-nowrap border-r-2 border-slate-950 max-md:max-w-full">
      <!-- card header -->
      <div class="flex w-full">
        <h2
          class="text-2xl font-semibold tracking-tight leading-none text-zinc-950 max-md:max-w-full overflow-hidden text-ellipsis whitespace-nowrap max-w-full"
          style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
          {{ displayValue }}
        </h2>
        <button
          *ngIf="isFocused()"
          class="flex gap-2 justify-center items-center self-start px-4 py-2.5 my-auto text-sm font-medium leading-5 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-200 min-h-[40px]"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/4a8ee8e5fad75d36f90fd2204f73184ed467eb21e49f5e2cd21836b4e1bad313?apiKey=2657c647a72a40e5bd9f8e334f049b96&"
            alt
            class="object-contain shrink-0 self-stretch my-auto w-4 aspect-square">
          <span>edit</span>
        </button>
      </div>
      <p class="mt-1.5 text-sm leading-5 text-zinc-500 max-md:max-w-full">{{
        description }}</p>

      <!-- Recursive rendering for nested objects -->
      <div *ngIf="hasNestedValues"
        class="flex relative flex-col mt-4 pl-4 border-l-2 border-zinc-200">
        <app-insight-property-card
          *ngFor="let item of nestedValues"
          [title]="item.key"
          [value]="item.value"
          [cardId]="cardId + '-' + item.key"
          class="mt-2 first:mt-0">
        </app-insight-property-card>
      </div>

      <!-- Array rendering -->
      <div *ngIf="isArray"
        class="flex relative flex-col mt-4 pl-4 border-l-2 border-zinc-200">
        <app-insight-property-card
          *ngFor="let item of value; let i = index"
          [title]="'Item ' + (i + 1)"
          [value]="item"
          [cardId]="cardId + '-' + i"
          class="mt-2 first:mt-0">
        </app-insight-property-card>
      </div>
    </div>
  </div>
  <span
    class="absolute left-3 self-start px-1 text-sm font-medium leading-none bg-white text-zinc-900 top-[-7px]">
    {{ value ? title : 'Insight Property' }}
  </span>
</article>
