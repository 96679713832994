import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LucideAngularModule, X } from 'lucide-angular';
import { Chat } from '../../../types/chat';
import { Assistant } from '../../../types/assistant';
import { AssistantService } from '../../../services/assistant/assistant.user.service';
import { BackButtonComponent } from '../../back/back.component';

@Component({
  selector: 'app-chat-header',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, BackButtonComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  readonly XIcon = X;
  @Input() activeChats!: Chat[];
  @Input() currentChat!: Chat;
  @Input() assistants!: Assistant[];
  @Output() addChat = new EventEmitter<Assistant>();
  @Output() removeChat = new EventEmitter<Chat>();
  @Output() setCurrentChat = new EventEmitter<Chat>();

  constructor(private assistantService: AssistantService) { }

  get availableAssistants(): Assistant[] {
    const activeAssistantIds = this.activeChats.map(({ assistant }) => assistant.id);
    return this.assistants.filter(assistant => !activeAssistantIds.includes(assistant.id));
  }

  getAssistantColors(assistantId: string) {
    return this.assistantService.getAssistantColors(assistantId);
  }

  getAssistantName(assistantId: string): string {
    return this.assistantService.getAssistantById(assistantId)?.name || '';
  }

}
