import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

export interface Price {
  id: string;
  prices: Record<Currency, {
    price: number;
    currency: string;
  }>,
  interval: string;
  subscribed?: { cancelAt?: Date, renewAt?: Date };
}

export interface Subscription {
  id: string;
  priceId: string;
  subscribed: boolean;
  cancelAt: number;
  renewAt: number;
}

export type Currency = "usd" | "cad";
export const CURRENCIES: Currency[] = ['usd', 'cad'];

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private readonly _API_URL = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  async getSubscriptions() {
    return await firstValueFrom(this.http.get(
      `${this._API_URL}/api/v1/payments/subscriptions`,
      { withCredentials: true }
    )) as Price[];
  }

  async getUserSubscription(email: string) {
    return await firstValueFrom(this.http.get(
      `${this._API_URL}/api/v1/payments/subscriptions/${email}`,
      { withCredentials: true }
    )) as Subscription;
  }

  async createCheckoutSession(priceId: string, currency: Currency, successPath: string, cancelPath: string, email: string) {
    return await firstValueFrom(this.http.post(
      `${this._API_URL}/api/v1/payments/checkout`,
      {
        priceId,
        currency,
        successPath,
        cancelPath,
        email
      },
      { withCredentials: true }
    )) as { sessionId: string };
  }

  async cancelSubscription(email: string) {
    await firstValueFrom(this.http.post(
      `${this._API_URL}/api/v1/payments/subscriptions/cancel`,
      { email },
      { withCredentials: true }
    ));
  }

  async renewSubscription(email: string) {
    await firstValueFrom(this.http.post(
      `${this._API_URL}/api/v1/payments/subscriptions/renew`,
      { email },
      { withCredentials: true }
    ));
  }
}
