import { Color } from "./color-scheme";
import { InsightType } from "./insights";

export interface Assistant {
  id: string;
  insightType: InsightType;
  name?: string;
  color: Color;
}

export const assistants: Assistant[] = [
  { id: 'asst_k0rvtpAI55vXwySYRLpgVzfV', insightType: 'Spark', color: 'blue' },
];

export const defaultAssistant = assistants[0];
