@if (authState$ | async; as authState) {
<header
  class="top-0 right-0 flex justify-between items-center space-x-4 w-full layout h-16"
>
  <button routerLink="/">
    <img
      class="h-12 w-24"
      src="/assets/svgs/bp-logo.svg"
      alt="Syzl Logo"
    />
  </button>
  <!-- TODO: Display the current path (Primary [in large text] > secondary [in small text] > tertiary [in small text]) -->
   <!-- Primary Path = Current Route -->
    <!-- Secondary Path(s) = either sub-path or focused insight path -->
  <button *ngIf="authState.isLoggedIn" class="flex gap-2 justify-center items-center self-stretch px-4 py-2.5 my-auto text-sm font-medium leading-5 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-200 min-h-[40px]">
    <img src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/4a8ee8e5fad75d36f90fd2204f73184ed467eb21e49f5e2cd21836b4e1bad313?apiKey=2657c647a72a40e5bd9f8e334f049b96&" alt="" class="object-contain shrink-0 self-stretch my-auto w-4 aspect-square">
    <span>Share</span>
  </button>
</header>
}