import { Assistant, defaultAssistant } from "./assistant";
import { Message } from "./message";

export interface Chat {
  assistant: Assistant;
  messages: Message[];
}

export const defaultChat: Chat = {
  assistant: defaultAssistant,
  messages: []
};
