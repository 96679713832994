<div class="p-6 max-w-2xl mx-auto">
  @if (showToast) {
<div class="px-4 py-2 rounded my-2" [ngClass]="toastConfig.type === 'success' ?
    'bg-success-50 text-success-700 border border-success-500' :
    'bg-danger-50 text-danger-700 border border-danger-500'">
  {{ toastConfig.message }}
</div>

  }
  <div class="mb-4">
    <button [routerLink]="['../']" class="text-xs text-primary-default hover:underline">
      Back
    </button>
  </div>

  <form [formGroup]="questionSetForm" (ngSubmit)="onSubmit()" class="space-y-8">
    <!-- Name Field -->
    <div class="space-y-2">
      <label for="name" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        Name
      </label>
      <input
        id="name"
        formControlName="name"
        placeholder="Enter question set name"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
    </div>

    <!-- Description Field -->
    <div class="space-y-2">
      <label for="description" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        Description
      </label>
      <textarea
        id="description"
        formControlName="description"
        placeholder="Enter description"
        rows="3"
        class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      ></textarea>
    </div>

    <!-- Target Insight Model Field -->
    <div class="space-y-2">
      <label for="targetInsightModel" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        Target Insight Model
      </label>
      <select
        id="targetInsightModel"
        formControlName="targetInsightModel"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        @for (model of targetInsightModels; track model) {
          <option [value]="model">{{ model | camelCaseToSpaces }}</option>
        }
      </select>
    </div>

    <!-- Questions Section -->
    <div formArrayName="questions" class="space-y-4">
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-semibold">Questions</h3>
        <button
          type="button"
          (click)="addQuestion()"
          class="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
        >
          Add Question
        </button>
      </div>

      @for (control of questions.controls; track $index) {
      <div class="flex gap-2 items-start">
        <textarea
          [formControlName]="$index"
          placeholder="Enter your question"
          rows="2"
          class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        ></textarea>
        <button
          type="button"
          (click)="removeQuestion($index)"
          class="inline-flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
        >
          Remove
        </button>
      </div>
      }

      @if (questions.controls.length === 0) {
      <div class="text-center py-8 border-2 border-dashed rounded-lg">
        <p class="text-sm text-muted-foreground">
          No questions added. Click 'Add Question' to get started.
        </p>
      </div>
      }
    </div>

    <!-- Submit Button -->
    <div class="flex justify-end">
      <button
        type="submit"
        [disabled]="!questionSetForm.valid || questionSetForm.pristine"
        class="inline-flex bg-primary-default text-secondary-default items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
      >
        Save Question Set
      </button>
    </div>
  </form>
</div>
