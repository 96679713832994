<!-- This code was generated by Builder.io -->
<div class="flex flex-row bg-white rounded-lg border border-solid border-zinc-200 h-full">
  @if (menuItems.length > 0) {
    <app-side-menu [menuItems]="menuItems"></app-side-menu>
  }
  <main class="flex flex-col min-w-[240px] w-[1224px] max-md:max-w-full md:w-full">
    <header class="flex flex-wrap gap-10 justify-between items-center px-4 w-full bg-white border-b border-zinc-200 min-h-[56px] text-zinc-900 max-md:max-w-full">
      <div class="relative">
        <select
          class="block appearance-none w-full bg-white border border-zinc-200 text-zinc-900 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-zinc-500">
          @for (business of businesses; track business.id) {
            <option [value]="business.id">{{ business.name }}</option>
          }
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-zinc-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"/></svg>
        </div>
      </div>
      <button class="flex gap-2 justify-center items-center self-stretch px-4 py-2.5 my-auto text-sm font-medium leading-5 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-200 min-h-[40px]">
        <img src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/4a8ee8e5fad75d36f90fd2204f73184ed467eb21e49f5e2cd21836b4e1bad313?apiKey=2657c647a72a40e5bd9f8e334f049b96&" alt="" class="object-contain shrink-0 self-stretch my-auto w-4 aspect-square">
        <span>Share</span>
      </button>
    </header>
    <div class="flex flex-wrap flex-1 size-full max-md:max-w-full">
      <div class="w-1/2 max-md:w-full max-h-full overflow-auto p-4">
        <app-insight-list></app-insight-list>
      </div>
      <div class="w-1/2 max-md:hidden max-h-full">
        <app-chat></app-chat>
      </div>
    </div>
  </main>
</div>
