import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { LucideAngularModule, SendHorizontal } from 'lucide-angular';
import { HeaderComponent } from './header/header.component';
import { MessageComponent } from './message/message.component';
import { Chat } from '../../types/chat';
import { ChatService } from '../../services/chat.service';
import { Message } from '../../types/message';
import { AssistantService } from '../../services/assistant/assistant.user.service';
import { Assistant } from '../../types/assistant';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LucideAngularModule,
    HeaderComponent,
    MessageComponent
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})

export class ChatComponent extends BaseComponent implements OnInit, AfterViewInit {
  // lucide icons
  readonly SendIcon = SendHorizontal

  activeChats: Chat[] = [];
  currentChat!: Chat;
  messageInput = new FormControl('');
  assistants: Assistant[] = [];
  waitingForResponse: boolean = false;
  loadingMessage: Message = {
    role: 'bot',
    message: '...',
    timestamp: new Date()
  };

  constructor(
    private chatService: ChatService,
    private assistantService: AssistantService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.chatService.activeChats$, chats => this.activeChats = chats)
    this.subscribe(this.chatService.currentChat$, chat => this.currentChat = chat)
    this.subscribe(this.chatService.waitingForResponse$, waitingForResponse => this.waitingForResponse = waitingForResponse)
    this.subscribe(this.assistantService.assistants$, assistants => this.assistants = assistants)
  }

  async ngAfterViewInit(): Promise<void> {
    await this.chatService.loadLastUsedChat();
  }

  getAssistantColors(assistantId: string): any {
    return this.assistantService.getAssistantColors(assistantId);
  }

  getCurrentChatData(): Chat | undefined {
    return this.activeChats.find(chat => chat === this.currentChat);
  }

  getLatestMessage(): Message | undefined {
    const chat = this.getCurrentChatData();
    if (!chat || chat.messages.length === 0) return;
    return chat.messages[chat.messages.length - 1];
  }

  addChat(assistant: Assistant): void {
    this.chatService.addChat(assistant);
  }

  removeChat(chat: Chat): void {
    this.chatService.removeChat(chat);
  }

  setCurrentChat(chat: Chat): void {
    this.chatService.setCurrentChat(chat);
  }

  async onChoicesSubmitted($event: string[]) {
    this.messageInput.setValue($event.join('\n '));
    await this.sendMessage();
  }

  async sendMessage() {
    const userInput = this.messageInput.value?.trim();
    if (!userInput) return;

    const userMessage: Message = {
      role: 'user',
      message: userInput,
      timestamp: new Date()
    };

    if (!this.currentChat) return;
    this.chatService.addMessage(this.currentChat, userMessage);
    this.messageInput.reset();

    this.chatService.sendMessageToAssistant(userInput);
  }
}

