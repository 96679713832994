<div class="w-full h-full flex flex-row layout">
  <!-- Sidebar Navigation -->
  <div class="flex flex-col md:w-56 h-full">
    <!-- Navigation -->
    <nav class="flex flex-col gap-1 p-2 bg-grey-100 rounded-lg">

      @for (item of navItems; track item.path) {
      <a
        [routerLink]="[item.path]"
        routerLinkActive="bg-white text-primary-500 shadow-sm font-semibold"
        [attr.aria-current]="isActive(item.path) ? 'page' : undefined"
        class="px-4 py-3 rounded-md text-grey-800 hover:bg-grey-50 transition-all duration-200 relative focus:outline-none"
        [class.active-tab]="isActive(item.path)">
        <span class="text-sm">{{item.label}}</span>
      </a>
      }
    </nav>
  </div>

  <!-- Main Content Area -->
  <div class="flex-1">
    <router-outlet></router-outlet>
  </div>
</div>
