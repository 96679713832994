// relative-time.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime',
  pure: true, // Pure pipes are efficient as they rely on immutable inputs
  standalone: true,
})
export class RelativeTimePipe implements PipeTransform {

  transform(value: Date | string | number): string {
    if (!value) return '';

    let date: Date;

    // Handle different input types
    if (value instanceof Date) {
      date = value;
    } else if (typeof value === 'string' || typeof value === 'number') {
      date = new Date(value);
    } else {
      return '';
    }

    if (isNaN(date.getTime())) {
      // Invalid date
      return '';
    }

    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 0) {
      // Future dates
      return 'Just now';
    }

    const intervals: { [key: string]: number } = {
      'year': 31536000,
      'month': 2592000,
      'week': 604800,
      'day': 86400,
      'hour': 3600,
      'minute': 60,
      'second': 1
    };

    for (const unit in intervals) {
      const intervalSeconds = intervals[unit];
      const counter = Math.floor(seconds / intervalSeconds);

      if (counter > 0) {
        if (unit === 'day' && counter >= 7) {
          // For dates older than a week, show the date in 'MMM dd, yyyy' format
          return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
        }

        // Singular or plural
        const unitDisplay = counter === 1 ? unit : `${unit}s`;
        return `${counter} ${unitDisplay} ago`;
      }
    }

    return 'Just now';
  }
}
