import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, firstValueFrom, Observable, pipe, throwError } from 'rxjs';
import { Business, BusinessWithRole, Membership } from './business.types';
import { BrowserService } from '../browser.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  private readonly _API_URL = environment.apiUrl;
  private readonly _STORAGE_KEY = 'focused_business';

  private _focusedBusinessSubject = new BehaviorSubject<Business | null>(null);  

  get focusedBusiness$(): Observable<Business | null> {
    return this._focusedBusinessSubject.asObservable();
  }

  // TODO: make local biz state subscribe to cloud, enforce only 1 user can be actively working on a business at a time, pointer to editing session , containing only 1 value not array
  // BizGuard checks permission of biz, another guard to see if their session is the editing session.
  setFocusedBusiness(business: Business | null): void {
    this._focusedBusinessSubject.next(business);
    if (business) {
      this._browserService.setLocalStorage(this._STORAGE_KEY, JSON.stringify(business));
    } else {
      this._browserService.removeLocalStorage(this._STORAGE_KEY); // Remove stale data
    }
  }

  constructor(
    private _httpClient: HttpClient,
    private _browserService: BrowserService // Add BrowserService dependency
  ) {
    const savedBusiness = this._loadInitialBusiness();
    if (savedBusiness) {
      this._focusedBusinessSubject.next(savedBusiness);
    }
  }

  private _loadInitialBusiness(): Business | null {
    try {
      const savedBusiness = this._browserService.getLocalStorage(this._STORAGE_KEY);
      return savedBusiness ? JSON.parse(savedBusiness) : null;
    } catch {
      return null;
    }
  }

  async getUserBusinesses(): Promise<BusinessWithRole[]> {
    const response = await firstValueFrom(
      this._httpClient.get<{ business: Business, role: string }[]>(`${this._API_URL}/api/v1/business`, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error getting user businesses:', error);
            return throwError(() => error);
          })
        )
    );

    if (response.length > 0) {
      const { business } = response[0];
      this.setFocusedBusiness(business);
    }

    const businesses = response.map(({ business, role }) => ({ ...business, role: role as Membership }));
    return businesses;
  }

  async createNewBusiness(businessName: string, businessDescription: string): Promise<Business> {
    const businessData = {
      name: businessName,
      description: businessDescription
    };

    const response = await firstValueFrom(
        this._httpClient.post<Business>(`${this._API_URL}/api/v1/business`, businessData, { withCredentials: true })
            .pipe(
                catchError(error => {
                    console.error('Error creating new business:', error);
                    return throwError(() => error);
                })
            )
    );
    
    console.log('Response Business:', response);
    this.setFocusedBusiness(response);
    return response;
  }

  async updateUserBusiness(businessId: string, business: Business): Promise<Business> {
    const response = await firstValueFrom(
      this._httpClient.put<Business>(`${this._API_URL}/api/v1/business/${businessId}`, business, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error updating user business:', error);
            return throwError(() => error);
          })
        )
    )
    return response;  // Make sure the API returns the updated business
  }
}
