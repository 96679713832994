<div
  [class]="
    'flex items-start gap-3 ' +
    (message.role === 'user' ? 'flex-row-reverse' : '')
  "
>
  <!-- Avatar -->
  <div
    [class]="
      'flex-shrink-0 rounded-full p-2 ' +
      (message.role === 'user'
        ? getAssistantColors(currentAssistant.id).light
        : 'bg-grey-100')
    "
  >
    <lucide-icon
      [img]="message.role === 'user' ? UserIcon : BotIcon"
      [size]="20"
      [class]="
        message.role === 'user'
          ? getAssistantColors(currentAssistant.id).text
          : 'text-grey-600'
      "
    ></lucide-icon>
  </div>

  <div
    [class]="
      'px-4 py-2 rounded-2xl max-w-[80%] mb-1 ' +
      (message.role === 'user'
        ? getAssistantColors(currentAssistant.id).bg + ' text-white'
        : 'bg-white border border-grey-200')
    "
  >
    <!-- Message Text -->
    <p class="text-sm whitespace-pre-line">{{ message.message }}</p>

    @if(message.choices && message.choices.length > 0 && latestMessage) {
      <!-- Choices -->
      <div class="mt-2 flex flex-wrap gap-2">
        @for (choice of message.choices; track choice) {
        <button
          [class]="'px-4 py-2 rounded-2xl text-sm ' +
          (selectedChoices.includes(choice)
          ? getAssistantColors(currentAssistant.id).bg + ' text-white'
          : message.role === 'user' ? getAssistantColors(currentAssistant.id).light + ' hover:' + getAssistantColors(currentAssistant.id).bg + ' text-white' : 'bg-grey-100 hover:bg-grey-200')"
          (click)="toggleChoice(choice)"
        >
          {{ choice }}
        </button>
        }
      </div>

      <!-- Submit Button -->
      <div class="mt-2 flex justify-end">
        <button
          [class]="'px-4 py-2 rounded-2xl text-sm ' + (selectedChoices.length > 0 ? getAssistantColors(currentAssistant.id).bg + ' text-white' : 'bg-grey-100 text-grey-600')"
          [disabled]="selectedChoices.length === 0"
          (click)="onChoicesSubmitted.emit(selectedChoices)"
        >
          <lucide-icon [img]="checkIcon" [size]="20"></lucide-icon>
        </button>
      </div>
    }

    <!-- Timestamp -->
    <time class="text-xs text-grey-400 mt-1">
      {{ message.timestamp | date : "shortTime" }}
    </time>
  </div>
</div>
