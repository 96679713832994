import { Component } from '@angular/core';
import { HlmDialogModule } from '@spartan-ng/ui-dialog-helm';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding-success',
  standalone: true,
  imports: [HlmDialogModule],
  templateUrl: './onboarding-success.component.html',
  styleUrl: './onboarding-success.component.scss'
})
export class OnboardingSuccessComponent {

  constructor(private _router: Router) { }

  closeDialog() {
    this._router.navigate(['/dashboard']);
  }

}
