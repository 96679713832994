@if (isLoading) {
  <div>Loading businesses...</div>
}

@else if (businesses.length === 0) {
  <div>No businesses found.</div>
}

@else {
  @for (business of businesses; track business._id) {
    <section hlmCard>
      <div hlmCardHeader>
        <!-- Display original business name -->
        <h3 style="font-weight: bold;" hlmCardTitle>{{ business.name }}</h3>
        <p hlmCardDescription>{{ business.description || '~ No description provided.' }}</p>
      </div>

      <p hlmCardContent>
        <label class="block" hlmLabel>Name
          <input
            class="mt-1.5 w-full"
            [(ngModel)]="business.name"
            placeholder="Business Name"
            hlmInput />
        </label>
      </p>
      
      <div hlmCardFooter class="justify-between">
        <button 
          style="background-color: #007bff; color: white; padding: 10px 20px; border: 1px solid #ccc; cursor: pointer; border-radius: 5px; flex: 1;"
          hlmBtn 
          (click)="updateBusiness(business)">
          Update
        </button>
      </div>
    </section>
  }
}
