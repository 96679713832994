import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionSetService } from '../../../../services/question-sets/question-set.service';
import { QuestionSet, QuestionSetInput, QuestionSetUpdate } from '../../../../services/question-sets/question-set.types';
import { InsightType } from '../../../../types/insights';
import { CamelCaseToSpacesPipe } from '../../../../pipes/camel-case-to-spaces.pipe';
import { InsightsService } from '~/app/services/insights/insights.user.service';

interface QuestionSetForm {
  _id: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  questions: FormArray<FormControl<string>>;
  targetInsightModel: FormControl<string>;
}

@Component({
  selector: 'app-question-set-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    CamelCaseToSpacesPipe
  ],
  templateUrl: './question-set-form.component.html',
  styleUrl: './question-set-form.component.scss'
})
export class QuestionSetFormComponent implements OnInit, OnDestroy {
  private _questionSetService = inject(QuestionSetService);
  private _insightService = inject(InsightsService);
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);

  isEditing = false;
  currentSet = this._questionSetService.selectedSet$;

  questionSetForm = new FormGroup<QuestionSetForm>({
    _id: new FormControl('', { nonNullable: true }),
    name: new FormControl('', { nonNullable: true }),
    description: new FormControl('', { nonNullable: true }),
    questions: new FormArray<FormControl<string>>([]),
    targetInsightModel: new FormControl('', { nonNullable: true }),
  });

  targetInsightModels: InsightType[] = [];

  async ngOnInit() {
    this.targetInsightModels = Object.values(await this._insightService.getInsightTypes());
    if (!this.currentSet()) {
      const id = this._route.snapshot.paramMap.get('id');
      if (id) {
        const loadedQuestionSet = await this._questionSetService.getQuestionSetById(id);
        if (loadedQuestionSet) {
          this.setQuestionSetForm(loadedQuestionSet);
        }
      }
    } else {
      this.setQuestionSetForm(this.currentSet() as QuestionSet);
    }
  }

  ngOnDestroy() {
    this._questionSetService.setSelectedSet(null);
  }

  createQuestionControl(): FormControl<string> {
    return new FormControl('', { nonNullable: true });
  }

  setQuestionSetForm(set: QuestionSet) {
    // Clear existing questions
    while (this.questions.length) {
      this.questions.removeAt(0);
    }

    // Add each question string
    set.questions.forEach(question => {
      this.questions.push(new FormControl(question, { nonNullable: true }));
    });

    // Set other values
    this.questionSetForm.patchValue({
      _id: set._id,
      name: set.name,
      description: set.description,
      targetInsightModel: set.targetInsightModel,
    });

    this.isEditing = true;
  }

  get questions() {
    return this.questionSetForm.get('questions') as FormArray<FormControl<string>>;
  }

  addQuestion() {
    this.questions.push(this.createQuestionControl());
  }

  removeQuestion(index: number) {
    this.questions.removeAt(index);
  }

  async onSubmit() {
    if (!this.questionSetForm.valid) return;

    const formData = this.questionSetForm.getRawValue();

    if (!this.isEditing) {
      // Create new question set
      const newQuestionSet: QuestionSetInput = {
        name: formData.name,
        description: formData.description,
        questions: formData.questions,
        targetInsightModel: formData.targetInsightModel
      };

      const newSet = await this._questionSetService.createQuestionSet(newQuestionSet);
      if (!newSet) {
        this.notifyUser('Failed to create question set');
        return;
      }

      this.notifyUser('Question set created successfully', 'success');
      setTimeout(async () => {
        await this._router.navigate(['../', newSet._id], { relativeTo: this._route });
      }, 4000);

      this._questionSetService.setSelectedSet(newSet);
    } else {
      // Edit existing question set
      const updateData: QuestionSetUpdate = {
        name: formData.name,
        description: formData.description,
        questions: formData.questions,
        targetInsightModel: formData.targetInsightModel
      };

      const edited = await this._questionSetService.editQuestionSet(formData._id, updateData);
      if (!edited) {
        this.notifyUser('Failed to edit question set');
        return;
      }

      this._questionSetService.setSelectedSet(edited);
      this.notifyUser('Question set saved successfully', 'success');
    }
  }

  showToast = false;
  toastConfig = {
    message: '',
    type: 'error' as 'error' | 'success'
  }

  notifyUser(message: string, type: 'error' | 'success' = 'error') {
    this.showToast = true;
    this.toastConfig = { message, type };
    setTimeout(() => {
      this.showToast = false;
      this.toastConfig = { message: '', type: 'error' };
    }, 3000);
  }
}
