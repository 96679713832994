export interface ColorScheme {
  bg: string;
  hover: string;
  light: string;
  text: string;
}

export type Color = 'blue';
export const DEFAULT_COLOR: Color = 'blue';

export const COLOR_SCHEMES: Record<Color, ColorScheme> = {
  blue: { bg: 'bg-primary-600', hover: 'hover:bg-primary-700', light: 'bg-primary-100', text: 'text-primary-600' },
}
