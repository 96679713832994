import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  standalone: true
})
export class AdminLoginComponent {
  private _router = inject(Router);
  private _authService = inject(AuthService);

  loginForm = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true
    }),
    password: new FormControl('', {
      validators: [Validators.required, Validators.minLength(6)],
      nonNullable: true
    })
  });

  loading = false;
  submitted = false;

  async onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    try {
      const { email, password } = this.loginForm.getRawValue();
      const loginResponse = await this._authService.loginWithPassword(email, password);

      if (loginResponse) {
        console.log('loginResponse:', loginResponse);
        await this._router.navigate(['/admin/dashboard']);
      }
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      this.loading = false;
    }
  }

  resetForm() {
    this.submitted = false;
    this.loginForm.reset();
  }
}
