<div class="p-6 max-w-2xl mx-auto">
  @if (showToast) {
    <div
      class="px-4 py-2 rounded my-2"
      [ngClass]="toastConfig.type === 'success' ?
        'bg-success-50 text-success-700 border border-success-500' :
        'bg-danger-50 text-danger-700 border border-danger-500'"
    >
      {{ toastConfig.message }}
    </div>
  }

  <div class="mb-4">
    <button [routerLink]="['../']" class="text-xs text-primary-default hover:underline">
      Back
    </button>
  </div>

  <form [formGroup]="businessForm" (ngSubmit)="onSubmit()" class="space-y-8">
    <!-- Basic Information -->
    <div class="space-y-4">
      <h2 class="text-lg font-semibold">Basic Information</h2>

      <!-- Name Field -->
      <div class="space-y-2">
        <label for="name" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          Name
        </label>
        <input
          id="name"
          formControlName="name"
          placeholder="Enter business name"
          class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        >
      </div>

      <!-- Description Field -->
      <div class="space-y-2">
        <label for="description" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          Description
        </label>
        <textarea
          id="description"
          formControlName="description"
          placeholder="Enter business description"
          rows="3"
          class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        ></textarea>
      </div>

      <!-- Company Details -->
      <div class="grid grid-cols-2 gap-4">
        <div class="space-y-2">
          <label for="founded" class="text-sm font-medium leading-none">
            Founded
          </label>
          <input
            type="date"
            id="founded"
            formControlName="founded"
            class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
        </div>

        <div class="space-y-2">
          <label for="employeeCount" class="text-sm font-medium leading-none">
            Employee Count
          </label>
          <input
            type="number"
            id="employeeCount"
            formControlName="employeeCount"
            placeholder="Number of employees"
            class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
        </div>

        <div class="space-y-2">
          <label for="industry" class="text-sm font-medium leading-none">
            Industry
          </label>
          <input
            id="industry"
            formControlName="industry"
            placeholder="Enter industry"
            class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
        </div>

        <div class="space-y-2">
          <label for="size" class="text-sm font-medium leading-none">
            Company Size
          </label>
          <input
            id="size"
            formControlName="size"
            placeholder="Enter company size"
            class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
        </div>
      </div>

      <!-- Contact Information -->
      <div class="grid grid-cols-2 gap-4">
        <div class="space-y-2">
          <label for="website" class="text-sm font-medium leading-none">
            Website
          </label>
          <input
            id="website"
            formControlName="website"
            placeholder="Enter website URL"
            class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
        </div>

        <div class="space-y-2">
          <label for="headquarters" class="text-sm font-medium leading-none">
            Headquarters
          </label>
          <input
            id="headquarters"
            formControlName="headquarters"
            placeholder="Enter headquarters location"
            class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
        </div>
      </div>

      <!-- Revenue -->
      <div class="space-y-2">
        <label for="revenue" class="text-sm font-medium leading-none">
          Revenue
        </label>
        <input
          id="revenue"
          formControlName="revenue"
          placeholder="Enter revenue"
          class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        >
      </div>

      @if (isEditing) {
      <div class="space-y-2">
        <label class="text-sm font-medium leading-none">
          Business Members
        </label>
        @for(member of currentBusiness()?.users; track member.businessId) {
        <div class="flex flex-col">
          <p>{{ member.userId.email }}</p>
          <span class="text-grey-500 italic">
            {{ member.role }}
          </span>
        </div>
        } @empty {
        <div class="text-muted-foreground">No members associated to this Business</div>
        }
      </div>
      }
    </div>

    <!-- Submit Button -->
    <div class="flex justify-end">
      <button
        type="submit"
        [disabled]="!businessForm.valid || businessForm.pristine"
        class="inline-flex bg-primary-default text-secondary-default items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
      >
        {{ isEditing ? 'Save Changes' : 'Create Business' }}
      </button>
    </div>
  </form>
</div>
