<div class="bg-white rounded-lg shadow-md p-6">
  <div class="flex flex-row justify-between items-start">
    <!-- Business Info -->
    <div class="flex flex-col flex-grow space-y-3">
      <div>
        <h2 class="text-lg font-semibold text-grey-900">
          {{ business().name }}
        </h2>
        <p class="text-sm text-grey-500 mt-1">{{ business().description }}</p>
      </div>

      <!-- Business Details -->
      <div class="grid grid-cols-2 gap-4 text-sm">
        @if (business().industry || business().size || business().employeeCount || business().founded) {
          <div class="space-y-2">
            @if (business().industry) {
              <div>
                <span class="text-grey-500">Industry:</span>
                <span class="ml-2 text-grey-700">{{ business().industry }}</span>
              </div>
            }
            @if (business().size) {
              <div>
                <span class="text-grey-500">Size:</span>
                <span class="ml-2 text-grey-700">{{ business().size }}</span>
              </div>
            }
            @if (business().employeeCount) {
              <div>
                <span class="text-grey-500">Employees:</span>
                <span class="ml-2 text-grey-700">{{ business().employeeCount }}</span>
              </div>
            }
            @if (business().founded) {
              <div>
                <span class="text-grey-500">Founded:</span>
                <span class="ml-2 text-grey-700">{{ formatDate(business().founded) }}</span>
              </div>
            }
          </div>
        }

        @if (business().headquarters || business().revenue || business().website) {
          <div class="space-y-2">
            @if (business().headquarters) {
              <div>
                <span class="text-grey-500">HQ:</span>
                <span class="ml-2 text-grey-700">{{ business().headquarters }}</span>
              </div>
            }
            @if (business().revenue) {
              <div>
                <span class="text-grey-500">Revenue:</span>
                <span class="ml-2 text-grey-700">{{ business().revenue }}</span>
              </div>
            }
            @if (business().website) {
              <div>
                <span class="text-grey-500">Website:</span>
                <a
                  href="{{ business().website }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="ml-2 text-primary-default hover:underline"
                >
                  {{ business().website }}
                </a>
              </div>
            }
          </div>
        }
      </div>

      <!-- Products/Services Tags -->
      @if (business().products?.length || business().services?.length) {
        <div class="flex flex-wrap gap-2">
          @for (product of business().products ?? []; track product) {
            <span class="px-2 py-1 text-xs rounded-full bg-primary-50 text-primary-700">
              {{ product }}
            </span>
          }
          @for (service of business().services ?? []; track service) {
            <span class="px-2 py-1 text-xs rounded-full bg-secondary-50 text-secondary-700">
              {{ service }}
            </span>
          }
        </div>
      }
    </div>

    <!-- Actions -->
    <div class="flex gap-2 ml-4">
      <button
        class="text-grey-400 hover:text-primary-600 transition-colors"
        (click)="edit.emit(business())"
      >
        <lucide-angular [img]="icons.edit" class="h-4" />
      </button>
      <button
        class="text-grey-400 hover:text-danger-600 transition-colors"
        (click)="delete.emit(business()._id)"
      >
        <lucide-angular [img]="icons.delete" class="h-4" />
      </button>
    </div>
  </div>
</div>
