/**
 * This code was generated by Builder.io.
 */
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from '../../side-menu/menu/menu.component';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, MenuComponent],
})
export class SideMenuComponent {
  @Input() menuItems: any[] = [];
}
