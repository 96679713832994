import { CommonModule } from '@angular/common';
import { Component, inject, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BaseComponent } from '../base.component';
import { LucideAngularModule, SquareUser } from 'lucide-angular';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    LucideAngularModule,
    RouterLink
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends BaseComponent {
  readonly userIcon = SquareUser;
  
  private readonly _router = inject(Router);

  protected readonly authState$;
  
  constructor(
    private readonly _authService: AuthService
  ) {
    super();
    this.authState$ = this._authService.authState$;
  }

  login() {
    this._router.navigate(['/login']);
  }

  async logout() {
    await this._authService.logout();
  }
}
