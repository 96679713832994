import * as i0 from '@angular/core';
import { inject, Directive, Input, RendererFactory2, Injector, signal, computed, runInInjectionContext, effect, Injectable, ViewContainerRef, EventEmitter, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Output, TemplateRef, input, NgModule } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Subject, take, takeUntil, filter } from 'rxjs';
import { provideExposesStateProviderExisting, provideCustomClassSettableExisting } from '@spartan-ng/ui-core';
import { OverlayPositionBuilder, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DIALOG_DATA, Dialog } from '@angular/cdk/dialog';

// brn-dialog-utils.ts
const _c0 = ["*"];
const cssClassesToArray = (classes, defaultClass = '') => {
  if (typeof classes === 'string') {
    const splitClasses = classes.trim().split(' ');
    if (splitClasses.length === 0) {
      return [defaultClass];
    }
    return splitClasses;
  }
  return classes ?? [];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class BrnDialogRef {
  get open() {
    return this.state() === 'open';
  }
  constructor(_cdkDialogRef, _open, state, dialogId, _options) {
    this._cdkDialogRef = _cdkDialogRef;
    this._open = _open;
    this.state = state;
    this.dialogId = dialogId;
    this._options = _options;
    this._closing$ = new Subject();
    this.closing$ = this._closing$.asObservable();
    this.closed$ = this._cdkDialogRef.closed.pipe(take(1));
  }
  close(result, delay = this._options?.closeDelay ?? 0) {
    if (!this.open || this._options?.disableClose) return;
    this._closing$.next();
    this._open.set(false);
    if (this._previousTimeout) {
      clearTimeout(this._previousTimeout);
    }
    this._previousTimeout = setTimeout(() => {
      this._cdkDialogRef.close(result);
    }, delay);
  }
  setPanelClass(paneClass) {
    this._cdkDialogRef.config.panelClass = cssClassesToArray(paneClass);
  }
  setOverlayClass(overlayClass) {
    this._cdkDialogRef.config.backdropClass = cssClassesToArray(overlayClass);
  }
  setAriaDescribedBy(ariaDescribedBy) {
    this._cdkDialogRef.config.ariaDescribedBy = ariaDescribedBy;
  }
  setAriaLabelledBy(ariaLabelledBy) {
    this._cdkDialogRef.config.ariaLabelledBy = ariaLabelledBy;
  }
  setAriaLabel(ariaLabel) {
    this._cdkDialogRef.config.ariaLabel = ariaLabel;
  }
}
class BrnDialogCloseDirective {
  constructor() {
    this._brnDialogRef = inject(BrnDialogRef);
  }
  set delay(value) {
    this._delay = coerceNumberProperty(value);
  }
  close() {
    this._brnDialogRef.close(undefined, this._delay);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogCloseDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogCloseDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnDialogCloseDirective,
      selectors: [["button", "brnDialogClose", ""]],
      hostBindings: function BrnDialogCloseDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function BrnDialogCloseDirective_click_HostBindingHandler() {
            return ctx.close();
          });
        }
      },
      inputs: {
        delay: "delay"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogCloseDirective, [{
    type: Directive,
    args: [{
      selector: 'button[brnDialogClose]',
      standalone: true,
      host: {
        '(click)': 'close()'
      }
    }]
  }], null, {
    delay: [{
      type: Input
    }]
  });
})();
const DEFAULT_BRN_DIALOG_OPTIONS = {
  role: 'dialog',
  attachPositions: [],
  attachTo: null,
  autoFocus: 'first-tabbable',
  backdropClass: '',
  closeDelay: 0,
  closeOnBackdropClick: true,
  closeOnOutsidePointerEvents: false,
  hasBackdrop: true,
  panelClass: '',
  positionStrategy: null,
  restoreFocus: true,
  scrollStrategy: null,
  disableClose: false,
  ariaLabel: undefined,
  ariaModal: true
};
let dialogSequence = 0;
/** @deprecated `injectBrnDialogCtx` will no longer be supported once components are stable. Use `injectBrnDialogContext` instead.  */
const injectBrnDialogCtx = () => {
  return inject(DIALOG_DATA);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const injectBrnDialogContext = (options = {}) => {
  return inject(DIALOG_DATA, options);
};
class BrnDialogService {
  constructor() {
    this._cdkDialog = inject(Dialog);
    this._rendererFactory = inject(RendererFactory2);
    this._renderer = this._rendererFactory.createRenderer(null, null);
    this._positionBuilder = inject(OverlayPositionBuilder);
    this._sso = inject(ScrollStrategyOptions);
    this._injector = inject(Injector);
  }
  open(content, vcr, context, options) {
    if (options?.id && this._cdkDialog.getDialogById(options.id)) {
      throw new Error(`Dialog with ID: ${options.id} already exists`);
    }
    const positionStrategy = options?.positionStrategy ?? (options?.attachTo && options?.attachPositions && options?.attachPositions?.length > 0 ? this._positionBuilder?.flexibleConnectedTo(options.attachTo).withPositions(options.attachPositions ?? []) : this._positionBuilder.global().centerHorizontally().centerVertically());
    let brnDialogRef;
    let effectRef;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const contextOrData = {
      ...context,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      close: (result = undefined) => brnDialogRef.close(result, options?.closeDelay)
    };
    const destroyed$ = new Subject();
    const open = signal(true);
    const state = computed(() => open() ? 'open' : 'closed');
    const dialogId = dialogSequence++;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cdkDialogRef = this._cdkDialog.open(content, {
      id: options?.id ?? `brn-dialog-${dialogId}`,
      role: options?.role,
      viewContainerRef: vcr,
      templateContext: () => ({
        $implicit: contextOrData
      }),
      data: contextOrData,
      hasBackdrop: options?.hasBackdrop,
      panelClass: cssClassesToArray(options?.panelClass),
      backdropClass: cssClassesToArray(options?.backdropClass, 'bg-transparent'),
      positionStrategy,
      scrollStrategy: options?.scrollStrategy ?? this._sso?.block(),
      restoreFocus: options?.restoreFocus,
      disableClose: true,
      autoFocus: options?.autoFocus ?? 'first-tabbable',
      ariaDescribedBy: options?.ariaDescribedBy ?? `brn-dialog-description-${dialogId}`,
      ariaLabelledBy: options?.ariaLabelledBy ?? `brn-dialog-title-${dialogId}`,
      ariaLabel: options?.ariaLabel,
      ariaModal: options?.ariaModal,
      providers: cdkDialogRef => {
        brnDialogRef = new BrnDialogRef(cdkDialogRef, open, state, dialogId, options);
        runInInjectionContext(this._injector, () => {
          effectRef = effect(() => {
            if (overlay) {
              this._renderer.setAttribute(overlay, 'data-state', state());
            }
            if (backdrop) {
              this._renderer.setAttribute(backdrop, 'data-state', state());
            }
          });
        });
        const providers = [{
          provide: BrnDialogRef,
          useValue: brnDialogRef
        }];
        if (options?.providers) {
          if (typeof options.providers === 'function') {
            providers.push(...options.providers());
          }
          if (Array.isArray(options.providers)) {
            providers.push(...options.providers);
          }
        }
        return providers;
      }
    });
    const overlay = cdkDialogRef.overlayRef.overlayElement;
    const backdrop = cdkDialogRef.overlayRef.backdropElement;
    if (options?.closeOnOutsidePointerEvents) {
      cdkDialogRef.outsidePointerEvents.pipe(takeUntil(destroyed$)).subscribe(() => {
        brnDialogRef.close(undefined, options?.closeDelay);
      });
    }
    if (options?.closeOnBackdropClick) {
      cdkDialogRef.backdropClick.pipe(takeUntil(destroyed$)).subscribe(() => {
        brnDialogRef.close(undefined, options?.closeDelay);
      });
    }
    if (!options?.disableClose) {
      cdkDialogRef.keydownEvents.pipe(filter(e => e.key === 'Escape'), takeUntil(destroyed$)).subscribe(() => {
        brnDialogRef.close(undefined, options?.closeDelay);
      });
    }
    cdkDialogRef.closed.pipe(takeUntil(destroyed$)).subscribe(() => {
      effectRef?.destroy();
      destroyed$.next();
    });
    return brnDialogRef;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogService)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BrnDialogService,
      factory: BrnDialogService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class BrnDialogComponent {
  constructor() {
    this._dialogService = inject(BrnDialogService);
    this._vcr = inject(ViewContainerRef);
    this.positionBuilder = inject(OverlayPositionBuilder);
    this.ssos = inject(ScrollStrategyOptions);
    this._injector = inject(Injector);
    this._context = {};
    this._options = {
      ...DEFAULT_BRN_DIALOG_OPTIONS
    };
    this._dialogRef = signal(undefined);
    this.state = computed(() => this._dialogRef()?.state() ?? 'closed');
    this.closed = new EventEmitter();
    this.stateChanged = new EventEmitter();
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set newState(state) {
    if (state === 'open') {
      this.open();
    }
    if (state === 'closed') {
      this.close(this._options.closeDelay);
    }
  }
  set role(role) {
    this._options.role = role;
  }
  set hasBackdrop(hasBackdrop) {
    this._options.hasBackdrop = hasBackdrop;
  }
  set positionStrategy(positionStrategy) {
    this._options.positionStrategy = positionStrategy;
  }
  set scrollStrategy(scrollStrategy) {
    if (scrollStrategy === 'close') {
      this._options.scrollStrategy = this.ssos.close();
    } else if (scrollStrategy === 'reposition') {
      this._options.scrollStrategy = this.ssos.reposition();
    } else {
      this._options.scrollStrategy = scrollStrategy;
    }
  }
  set restoreFocus(restoreFocus) {
    this._options.restoreFocus = restoreFocus;
  }
  set closeOnOutsidePointerEvents(closeOnOutsidePointerEvents) {
    this._options.closeOnOutsidePointerEvents = closeOnOutsidePointerEvents;
  }
  set closeOnBackdropClick(closeOnBackdropClick) {
    this._options.closeOnBackdropClick = closeOnBackdropClick;
  }
  set attachTo(attachTo) {
    this._options.attachTo = attachTo;
  }
  set attachPositions(attachPositions) {
    this._options.attachPositions = attachPositions;
  }
  set autoFocus(autoFocus) {
    this._options.autoFocus = autoFocus;
  }
  set closeDelay(closeDelay) {
    this._options.closeDelay = closeDelay;
  }
  set disableClose(disableClose) {
    this._options.disableClose = disableClose;
  }
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  set ariaDescribedBy(ariaDescribedBy) {
    this.setAriaDescribedBy(ariaDescribedBy);
  }
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  set ariaLabelledBy(ariaLabelledBy) {
    this.setAriaLabelledBy(ariaLabelledBy);
  }
  set ariaLabel(ariaLabel) {
    this.setAriaLabel(ariaLabel);
  }
  set ariaModal(isModal) {
    this.setAriaModal(isModal);
  }
  open() {
    if (!this._contentTemplate || this._dialogRef()) return;
    this._dialogStateEffectRef?.destroy();
    const dialogRef = this._dialogService.open(this._contentTemplate, this._vcr, this._context, this._options);
    this._dialogRef.set(dialogRef);
    runInInjectionContext(this._injector, () => {
      this._dialogStateEffectRef = effect(() => this.stateChanged.emit(dialogRef.state()));
    });
    dialogRef.closed$.pipe(take(1)).subscribe(result => {
      this._dialogRef.set(undefined);
      this.closed.emit(result);
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  close(result, delay) {
    this._dialogRef()?.close(result, delay ?? this._options.closeDelay);
  }
  registerTemplate(template) {
    this._contentTemplate = template;
  }
  setOverlayClass(overlayClass) {
    this._options.backdropClass = overlayClass ?? '';
    this._dialogRef()?.setOverlayClass(overlayClass);
  }
  setPanelClass(panelClass) {
    this._options.panelClass = panelClass ?? '';
    this._dialogRef()?.setPanelClass(panelClass);
  }
  setContext(context) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this._context = {
      ...this._context,
      ...context
    };
  }
  setAriaDescribedBy(ariaDescribedBy) {
    this._options = {
      ...this._options,
      ariaDescribedBy
    };
    this._dialogRef()?.setAriaDescribedBy(ariaDescribedBy);
  }
  setAriaLabelledBy(ariaLabelledBy) {
    this._options = {
      ...this._options,
      ariaLabelledBy
    };
    this._dialogRef()?.setAriaLabelledBy(ariaLabelledBy);
  }
  setAriaLabel(ariaLabel) {
    this._options = {
      ...this._options,
      ariaLabel
    };
    this._dialogRef()?.setAriaLabel(ariaLabel);
  }
  setAriaModal(ariaModal) {
    this._options = {
      ...this._options,
      ariaModal
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnDialogComponent,
      selectors: [["brn-dialog"]],
      inputs: {
        newState: [0, "state", "newState"],
        role: "role",
        hasBackdrop: [2, "hasBackdrop", "hasBackdrop", booleanAttribute],
        positionStrategy: "positionStrategy",
        scrollStrategy: "scrollStrategy",
        restoreFocus: "restoreFocus",
        closeOnOutsidePointerEvents: [2, "closeOnOutsidePointerEvents", "closeOnOutsidePointerEvents", booleanAttribute],
        closeOnBackdropClick: [2, "closeOnBackdropClick", "closeOnBackdropClick", booleanAttribute],
        attachTo: "attachTo",
        attachPositions: "attachPositions",
        autoFocus: "autoFocus",
        closeDelay: [2, "closeDelay", "closeDelay", numberAttribute],
        disableClose: [2, "disableClose", "disableClose", booleanAttribute],
        ariaDescribedBy: [0, "aria-describedby", "ariaDescribedBy"],
        ariaLabelledBy: [0, "aria-labelledby", "ariaLabelledBy"],
        ariaLabel: [0, "aria-label", "ariaLabel"],
        ariaModal: [2, "aria-modal", "ariaModal", booleanAttribute]
      },
      outputs: {
        closed: "closed",
        stateChanged: "stateChanged"
      },
      exportAs: ["brnDialog"],
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function BrnDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogComponent, [{
    type: Component,
    args: [{
      selector: 'brn-dialog',
      standalone: true,
      template: `
		<ng-content />
	`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      exportAs: 'brnDialog'
    }]
  }], null, {
    closed: [{
      type: Output
    }],
    stateChanged: [{
      type: Output
    }],
    newState: [{
      type: Input,
      args: ['state']
    }],
    role: [{
      type: Input
    }],
    hasBackdrop: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    positionStrategy: [{
      type: Input
    }],
    scrollStrategy: [{
      type: Input
    }],
    restoreFocus: [{
      type: Input
    }],
    closeOnOutsidePointerEvents: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    closeOnBackdropClick: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    attachTo: [{
      type: Input
    }],
    attachPositions: [{
      type: Input
    }],
    autoFocus: [{
      type: Input
    }],
    closeDelay: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    disableClose: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    ariaDescribedBy: [{
      type: Input,
      args: ['aria-describedby']
    }],
    ariaLabelledBy: [{
      type: Input,
      args: ['aria-labelledby']
    }],
    ariaLabel: [{
      type: Input,
      args: ['aria-label']
    }],
    ariaModal: [{
      type: Input,
      args: [{
        alias: 'aria-modal',
        transform: booleanAttribute
      }]
    }]
  });
})();
class BrnDialogContentDirective {
  set class(newClass) {
    if (!this._brnDialog) return;
    this._brnDialog.setPanelClass(newClass);
  }
  set context(context) {
    if (!this._brnDialog) return;
    this._brnDialog.setContext(context);
  }
  constructor() {
    this._brnDialog = inject(BrnDialogComponent, {
      optional: true
    });
    this._brnDialogRef = inject(BrnDialogRef, {
      optional: true
    });
    this._template = inject(TemplateRef);
    this.state = computed(() => this._brnDialog?.state() ?? this._brnDialogRef?.state() ?? 'closed');
    if (!this._brnDialog) return;
    this._brnDialog.registerTemplate(this._template);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogContentDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogContentDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnDialogContentDirective,
      selectors: [["", "brnDialogContent", ""]],
      inputs: {
        class: "class",
        context: "context"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([provideExposesStateProviderExisting(() => BrnDialogContentDirective)])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogContentDirective, [{
    type: Directive,
    args: [{
      selector: '[brnDialogContent]',
      standalone: true,
      providers: [provideExposesStateProviderExisting(() => BrnDialogContentDirective)]
    }]
  }], () => [], {
    class: [{
      type: Input
    }],
    context: [{
      type: Input
    }]
  });
})();
class BrnDialogDescriptionDirective {
  constructor() {
    this._brnDialogRef = inject(BrnDialogRef);
    this._id = signal(`brn-dialog-description-${this._brnDialogRef?.dialogId}`);
    effect(() => {
      this._brnDialogRef.setAriaDescribedBy(this._id());
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogDescriptionDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogDescriptionDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnDialogDescriptionDirective,
      selectors: [["", "brnDialogDescription", ""]],
      hostVars: 1,
      hostBindings: function BrnDialogDescriptionDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx._id());
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogDescriptionDirective, [{
    type: Directive,
    args: [{
      selector: '[brnDialogDescription]',
      standalone: true,
      host: {
        '[id]': '_id()'
      }
    }]
  }], () => [], null);
})();
class BrnDialogOverlayComponent {
  constructor() {
    this._brnDialog = inject(BrnDialogComponent);
  }
  set class(newClass) {
    this._brnDialog.setOverlayClass(newClass);
  }
  setClassToCustomElement(newClass) {
    this._brnDialog.setOverlayClass(newClass);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogOverlayComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogOverlayComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnDialogOverlayComponent,
      selectors: [["brn-dialog-overlay"]],
      inputs: {
        class: "class"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([provideCustomClassSettableExisting(() => BrnDialogOverlayComponent)]), i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function BrnDialogOverlayComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'brn-dialog-overlay',
      standalone: true,
      template: '',
      providers: [provideCustomClassSettableExisting(() => BrnDialogOverlayComponent)],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }], null, {
    class: [{
      type: Input
    }]
  });
})();
class BrnDialogTitleDirective {
  constructor() {
    this._brnDialogRef = inject(BrnDialogRef);
    this._id = signal(`brn-dialog-title-${this._brnDialogRef?.dialogId}`);
    effect(() => {
      this._brnDialogRef.setAriaLabelledBy(this._id());
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogTitleDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogTitleDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnDialogTitleDirective,
      selectors: [["", "brnDialogTitle", ""]],
      hostVars: 1,
      hostBindings: function BrnDialogTitleDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx._id());
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogTitleDirective, [{
    type: Directive,
    args: [{
      selector: '[brnDialogTitle]',
      standalone: true,
      host: {
        '[id]': '_id()'
      }
    }]
  }], () => [], null);
})();
let idSequence = 0;
class BrnDialogTriggerDirective {
  constructor() {
    this._brnDialog = inject(BrnDialogComponent, {
      optional: true
    });
    this._brnDialogRef = inject(BrnDialogRef, {
      optional: true
    });
    this.id = input(`brn-dialog-trigger-${idSequence++}`);
    this.state = this._brnDialogRef?.state ?? signal('closed');
    this.dialogId = `brn-dialog-${this._brnDialogRef?.dialogId ?? idSequence++}`;
  }
  set brnDialogTriggerFor(brnDialog) {
    this._brnDialog = brnDialog;
  }
  open() {
    this._brnDialog?.open();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function BrnDialogTriggerDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogTriggerDirective)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnDialogTriggerDirective,
      selectors: [["button", "brnDialogTrigger", ""], ["button", "brnDialogTriggerFor", ""]],
      hostAttrs: ["aria-haspopup", "dialog"],
      hostVars: 4,
      hostBindings: function BrnDialogTriggerDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function BrnDialogTriggerDirective_click_HostBindingHandler() {
            return ctx.open();
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id());
          i0.ɵɵattribute("aria-expanded", ctx.state() === "open" ? "true" : "false")("data-state", ctx.state())("aria-controls", ctx.dialogId);
        }
      },
      inputs: {
        id: [1, "id"],
        brnDialogTriggerFor: "brnDialogTriggerFor"
      },
      exportAs: ["brnDialogTrigger"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogTriggerDirective, [{
    type: Directive,
    args: [{
      selector: 'button[brnDialogTrigger],button[brnDialogTriggerFor]',
      standalone: true,
      host: {
        '[id]': 'id()',
        '(click)': 'open()',
        'aria-haspopup': 'dialog',
        '[attr.aria-expanded]': "state() === 'open' ? 'true': 'false'",
        '[attr.data-state]': 'state()',
        '[attr.aria-controls]': 'dialogId'
      },
      exportAs: 'brnDialogTrigger'
    }]
  }], null, {
    brnDialogTriggerFor: [{
      type: Input
    }]
  });
})();
const BrnDialogImports = [BrnDialogComponent, BrnDialogOverlayComponent, BrnDialogTriggerDirective, BrnDialogCloseDirective, BrnDialogContentDirective, BrnDialogTitleDirective, BrnDialogDescriptionDirective];
class BrnDialogModule {
  /** @nocollapse */static {
    this.ɵfac = function BrnDialogModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnDialogModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnDialogModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnDialogModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnDialogImports],
      exports: [...BrnDialogImports]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BrnDialogCloseDirective, BrnDialogComponent, BrnDialogContentDirective, BrnDialogDescriptionDirective, BrnDialogImports, BrnDialogModule, BrnDialogOverlayComponent, BrnDialogRef, BrnDialogService, BrnDialogTitleDirective, BrnDialogTriggerDirective, DEFAULT_BRN_DIALOG_OPTIONS, cssClassesToArray, injectBrnDialogContext, injectBrnDialogCtx };
