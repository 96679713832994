import { Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { EmailSentComponent } from './components/login/sent/sent.component';
import { VerifyComponent } from './components/login/verify/verify.component';
import { InsightsDashboardComponent } from './components/insights-dashboard/insights-dashboard.component';
import { sessionGuard } from './guards/session.guard';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { adminGuard, adminRedirectGuard } from './guards/admin.guard';
import { loginGuard } from './guards/login.guard';
import { AdminInsightsComponent } from './admin/admin-insights/admin-insights.component';
import { AdminQuestionSetsComponent } from './admin/admin-question-sets/admin-question-sets.component';
import { QuestionSetFormComponent } from './admin/admin-question-sets/components/question-set-form/question-set-form.component';
import { AdminConsultantsComponent } from './admin/admin-consultants/admin-consultants.component';
import { AdminAssistantsComponent } from './admin/admin-assistants/admin-assistants.component';
import { AssistantFormComponent } from './admin/admin-assistants/components/assistant-form/assistant-form.component';
import { ConsultantFormComponent } from './admin/admin-consultants/components/consultant-form/consultant-form.component';
import { FigmaImportsPage } from './components/figma-imports.component';
import { AdminBusinessComponent } from './admin/admin-business/admin-business.component';
import { BusinessFormComponent } from './admin/admin-business/components/admin-business-form/admin-business-form.component';
import { CheckoutComponent } from './components/stripe/checkout/checkout.component';
import { SuccessComponent } from './components/stripe/success/success.component';
import { environment } from '~/environments/environment';
import { subscriptionGuard } from './guards/subscription.guard';


export const routes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: 'verify',
        component: VerifyComponent,
        title: 'Blueprint | Verify Login',
      },
      {
        path: 'sent',
        component: EmailSentComponent,
        title: 'Blueprint | Email Sent',
      },
      {
        path: '',
        component: LoginComponent,
        canActivate: [loginGuard],
        title: 'Blueprint | Login',
      },
    ],
  },
  {
    path: 'dashboard',
    component: InsightsDashboardComponent,
    canActivate: [sessionGuard, subscriptionGuard],
    title: 'Blueprint | Dashboard',
  },

  {
    path: 'subscribe',
    children: [
      {
        path: 'success',
        component: SuccessComponent,
        title: 'Blueprint | Success',
      },
      {
        path: '',
        component: CheckoutComponent,
        canActivate: [sessionGuard],
        title: 'Blueprint | Subscribe',
      }
    ]
  },
  {
    path: '',
    component: LandingComponent,
    canActivate: [sessionGuard],
    title: 'Blueprint | Welcome',
  },
  {
    path: 'admin',
    children: [
      {
        path: 'login',
        component: AdminLoginComponent,
        canActivate: [adminRedirectGuard],
        title: 'Blueprint | Admin Login',
      },
      {
        path: 'dashboard',
        component: AdminDashboardComponent,
        canActivate: [adminGuard],
        title: 'Blueprint | Admin Dashboard',
        children: [
          {
            path: 'assistants',
            component: AdminAssistantsComponent,
            title: 'Blueprint | Admin Assistants',
            children: [
              {
                path: 'new',
                component: AssistantFormComponent,
                title: 'Blueprint | New Assistant',
              },
              {
                path: ':id',
                component: AssistantFormComponent,
                title: 'Blueprint | Edit Assistant',
              },
            ],
          },
          {
            path: 'business',
            component: AdminBusinessComponent,
            title: 'Blueprint | Admin Business',
            children: [
              {
                path: 'new',
                component: BusinessFormComponent,
                title: 'Blueprint | New Business',
              },
              {
                path: ':id',
                component: BusinessFormComponent,
                title: 'Blueprint | Edit Business',
              }
            ]
          },
          {
            path: 'insights',
            component: AdminInsightsComponent,
            title: 'Blueprint | Admin Insights',
          },
          {
            path: 'consultants',
            component: AdminConsultantsComponent,
            title: 'Blueprint | Admin Consultants',
            children: [
              {
                path: 'new',
                component: ConsultantFormComponent,
                title: 'Blueprint | New Consultant',
              },
              {
                path: ':id',
                component: ConsultantFormComponent,
                title: 'Blueprint | Edit Consultant',
              },
            ]
          },
          {
            path: 'question-sets',
            component: AdminQuestionSetsComponent,
            title: 'Blueprint | Question Sets',
            children: [
              {
                path: 'new',
                component: QuestionSetFormComponent,
                title: 'Blueprint | New Question Set',
              },
              {
                path: ':id',
                component: QuestionSetFormComponent,
                title: 'Blueprint | Edit Question Set',
              },
            ],
          },
        ],
      },
    ],
  },
  environment.production
    ? {
      path: 'figma-imports',
      redirectTo: '**',
    }
    : {
      path: 'figma-imports',
      component: FigmaImportsPage,
      title: 'Blueprint | Figma Imports',
    },
  {
    path: '**',
    redirectTo: 'login',
  },
];
