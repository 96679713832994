import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root' // This makes it a singleton
})
export class InsightFocusService {
  focusedInsightModelChange: Subject<string | null> = new Subject<string | null>();

  private focusedCardId: string | null = null;
  private focusedInsightModel: string | null = null;

  getFocusedCardId(): string | null {
    return this.focusedCardId;
  }

  setFocusedCardId(cardId: string | null) {
    this.focusedCardId = cardId;
  }

  getFocusedInsightModel(): string | null {
    return this.focusedInsightModel;
  }

  getFocusedInsightModelChange() {
    return this.focusedInsightModelChange.asObservable();
  }

  setFocusedInsightModel(modelName: string | null) {
    this.focusedInsightModel = modelName;
    this.focusedInsightModelChange.next(modelName);
  }
}
