import { Injectable } from '@angular/core';
import { Assistant, assistants } from '../../types/assistant';
import { COLOR_SCHEMES, DEFAULT_COLOR } from '../../types/color-scheme';
import { Message } from '../../types/message';
import { BehaviorSubject, firstValueFrom, catchError, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '~/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssistantService {
  private assistants = new BehaviorSubject<Assistant[]>([]);
  private readonly _API_URL = environment.apiUrl;

  assistants$ = this.assistants.asObservable();

  constructor(private _httpClient: HttpClient) {
    this.loadAssistants();
  }

  async loadAssistants() {
    // TODO: replace this with API call
    this.assistants.next(assistants);
  }

  getAssistantById(assistantId: string) {
    return this.assistants.value.find(({ id }) => id === assistantId);
  }

  getAssistantColors(assistantId: string) {
    const assistant = this.getAssistantById(assistantId);
    return COLOR_SCHEMES[assistant?.color || DEFAULT_COLOR];
  }

  async getAssistantHistory(insightType: string, businessId: string, limit: number): Promise<Message[]> {
    if (!insightType || !businessId) {
      return [
        {
          role: "bot",
          message: "Hello! I'm your personal business assistant.",
          timestamp: new Date()
        },
        {
          role: "bot",
          message: "Once you pick an area of your business to focus on we can get started.",
          timestamp: new Date()
        }
      ];
    }

    const url = `${this._API_URL}/api/v1/assistant/history?insightType=${insightType}&businessId=${businessId}&limit=${limit}`;
    try {
      const response: any[] = await firstValueFrom(
        this._httpClient.get<any[]>(url, { withCredentials: true })
          .pipe(
            catchError(error => {
              console.error('Error fetching assistant history:', error);
              return throwError(() => error);
            })
          )
      );

      return response.map((msg: any) => ({
        role: msg.role,
        message: msg.content.map((content: any) => content.text.value).join(' '),
        timestamp: new Date(msg.created_at * 1000) // Assuming created_at is in seconds
      }));
    } catch (error) {
      console.error('Error fetching assistant history:', error);
      return [];
    }
  }

  async getLastUsedAssistant() {
    // TODO: replace this with API call
    const randomNumber = Math.floor(Math.random() * this.assistants.value.length);
    return this.assistants.value[randomNumber];
  }
}
