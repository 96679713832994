<div class="min-h-screen bg-gray-50 flex flex-col items-center justify-center">
  <div class="w-full max-w-4xl px-6 py-8">
    <div class="flex items-center justify-between mb-6">
      <div class="relative inline-block">
        <select
          class="text-black py-2 px-4 rounded-lg font-medium w-20
          transform transition duration-150 hover:scale-105"
          (change)="setCurrency($event)"
        >
          @for (currency of CURRENCIES; track currency) {
            <option [value]="currency" [selected]="currency === currency">{{currency.toUpperCase()}}</option>
          }
        </select>
      </div>
      <button hlmBtn (click)="logout()">Logout</button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
      @for (price of prices; track price.id) {
        <div class="bg-white p-8 rounded-lg shadow-md text-center">
          @if (price.subscribed) {
            <span>
              Current Plan
            </span>
          }
          <div class="flex items-baseline justify-center">
            <span class="text-gray-600 mr-2">{{currency.toUpperCase()}}</span>
            <span class="text-4xl font-bold text-gray-900">${{price.prices[currency]}}</span>
            <span class="text-gray-600 ml-2">/{{price.interval}}</span>
          </div>
          @if (!price.subscribed) {
            @if (!subscribed) {
              <button
                class="bg-primary-600 text-white py-3 px-6 mt-2 rounded-lg font-medium
                hover:bg-primary-700 active:bg-primary-800
                transform transition duration-150 hover:scale-105
                focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                (click)="checkout(price.id)"
              >Purchase</button>
            } @else {
              <div class="text-center text-xs text-gray-500 mt-2">
                You're already subscribed to another plan.
              </div>
            }
          } @else if (price.subscribed.cancelAt) {
            <button
              class="bg-primary-600 text-white py-3 px-6 mt-2 rounded-lg font-medium
              hover:bg-primary-700 active:bg-primary-800
              transform transition duration-150 hover:scale-105
              focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              (click)="renew()"
            >Renew</button>
            <div class="text-center text-xs text-gray-500 mt-2">
              Your subscription will end on {{price.subscribed.cancelAt.toLocaleDateString()}}
            </div>
          } @else {
            <button
              class="bg-grey-600 text-white py-3 px-6 mt-2 rounded-lg font-medium
              hover:bg-grey-700 active:bg-grey-800
              transform transition duration-150 hover:scale-105
              focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
              (click)="cancel()"
            >Cancel</button>
            <div class="text-center text-xs text-gray-500 mt-2">
              Your subscription will renew on {{price.subscribed.renewAt?.toLocaleDateString()}}
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
