<router-outlet />

@if (!isEditingOrCreating()) {
<div class="p-4 md:px-6">
  <div class="flex flex-col gap-2">
    <!-- Loading State -->
    @if (isLoading()) {
    <div class="col-span-full text-center py-12 bg-grey-50 rounded-lg">
      <div
        class="animate-spin rounded-full h-12 w-12 border-b-2 border-grey-900 mx-auto"
      ></div>
      <h3 class="mt-2 text-sm font-medium text-grey-900">
        Loading Consultants...
      </h3>
    </div>
    }

    <!-- Error State -->
    @else if (error()) {
    <app-error-display title="Error loading consultants" [error]="error() || ''" (retry)="refresh()" />
    }

    <!-- Data State -->
    @else {
    <!-- Header with Actions -->
    <div class="flex flex-row justify-between items-center">
      <h1 class="text-2xl font-bold text-grey-900">Consultants</h1>
      <div class="flex gap-2">
        <button
          (click)="createNew()"
          class="px-4 py-2 text-sm font-medium text-secondary-default bg-primary-default rounded-md hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          New Consultant
        </button>

        <button
          (click)="refresh()"
          class="px-4 py-2 text-sm font-medium text-grey-700 bg-white border border-grey-300 rounded-md hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          [disabled]="isLoading()"
        >
          <lucide-angular
            [img]="icons.refresh"
            class="h-4"
            [class.animate-spin]="isLoading()"
          />
        </button>
      </div>
    </div>

    <!-- Consultant List -->
    @for (consultant of consultants(); track consultant._id) {
    <div class="bg-white rounded-lg shadow-md p-4">
      <div class="flex flex-row justify-between items-center gap-4">
        <div class="flex flex-col">
          <h2 class="text-lg font-semibold text-grey-900">
            {{ consultant.name }}
          </h2>
          <p class="text-sm text-grey-500">{{ consultant.description }}</p>
        </div>
        <div class="flex gap-2">
          <button
            class="text-grey-400 hover:text-primary-600 transition-colors"
            (click)="edit(consultant)"
          >
            <lucide-angular [img]="icons.edit" class="h-4" />
          </button>
          <button
            class="text-grey-400 hover:text-danger-600 transition-colors"
            (click)="delete(consultant._id)"
          >
            <lucide-angular [img]="icons.delete" class="h-4" />
          </button>
        </div>
      </div>
    </div>
    } @empty {
    <app-empty-state (create)="createNew()" message="No consultants found" />
    } }
  </div>
</div>
}
