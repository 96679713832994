import { Injectable, signal, computed, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';

interface BaseCacheState<T> {
  data: T[];
  lastFetched: number | null;
  loading: boolean;
  error: string | null;
}

// Generic type parameters for the different model variations
@Injectable()
export abstract class AdminBaseService<
  T = any,
  TInput extends Partial<T> = Partial<T>,
  TUpdate extends Partial<T> = Partial<T>
> {
  protected readonly API_URL = environment.apiUrl;
  protected readonly CACHE_LIFETIME = 5 * 60 * 1000; // 5 minutes

  protected _state = signal<BaseCacheState<T>>({
    data: [],
    lastFetched: null,
    loading: false,
    error: null,
  });

  readonly data = computed(() => this._state().data);
  readonly loading = computed(() => this._state().loading);
  readonly error = computed(() => this._state().error);
  readonly lastFetched = computed(() => this._state().lastFetched);

  constructor(protected httpClient: HttpClient) {}

  protected async fetchData(
    endpoint: string,
    forceRefresh = false
  ): Promise<T[]> {
    if (!forceRefresh && this.isCacheValid()) {
      return this._state().data;
    }

    this._updateState({ loading: true, error: null });

    try {
      const response = await firstValueFrom(
        this.httpClient.get<T[]>(`${this.API_URL}/api/admin/${endpoint}`, {
          withCredentials: true,
        })
      );

      if (!response) {
        throw new Error('No data received');
      }

      this._updateState({
        data: response,
        lastFetched: Date.now(),
        loading: false,
        error: null,
      });

      return response;
    } catch (error) {
      const errorMessage = this.handleError(error);
      this._updateState({
        loading: false,
        error: errorMessage,
      });
      throw error;
    }
  }

  protected async createItem(endpoint: string, item: TInput): Promise<T> {
    try {
      const result = await firstValueFrom(
        this.httpClient.post<T>(`${this.API_URL}/api/admin/${endpoint}`, item, {
          withCredentials: true,
        })
      );

      if (!result) {
        throw new Error('No data received from create operation');
      }

      this._updateState({
        data: [...this._state().data, result],
      });

      return result;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  protected async updateItem(
    endpoint: string,
    id: string,
    update: TUpdate
  ): Promise<T> {
    try {
      const result = await firstValueFrom(
        this.httpClient.put<T>(
          `${this.API_URL}/api/admin/${endpoint}/${id}`,
          update,
          { withCredentials: true }
        )
      );

      if (!result) {
        throw new Error('No data received from update operation');
      }

      this._updateState({
        data: this._state().data.map((existing) =>
          this.getId(existing) === id ? result : existing
        ),
      });

      return result;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  protected async deleteItem(endpoint: string, id: string): Promise<void> {
    try {
      await firstValueFrom(
        this.httpClient.delete(`${this.API_URL}/api/admin/${endpoint}/${id}`, {
          withCredentials: true,
        })
      );

      this._updateState({
        data: this._state().data.filter((item) => this.getId(item) !== id),
      });
    } catch (error) {
      throw this.handleError(error);
    }
  }

  protected isCacheValid(): boolean {
    const { data, lastFetched } = this._state();
    return (
      data.length > 0 &&
      lastFetched !== null &&
      Date.now() - lastFetched < this.CACHE_LIFETIME
    );
  }

  protected _updateState(partialState: Partial<BaseCacheState<T>>): void {
    this._state.update((state) => ({
      ...state,
      ...partialState,
    }));
  }

  protected handleError(error: unknown): string {
    if (error instanceof HttpErrorResponse) {
      return error.error?.message || error.message;
    }
    return error instanceof Error ? error.message : 'An unknown error occurred';
  }

  protected getId(item: T): string {
    return (item as any)._id;
  }
}
