import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Chat, defaultChat } from '../types/chat';
import { Message } from '../types/message';
import { AssistantService } from './assistant/assistant.user.service';
import { ClientEvents, ServerEvents, WebSocketService } from './web-socket.service';
import { Assistant } from '../types/assistant';
import { BaseSingletonService } from './base.service';
import { InsightFocusService } from './insight-focus.service';
import { InsightType } from '../types/insights';
import { Business } from './business/business.types';
import { BusinessService } from './business/business.user.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseSingletonService implements OnDestroy {
  // State observables
  private waitingForResponse = new BehaviorSubject<boolean>(false);
  waitingForResponse$ = this.waitingForResponse.asObservable();

  private activeChats = new BehaviorSubject<Chat[]>([]);
  activeChats$ = this.activeChats.asObservable();

  private currentChat = new BehaviorSubject<Chat>(defaultChat);
  currentChat$ = this.currentChat.asObservable();

  private waitingForRating = new BehaviorSubject<boolean>(false);
  waitingForRating$ = this.waitingForRating.asObservable();

  chatAvailable = new BehaviorSubject<boolean>(false);
  chatAvailable$ = this.chatAvailable.asObservable();

  private startOfThread = new BehaviorSubject<boolean>(false);
  startOfThread$ = this.startOfThread.asObservable();

  focusedBusiness: Business | null = null;
  private messageLimit = 20;

  constructor(
    private assistantService: AssistantService,
    private webSocketService: WebSocketService,
    private insightFocusService: InsightFocusService,
    private businessService: BusinessService
  ) {
    super();
    this.webSocketService.connect();
    this.initializeSocketEvents();
    this.subscribeToBusinessChanges();
    this.subscribeToInsightChanges();
  }

  // ----- Subscriptions -----

  /**
   * Subscribes to changes in the focused business.
   */
  private subscribeToBusinessChanges(): void {
    this.subscribe(this.businessService.focusedBusiness$, async (business) => {
      this.focusedBusiness = business;
      await this.refreshChat();
    });
  }

  /**
   * Subscribes to changes in the focused insight model.
   */
  private subscribeToInsightChanges(): void {
    this.subscribe(this.insightFocusService.focusedInsightModelChange, async (insightType: string | null) => {
      if (insightType) {
        try {
          const current = this.currentChat.value;
          if (current?.assistant) {
            current.assistant.insightType = insightType as InsightType;
          }
          // Emit using the non-null insightType and business id (if available)
          this.webSocketService.emit(ClientEvents.init, {
            insightType,
            businessId: this.focusedBusiness?._id
          });
          this.chatAvailable.next(true);
        } catch (error) {
          console.error('Error setting insight type:', error);
        }
      } else {
        // Reset chat if no insight is selected
        this.chatAvailable.next(false);
        this.currentChat.next(defaultChat);
        this.activeChats.next([]);
        this.waitingForResponse.next(false);
      }
      await this.refreshChat();
    });
  }

  /**
   * Initializes socket events for handling server responses.
   */
  private initializeSocketEvents(): void {
    // Update assistant name on init event
    this.subscribe(this.webSocketService.on([ServerEvents.init]), ({ assistantName }) => {
      const current = this.currentChat.value;
      if (current?.assistant) {
        current.assistant.name = assistantName;
      }
    });

    // Process bot responses
    this.subscribe(this.webSocketService.on([ServerEvents.response]), ({ message, choices }) => {
      this.addMessageToCurrentChat({
        role: 'bot',
        message,
        choices,
        timestamp: new Date()
      });
      this.waitingForResponse.next(false);
    });

    // Process final results and update insight service
    this.subscribe(this.webSocketService.on([ServerEvents.result]), ({ message: result }) => {
      const { insightType } = this.currentChat.value.assistant;
      this.insightFocusService.setUpdatedFocusedInsight({ insightType, result });
      console.log('Result received:', result);
      this.waitingForResponse.next(false);
      this.waitingForRating.next(true);
    });
  }

  // ----- Chat Initialization & Updates -----

  /**
   * Initializes a new chat with the provided assistant using the current insight and business.
   */
  private async initializeChat(assistant: Assistant): Promise<void> {
    const focusedInsight = this.insightFocusService.getFocusedInsightModel();
    if (!focusedInsight || !this.focusedBusiness) {
      console.error('Missing focused insight or business at init');
      return;
    }
    try {
      const chatHistory = await this.assistantService.getAssistantHistory(
        focusedInsight,
        this.focusedBusiness._id,
        this.messageLimit
      );
      const newChat: Chat = { assistant, messages: chatHistory };
      this.activeChats.next([...this.activeChats.value, newChat]);
      this.currentChat.next(newChat);
    } catch (error) {
      console.error('Error initializing chat:', error);
    }
  }

  /**
   * Adds a new chat with the provided assistant.
   */
  async addChat(assistant: Assistant): Promise<void> {
    await this.initializeChat(assistant);
  }

  /**
   * Refreshes the current chat or resets state if no business or insight is selected.
   */
  async refreshChat(): Promise<void> {
    if (this.focusedBusiness && this.currentChat.value.assistant?.insightType) {
      await this.initializeChat(this.currentChat.value.assistant);
    } else {
      this.resetChatState();
    }
  }

  /**
   * Resets the chat state to default.
   */
  private resetChatState(): void {
    this.currentChat.next(defaultChat);
    this.chatAvailable.next(false);
    this.activeChats.next([]);
  }

  /**
   * Loads initial messages for the provided assistant.
   */
  async loadInitialMessages(assistant: Assistant): Promise<void> {
    const focusedInsight = this.insightFocusService.getFocusedInsightModel();
    if (!focusedInsight || !this.focusedBusiness) {
      console.error('Missing focused insight or business at load');
      return;
    }
    try {
      const chatHistory = await this.assistantService.getAssistantHistory(
        focusedInsight,
        this.focusedBusiness._id,
        this.messageLimit
      );
      const newChat: Chat = { assistant, messages: chatHistory };
      this.activeChats.next([...this.activeChats.value, newChat]);
      this.currentChat.next(newChat);
    } catch (error) {
      console.error('Error loading initial messages:', error);
    }
  }

  /**
   * Loads more messages for the current chat.
   */
  async loadMoreMessages(): Promise<void> {
    const focusedInsight = this.insightFocusService.getFocusedInsightModel();
    if (!focusedInsight || !this.focusedBusiness) {
      console.error('Missing focused insight or business at load more');
      return;
    }
    try {
      const currentMessages = this.currentChat.value.messages;
      const additionalMessages = await this.assistantService.getAssistantHistory(
        focusedInsight,
        this.focusedBusiness._id,
        this.messageLimit
      );

      if (additionalMessages.length === 0) {
        this.startOfThread.next(true);
        return;
      }

      this.currentChat.next({
        ...this.currentChat.value,
        messages: [...additionalMessages, ...currentMessages]
      });
    } catch (error) {
      console.error('Error loading more messages:', error);
    }
  }

  /**
   * Removes the specified chat.
   */
  removeChat(chat: Chat): void {
    const updatedChats = this.activeChats.value.filter(activeChat => activeChat !== chat);
    this.activeChats.next(updatedChats);
    if (this.currentChat.value === chat && updatedChats.length > 0) {
      this.currentChat.next(updatedChats[0]);
    }
  }

  /**
   * Sets the waiting for rating state.
   */
  setWaitingForRating(waitingForRating: boolean): void {
    this.waitingForRating.next(waitingForRating);
  }

  /**
   * Sets the current chat.
   */
  setCurrentChat(chat: Chat): void {
    this.currentChat.next(chat);
  }

  /**
   * Sends a message to the assistant and sets a waiting flag.
   */
  sendMessageToAssistant(message: string): void {
    setTimeout(() => this.waitingForResponse.next(true), 500);
    this.webSocketService.emit(ClientEvents.send, { message });
  }

  /**
   * Adds a message to the current chat.
   */
  private addMessageToCurrentChat(message: Message): void {
    const updatedChats = this.activeChats.value.map(chat => {
      if (chat === this.currentChat.value) {
        return { ...chat, messages: [...chat.messages, message] };
      }
      return chat;
    });
    this.activeChats.next(updatedChats);
  }

  /**
   * Adds a message to the specified chat.
   */
  addMessage(chat: Chat, message: Message): void {
    const updatedChats = this.activeChats.value.map(activeChat => {
      if (activeChat === chat) {
        return { ...activeChat, messages: [...activeChat.messages, message] };
      }
      return activeChat;
    });
    this.activeChats.next(updatedChats);
  }

  /**
   * Edits a message in the chat.
   */
  editMessage(event: { original: string; edited: string }): void {
    this.webSocketService.emit(ClientEvents.edit, event);
  }

  /**
   * Loads the last used chat.
   */
  async loadLastUsedChat(): Promise<void> {
    if (this.activeChats.value.length > 0) return;
    try {
      const assistantId = await this.assistantService.getLastUsedAssistant();
      await this.addChat(assistantId);
    } catch (error) {
      console.error('Error loading last used chat:', error);
    }
  }
}
