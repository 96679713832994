import { Component, input, output } from '@angular/core';
import { LucideAngularModule, EditIcon, Trash2Icon } from 'lucide-angular';
import { CommonModule } from '@angular/common';
import { Business } from '../../../../services/business/business.types';

@Component({
  selector: 'app-admin-business-card',
  standalone: true,
  imports: [LucideAngularModule, CommonModule],
  templateUrl: './business-card.component.html'
})
export class AdminBusinessCardComponent {
  // Inputs
  business = input.required<Business>();

  // Outputs
  edit = output<Business>();
  delete = output<string>();

  // Icons
  readonly icons = {
    edit: EditIcon,
    delete: Trash2Icon
  };

  // Helper methods for template
  formatDate(date: string | undefined): string {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString();
  }
}
