import { Component, OnInit, AfterViewInit, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Check, LucideAngularModule, SendHorizontal } from 'lucide-angular';
import { HeaderComponent } from '../header/header.component';
import { MessageComponent } from './message/message.component';
import { BaseComponent } from '../base.component';
import { Chat } from '~/app/types/chat';
import { Assistant } from '~/app/types/assistant';
import { Message } from '~/app/types/message';
import { ChatService } from '~/app/services/chat.service';
import { AssistantService } from '~/app/services/assistant/assistant.user.service';
import { ChatHeaderComponent } from './header/header.component';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { StarRatingComponent } from '../star-rating/star-rating.component';

@Component({
  selector: 'app-chat',
  standalone: true,
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LucideAngularModule,
    HeaderComponent,
    MessageComponent,
    ChatHeaderComponent,
    HlmButtonDirective,
    StarRatingComponent
  ]
})
export class ChatComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
  // lucide icons
  readonly SendIcon = SendHorizontal;
  readonly checkIcon = Check;

  activeChats: Chat[] = [];
  currentChat!: Chat;
  chatAvailable: boolean = false;
  messageInput = new FormControl({ value: '', disabled: !this.chatAvailable || !this.currentChat.assistant.id });
  assistants: Assistant[] = [];
  waitingForResponse: boolean = false;
  waitingForRating = true;

  ratingSubmitted = false;

  loadingMessage: Message = {
    role: 'bot',
    message: '...',
    timestamp: new Date()
  };

  showChoices: boolean = false;
  selectedChoices: string[] = [];
  currentAssistant!: Assistant;
  messages: Message[] = []; // Added property to store messages
  startOfThread: boolean = false;

  @Output() choicesSubmitted = new EventEmitter<string[]>();

  constructor(
    private chatService: ChatService,
    private assistantService: AssistantService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToObservables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chatAvailable'] || changes['currentChat']) {
      this.toggleMessageInput();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.setupScrollListener();
  }

  subscribeToObservables(): void {
    this.subscribe(this.chatService.activeChats$, chats => this.activeChats = chats);
    this.subscribe(this.chatService.currentChat$, chat => this.updateCurrentChat(chat));
    this.subscribe(this.chatService.waitingForResponse$, waitingForResponse => this.waitingForResponse = waitingForResponse);
    this.subscribe(this.chatService.waitingForRating$, waitingForRating => this.waitingForRating = waitingForRating);
    this.subscribe(this.assistantService.assistants$, assistants => this.assistants = assistants);
    this.subscribe(this.chatService.chatAvailable$, chatAvailable => this.chatAvailable = chatAvailable);
    this.subscribe(this.chatService.startOfThread$, startOfThread => this.startOfThread = startOfThread);
  }

  updateCurrentChat(chat: Chat): void {
    this.currentChat = chat;
    this.messages = chat.messages || [];
    this.showChoices = false;
    this.toggleMessageInput();
    this.scrollToBottom();
  }

  toggleMessageInput(): void {
    if (!this.chatAvailable || !this.currentChat.assistant.id) {
      this.messageInput.disable();
    } else {
      this.messageInput.enable();
    }
  }

  setupScrollListener(): void {
    const chatContainer = document.querySelector('.chat-container');
    if (chatContainer) {
      chatContainer.addEventListener('scroll', () => {
        if (chatContainer.scrollTop === 0 && !this.startOfThread) {
          this.chatService.loadMoreMessages();
        }
      });
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      const chatContainer = document.querySelector('.chat-container');
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }, 0);
  }

  toggleChoice(choice: string): void {
    const index = this.selectedChoices.indexOf(choice);
    if (index === -1) {
      this.selectedChoices.push(choice);
    } else {
      this.selectedChoices.splice(index, 1);
    }
  }

  getAssistantColors(assistantId: string): any {
    return this.assistantService.getAssistantColors(assistantId);
  }

  getCurrentChatData(): Chat | undefined {
    return this.activeChats.find(chat => chat === this.currentChat);
  }

  getLatestMessage(): Message | undefined {
    const chat = this.getCurrentChatData();
    if (!chat || chat.messages.length === 0) return;
    const latestMessage = chat.messages[chat.messages.length - 1];
    this.showChoices = !!latestMessage.choices?.length;
    return latestMessage;
  }

  addChat(assistant: Assistant): void {
    this.chatService.addChat(assistant);
  }

  removeChat(chat: Chat): void {
    this.chatService.removeChat(chat);
  }

  setCurrentChat(chat: Chat): void {
    this.chatService.setCurrentChat(chat);
  }

  handleChoicesSubmitted(): void {
    this.choicesSubmitted.emit(this.selectedChoices);
  }

  async sendMessage() {
    const userInput = this.messageInput.value?.trim();
    if (!userInput || !this.chatAvailable) return;

    const userMessage: Message = {
      role: 'user',
      message: userInput,
      timestamp: new Date()
    };

    if (!this.currentChat) return;
    this.chatService.addMessage(this.currentChat, userMessage);
    this.messageInput.reset();
    this.resetTextareaHeight();

    this.chatService.sendMessageToAssistant(userInput);
  }

  resetTextareaHeight(): void {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.style.height = 'inherit';
    }
  }

  toggleShowChoices(): void {
    this.showChoices = !this.showChoices;
    if (!this.showChoices) {
      this.selectedChoices = [];
    }
  }

  autoGrow(event: any): void {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;
  }

  handleEnterKey(event: any): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    } else if (event.key === 'Enter' && event.metaKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }
}

