<div class="flex flex-col h-full max-w-5xl mx-auto bg-gray-50 border-l">
  <div class="flex-1 overflow-y-auto p-4 space-y-4 flex-grow relative">
    @for (message of getCurrentChatData()?.messages; track message.timestamp) {
      <app-chat-message
        [message]="message"
        [latestMessage]="message === getLatestMessage()"
        [currentAssistant]="currentChat.assistant"
        (choicesSubmitted)="handleChoicesSubmitted()"
      ></app-chat-message>
    }

    @if (waitingForRating) {
      <app-star-rating></app-star-rating>
    }



    @if(waitingForResponse) {
      <app-chat-message
        [message]="loadingMessage"
        [currentAssistant]="currentChat.assistant"
      ></app-chat-message>
    }
  </div>

  <!-- Choices and Submit Button -->
  <div *ngIf="showChoices" class="p-4 bg-white border-t sticky bottom-0 flex flex-col gap-2">
    <div class="flex flex-wrap gap-2">
      @for (choice of getLatestMessage()?.choices; track choice) {
        <button
          [class]="'px-4 py-2 rounded-2xl text-sm ' +
          (selectedChoices.includes(choice)
          ? getAssistantColors(currentAssistant.id).bg + ' text-white'
          : 'bg-grey-100 hover:bg-grey-200')"
          (click)="toggleChoice(choice)"
        >
          {{ choice }}
        </button>
      }
    </div>
    <div class="flex justify-end">
      <button
        [class]="'px-4 py-2 rounded-2xl text-sm ' + (selectedChoices.length > 0 ? getAssistantColors(currentAssistant.id).bg + ' text-white' : 'bg-grey-100 text-grey-600')"
        [disabled]="selectedChoices.length === 0"
        (click)="handleChoicesSubmitted()"
      >
        <lucide-icon [img]="checkIcon" [size]="20"></lucide-icon>
      </button>
    </div>
  </div>

  <!-- Toggle Choices Button -->
  <button
    hlmBtn
    variant='secondary'
    (click)="toggleShowChoices()"
  >
    {{ showChoices ? 'Hide Choices' : 'Show Choices' }}
  </button>

  <!-- Toggle Choices Button -->
  <div class="p-4 bg-white border-t sticky bottom-0 flex full-width justify-between items-center">
    <form class="focus:ring-2 focus:ring-purple-500 flex items-end gap-2 grow rounded border p-1">
      <textarea
        [formControl]="messageInput"
        placeholder="Type your message..."
        class="flex-1 px-4 py-2 border-gray-200 focus:outline-none resize-none border-none"
        rows="1"
        (input)="autoGrow($event)"
        (keydown.enter)="handleEnterKey($event)"
        [disabled]="!chatAvailable || !currentChat.assistant.id"
        [value]="!chatAvailable ? '' : messageInput.value"
      ></textarea>
      <button
        type="button"
        [class]="
          'p-2.5 rounded-full text-white transition-colors ' +
          getAssistantColors(currentChat.assistant.id).bg +
          ' ' +
          getAssistantColors(currentChat.assistant.id).hover
        "
        (click)="sendMessage()"
        style="flex-shrink: 0;"
        [disabled]="!chatAvailable || !currentChat.assistant.id"
      >
        <lucide-icon [img]="SendIcon" [size]="20"></lucide-icon>
      </button>
    </form>
  </div>
</div>
