    <div (click)="create.emit()" class="col-span-full cursor-pointer text-center py-12 bg-grey-50 rounded-lg hover:bg-grey-100">
      <svg
        class="mx-auto h-12 w-12 text-grey-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-grey-900">{{ message() }}</h3>
    </div>
