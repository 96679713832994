import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { provideIcons } from '@ng-icons/core';
import {
  lucideBarChart,
  lucideClipboardList,
  lucideDollarSign,
  lucideMegaphone,
  lucidePieChart,
  lucideSparkles,
  lucideUserCheck
} from '@ng-icons/lucide';
import { InsightFocusService } from '~/app/services/insight-focus.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, Subscription } from 'rxjs';

interface MenuItem {
  name: string;
  icon: string;
  id: string;
  isActive?: boolean;
}

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, HlmButtonDirective, HlmIconComponent],
  providers: [provideIcons({ lucideSparkles, lucideUserCheck, lucideDollarSign, lucideBarChart, lucidePieChart, lucideClipboardList, lucideMegaphone })],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  host: {
    'class': 'flex flex-col flex-1 items-center self-center p-2 w-full bg-white max-w-[56px]'
  }
})
export class MenuComponent implements OnInit {
  @Input() menuItems: MenuItem[] = [];
  activeMenuItemId: string | null = null;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private _insightFocusService: InsightFocusService,
    private router: Router
  ) {
    // Clear active menu item on navigation
    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.activeMenuItemId = null;
        })
    );
  }

  ngOnInit() {
    this.initActiveMenuItem();
  }

  ngOnDestroy() {
    // Clean up subscriptions when component is destroyed
    this.subscriptions.unsubscribe();
  }

  initActiveMenuItem() {
    const activeItem = this.menuItems.find(item => item.name === this._insightFocusService.getFocusedInsightModel());
    if (activeItem) {
      this.activeMenuItemId = activeItem.id;
      this.setFocusedInsightModel(activeItem);
    }
  }

  setFocusedInsightModel(menuItem: MenuItem) {
    menuItem.isActive = !menuItem.isActive;

    this.menuItems.forEach(item => {
      if (item.name !== menuItem.name) {
        item.isActive = false;
      }
    });

    this._insightFocusService.setFocusedInsightModel(menuItem.isActive ? menuItem.name : null);
  }

  isMenuItemActive(menuItemId: string): boolean {
    return this.activeMenuItemId === menuItemId;
  }
}
