<hlm-menu class="floating-menu w-56" (click)="$event.stopPropagation()">
  <hlm-menu-label>Page</hlm-menu-label>

  <hlm-menu-group>
    <button hlmMenuItemRadio [checked]="selectedPosition === 'dashboard'" 
            (click)="navigateTo('dashboard')">
      <hlm-menu-item-radio />
      <span>Dashboard</span>
    </button>

    <button hlmMenuItemRadio [checked]="selectedPosition === 'onboarding'" 
            (click)="navigateTo('onboarding')">
      <hlm-menu-item-radio />
      <span>Onboarding</span>
    </button>
  </hlm-menu-group>

</hlm-menu>
