import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminBaseService } from '../admin/admin-base.service';

type MongooseDataType = 'String' | 'Number' | 'Boolean' | 'ObjectID' | 'Array' | 'Mixed' | 'Map' | 'Date' | 'Buffer' | 'Decimal128' | 'Object';

interface SchemaFieldInfo {
  field: string;
  type: MongooseDataType;
  isRequired: boolean;
  defaultValue?: any;
  isArray: boolean;
  arrayElementType?: MongooseDataType;
}

export interface InsightModel {
  name: string;
  schemaFields: SchemaFieldInfo[];
}

@Injectable({
  providedIn: 'root'
})
export class InsightModelService extends AdminBaseService<InsightModel> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async getInsightModels(forceRefresh = false): Promise<InsightModel[]> {
    return this.fetchData('insights', forceRefresh);
  }

  // Override getId since InsightModel doesn't have _id
  protected override getId(item: InsightModel): string {
    return item.name;
  }
}
