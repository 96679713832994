import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsightFocusService } from '~/app/services/insight-focus.service';

@Component({
  selector: 'app-insight-property-card',
  templateUrl: './insight-property-card.component.html',
  styleUrls: ['./insight-property-card.component.scss'],
  standalone: true,
  imports: [CommonModule],
  host: {
    '[class.focused]': 'isFocused()'
  }
})
export class InsightPropertyCardComponent implements OnChanges, OnDestroy {
  @Input() title: string = '';
  @Input() description?: string = '';
  @Input() cardId: string = '';
  @Input() value?: any;

  displayValue: string = '';
  hasNestedValues: boolean = false;
  nestedValues: Array<{key: string, value: any}> = [];
  isArray: boolean = false;

  constructor(private insightFocusService: InsightFocusService) {}

  ngOnChanges() {
    this.processValue();
  }

  private processValue() {
    if (this.value === null || this.value === undefined) {
      this.displayValue = this.title;
      this.hasNestedValues = false;
      this.isArray = false;
      return;
    }

    if (Array.isArray(this.value)) {
      this.displayValue = `${this.title} (${this.value.length} items)`;
      this.isArray = true;
      this.hasNestedValues = false;
      return;
    }

    if (typeof this.value === 'object') {
      this.displayValue = this.title;
      this.hasNestedValues = true;
      this.isArray = false;
      this.nestedValues = Object.entries(this.value).map(([key, value]) => ({
        key,
        value
      }));
      return;
    }

    // For primitive values
    this.displayValue = this.value.toString();
    this.hasNestedValues = false;
    this.isArray = false;
  }

  toggleFocus() {
    this.insightFocusService.setFocusedCardId(
      this.isFocused() ? null : this.cardId
    );
  }

  isFocused(): boolean {
    return this.insightFocusService.getFocusedCardId() === this.cardId;
  }

  ngOnDestroy() {
    if (this.isFocused()) {
      this.insightFocusService.setFocusedCardId(null);
    }
  }
}
