<div
  [class]="
    'flex items-start gap-3 ' +
    (message.role === 'user' ? 'flex-row-reverse' : '')
  "
>
  <!-- Avatar -->
  <div
    [class]="
      'flex-shrink-0 rounded-full p-2 ' +
      (message.role === 'user'
        ? getAssistantColors(currentAssistant.id).light
        : 'bg-grey-100')
    "
  >
    <lucide-icon
      [img]="message.role === 'user' ? UserIcon : BotIcon"
      [size]="20"
      [class]="
        message.role === 'user'
          ? getAssistantColors(currentAssistant.id).text
          : 'text-grey-600'
      "
    ></lucide-icon>
  </div>

  <div
    [class]="
      'px-4 py-2 rounded-2xl max-w-[80%] mb-1 relative ' +
      (message.role === 'user'
        ? getAssistantColors(currentAssistant.id).bg + ' text-white'
        : 'bg-white border border-grey-200')
    "
  >

    @if (!isEditing) {
      <!-- Edit Icon for Assistant Messages -->
      @if (message.role !== 'user' && role !== 'user' && message.message != '...') {
        <button
          class="absolute bottom-2 right-2 text-grey-400 hover:text-grey-600 transition-colors"
          (click)="isEditing = true"
        >
          <lucide-icon
            [img]="EditIcon"
            [size]="16"
          ></lucide-icon>
        </button>
      }

      <!-- Message Text -->
      <p class="text-sm whitespace-pre-line">{{ message.message }}</p>
    } @else {
      <textarea
        #editTextArea
        class="w-full p-2 mb-2 border rounded text-sm"
        [value]="message.message"
        rows="4"
      ></textarea>

      <div class="flex justify-end space-x-2 absolute bottom-2 right-2">
        <button
          class="text-danger-500 px-1 py-1 hover:bg-danger-100 rounded-full"
          (click)="isEditing = false"
        >
          <lucide-icon
            [img]="CancelIcon"
            [size]="16"
          ></lucide-icon>
        </button>

        <button
          class="text-primary-500 px-1 py-1 hover:bg-primary-100 rounded-full"
          (click)="editMessage(editTextArea.value)"
        >
          <lucide-icon
            [img]="CheckIcon"
            [size]="16"
          ></lucide-icon>
        </button>
      </div>
    }

    <!-- Timestamp -->
    <time class="text-xs text-grey-400 mt-1" *ngIf="isValidDate(message.timestamp)">
      {{ message.timestamp | date : "shortTime" }}
    </time>
  </div>
</div>
