import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LucideAngularModule } from 'lucide-angular';
import { HeaderComponent } from './components/header/header.component';
import { BuilderContext } from '@builder.io/sdk-angular';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    LucideAngularModule, 
    HeaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'plan-app';

  constructor(private builderContext: BuilderContext) {
    this.builderContext.apiKey = environment.builderApiKey;
  }
}
