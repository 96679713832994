<div class="p-6 max-w-2xl mx-auto">
  @if (showToast) {
  <div
    class="px-4 py-2 rounded my-2"
    [ngClass]="
      toastConfig.type === 'success'
        ? 'bg-success-50 text-success-700 border border-success-500'
        : 'bg-danger-50 text-danger-700 border border-danger-500'
    "
  >
    {{ toastConfig.message }}
  </div>
  }

  <div class="mb-4">
    <button
      [routerLink]="['../']"
      class="text-xs text-primary-default hover:underline"
    >
      Back
    </button>
  </div>

  <form [formGroup]="assistantForm" (ngSubmit)="onSubmit()" class="space-y-8">
    <!-- Name Field -->
    <div class="space-y-2">
      <label
        for="name"
        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Name
      </label>
      <input
        id="name"
        formControlName="name"
        placeholder="Enter assistant name"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      />
    </div>

    <!-- Description Field -->
    <div class="space-y-2">
      <label
        for="description"
        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Description
      </label>
      <textarea
        id="description"
        formControlName="description"
        placeholder="Enter description"
        rows="3"
        class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      ></textarea>
    </div>

    <!-- Consultant Selection -->
    <div class="space-y-2">
      <label
        for="consultantId"
        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Consultant
      </label>
      <select
        id="consultantId"
        formControlName="consultantId"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <option value="">Select a consultant</option>
        @for (consultant of consultants(); track consultant._id) {
        <option [value]="consultant._id">{{ consultant.name }}</option>
        }
      </select>
    </div>

    <!-- GPT Model Selection -->
    <div class="space-y-2">
      <label
        for="gptModel"
        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        GPT Model
      </label>
      <select
        id="gptModel"
        formControlName="gptModel"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <option value="">Select a model</option>
        @for (gpt of models(); track gpt) {
        <option [value]="gpt">{{ gpt }}</option>
        }
      </select>
    </div>

    <!-- Question Set Selection -->
    <div class="space-y-2">
      <label
        for="questionSetId"
        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Question Set
      </label>
      <select
        id="questionSetId"
        formControlName="questionSetId"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <option value="">Select a question set</option>
        @for (questionSet of questionSets(); track questionSet._id) {
        <option [value]="questionSet._id">{{ questionSet.name }}</option>
        }
      </select>
    </div>

    <!-- Target Insight Model Field -->
    <div class="space-y-2">
      <label
        for="targetInsightModelName"
        class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Target Insight Model
      </label>
      <select
        id="targetInsightModelName"
        formControlName="targetInsightModelName"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        @for (model of targetInsightModels; track model) {
          <option [value]="model">{{ model | camelCaseToSpaces }}</option>
        }
      </select>
    </div>

    @if (isEditing) {
    <div class="space-y-2">
      <h1 class="font-bold">OpenAI Settings</h1>
      <div class="space-y-2">
        <label
          for="instructions"
          class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Instructions
        </label>
        <textarea
          id="instructions"
          formControlName="instructions"
          placeholder="Instructions for OpenAI Assistant"
          rows="3"
          class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        ></textarea>
      </div>
    </div>
    }

    <!-- Submit and Action Buttons -->
    <div class="flex justify-between items-center">
      @if (isEditing) {
      <button
        type="button"
        (click)="toggleActivation()"
        [class.bg-success-500]="!assistantForm.get('isActive')?.value"
        [class.bg-warning-500]="assistantForm.get('isActive')?.value"
        class="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-white h-10 px-4 py-2"
      >
        {{ assistantForm.get("isActive")?.value ? "Deactivate" : "Activate" }}
      </button>
      } @else {
      <div></div>
      }

      <button
        type="submit"
        [disabled]="!assistantForm.valid || assistantForm.pristine"
        class="inline-flex bg-primary-default text-secondary-default items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
      >
        {{ isEditing ? "Update Assistant" : "Create Assistant" }}
      </button>
    </div>
  </form>
</div>
