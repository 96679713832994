import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BusinessService } from '../../../../services/business/business.admin.service';
import { Business, BusinessInput, BusinessUpdate } from '../../../../services/business/business.types';

interface BusinessForm {
  _id: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string | null>;
  founded: FormControl<string | null>;
  website: FormControl<string | null>;
  headquarters: FormControl<string | null>;
  industry: FormControl<string | null>;
  size: FormControl<string | null>;
  revenue: FormControl<string | null>;
  employeeCount: FormControl<number | null>;
  products: FormControl<string[] | null>;
  services: FormControl<string[] | null>;
  competitors: FormControl<string[] | null>;
  targetMarket: FormControl<string | null>;
  missionStatement: FormControl<string | null>;
  visionStatement: FormControl<string | null>;
  values: FormControl<string[] | null>;
  keyStrengths: FormControl<string[] | null>;
  keyWeaknesses: FormControl<string[] | null>;
  opportunities: FormControl<string[] | null>;
  threats: FormControl<string[] | null>;
  adminBusiness: FormControl<boolean | null>;
}

@Component({
  selector: 'app-business-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule
  ],
  templateUrl: './admin-business-form.component.html'
})
export class BusinessFormComponent implements OnInit, OnDestroy {
  private _businessService = inject(BusinessService);
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);

  isEditing = false;
  currentBusiness = this._businessService.selectedBusiness$;

  businessForm = new FormGroup<BusinessForm>({
    _id: new FormControl('', { nonNullable: true }),
    name: new FormControl('', { nonNullable: true }),
    description: new FormControl(null),
    founded: new FormControl(null),
    website: new FormControl(null),
    headquarters: new FormControl(null),
    industry: new FormControl(null),
    size: new FormControl(null),
    revenue: new FormControl(null),
    employeeCount: new FormControl(null),
    products: new FormControl(null),
    services: new FormControl(null),
    competitors: new FormControl(null),
    targetMarket: new FormControl(null),
    missionStatement: new FormControl(null),
    visionStatement: new FormControl(null),
    values: new FormControl(null),
    keyStrengths: new FormControl(null),
    keyWeaknesses: new FormControl(null),
    opportunities: new FormControl(null),
    threats: new FormControl(null),
    adminBusiness: new FormControl(false)
  });

  async ngOnInit() {
    if (!this.currentBusiness()) {
      const id = this._route.snapshot.paramMap.get('id');
      if (id) {
        const loadedBusiness = await this._businessService.getBusinessById(id);
        if (loadedBusiness) {
          this._businessService.setSelectedBusiness(loadedBusiness);
          this.setBusinessForm(loadedBusiness);
        }
      }
    } else {
      this.setBusinessForm(this.currentBusiness() as Business);
    }
  }

  ngOnDestroy() {
    this._businessService.setSelectedBusiness(null);
  }

  setBusinessForm(business: Business) {
    this.businessForm.patchValue({
      _id: business._id,
      name: business.name,
      description: business.description ?? null,
      founded: business.founded ?? null,
      website: business.website ?? null,
      headquarters: business.headquarters ?? null,
      industry: business.industry ?? null,
      size: business.size ?? null,
      revenue: business.revenue ?? null,
      employeeCount: business.employeeCount ?? null,
      products: business.products ?? null,
      services: business.services ?? null,
      competitors: business.competitors ?? null,
      targetMarket: business.targetMarket ?? null,
      missionStatement: business.missionStatement ?? null,
      visionStatement: business.visionStatement ?? null,
      values: business.values ?? null,
      keyStrengths: business.keyStrengths ?? null,
      keyWeaknesses: business.keyWeaknesses ?? null,
      opportunities: business.opportunities ?? null,
      threats: business.threats ?? null
    });

    this.isEditing = true;
  }

  async onSubmit() {
    if (!this.businessForm.valid) return;

    const formData = this.businessForm.getRawValue();

    if (!this.isEditing) {
      // Create new business
      const newBusiness: BusinessInput = {
        name: formData.name,
        ...(formData.description && { description: formData.description }),
        ...(formData.founded && { founded: formData.founded }),
        ...(formData.website && { website: formData.website }),
        ...(formData.headquarters && { headquarters: formData.headquarters }),
        ...(formData.industry && { industry: formData.industry }),
        ...(formData.size && { size: formData.size }),
        ...(formData.revenue && { revenue: formData.revenue }),
        ...(formData.employeeCount && { employeeCount: formData.employeeCount }),
        ...(formData.products && { products: formData.products }),
        ...(formData.services && { services: formData.services }),
        ...(formData.competitors && { competitors: formData.competitors }),
        ...(formData.targetMarket && { targetMarket: formData.targetMarket }),
        ...(formData.missionStatement && { missionStatement: formData.missionStatement }),
        ...(formData.visionStatement && { visionStatement: formData.visionStatement }),
        ...(formData.values && { values: formData.values }),
        ...(formData.keyStrengths && { keyStrengths: formData.keyStrengths }),
        ...(formData.keyWeaknesses && { keyWeaknesses: formData.keyWeaknesses }),
        ...(formData.opportunities && { opportunities: formData.opportunities }),
        ...(formData.threats && { threats: formData.threats })
      };

      const newBusinessResponse = await this._businessService.createBusiness(newBusiness);
      if (!newBusinessResponse) {
        this.notifyUser('Failed to create business');
        return;
      }

      this.notifyUser('Business created successfully', 'success');
      setTimeout(async () => {
        await this._router.navigate(['../', newBusinessResponse._id], { relativeTo: this._route });
      }, 4000);

      this._businessService.setSelectedBusiness(newBusinessResponse);
    } else {
      // Edit existing business
      const updateData: BusinessUpdate = {
        name: formData.name,
        ...(formData.description && { description: formData.description }),
        ...(formData.founded && { founded: formData.founded }),
        ...(formData.website && { website: formData.website }),
        ...(formData.headquarters && { headquarters: formData.headquarters }),
        ...(formData.industry && { industry: formData.industry }),
        ...(formData.size && { size: formData.size }),
        ...(formData.revenue && { revenue: formData.revenue }),
        ...(formData.employeeCount && { employeeCount: formData.employeeCount }),
        ...(formData.products && { products: formData.products }),
        ...(formData.services && { services: formData.services }),
        ...(formData.competitors && { competitors: formData.competitors }),
        ...(formData.targetMarket && { targetMarket: formData.targetMarket }),
        ...(formData.missionStatement && { missionStatement: formData.missionStatement }),
        ...(formData.visionStatement && { visionStatement: formData.visionStatement }),
        ...(formData.values && { values: formData.values }),
        ...(formData.keyStrengths && { keyStrengths: formData.keyStrengths }),
        ...(formData.keyWeaknesses && { keyWeaknesses: formData.keyWeaknesses }),
        ...(formData.opportunities && { opportunities: formData.opportunities }),
        ...(formData.threats && { threats: formData.threats })
      };

      const editResponse = await this._businessService.editBusiness(formData._id, updateData);
      if (!editResponse) {
        this.notifyUser('Failed to edit business');
        return;
      }

      this._businessService.setSelectedBusiness(editResponse);
      this.notifyUser('Business saved successfully', 'success');
    }
  }

  showToast = false;
  toastConfig = {
    message: '',
    type: 'error' as 'error' | 'success'
  }

  notifyUser(message: string, type: 'error' | 'success' = 'error') {
    this.showToast = true;
    this.toastConfig = { message, type };
    setTimeout(() => {
      this.showToast = false;
      this.toastConfig = { message: '', type: 'error' };
    }, 3000);
  }
}
