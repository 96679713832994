import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsightPropertyCardComponent } from '../insight-property-card/insight-property-card.component';
import { InsightFocusService } from '~/app/services/insight-focus.service';
import { InsightsService } from '~/app/services/insights/insights.user.service';
import { CamelCaseToSpacesPipe } from '~/app/pipes/camel-case-to-spaces.pipe';
import { RelativeTimePipe } from '~/app/pipes/relative-time.pipe';

interface InsightProperty {
  title: string;
  description: string;
  value?: string | number;
}

@Component({
  selector: 'app-insight-list',
  templateUrl: './insight-list.component.html',
  styleUrls: ['./insight-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    InsightPropertyCardComponent, 
    CamelCaseToSpacesPipe,
    RelativeTimePipe,
  ]
})
export class InsightListComponent {
  insightProperties: InsightProperty[] = [];
  name!: string;
  description!: string;
  createdAt!: Date;
  updatedAt!: Date;
  isActive!: boolean;
  loading = false;
  error: string | null = null;
  currentModelName: string | null = null;
  insightData: any[] = [];

  constructor(
    private _insightFocusService: InsightFocusService,
    private _insightsService: InsightsService
  ) { }

  ngOnInit(): void {
    this._insightFocusService.focusedInsightModelChange.subscribe(async (modelName: string | null) => {
      console.log('insight-list.component.ts: Insight focus changed:', modelName); // Add logging
      await this._handleModelFocusChange(modelName);
    });

    // Emit the current focused insight model on initialization
    this._insightFocusService.setFocusedInsightModel(this._insightFocusService.getFocusedInsightModel());

    this._insightFocusService.focusedInsightUpdated$.subscribe(async (insight: any) => {
      console.log('insight-list.component.ts: Insight updated:', insight); // Add logging
      await this._handleModelUpdated(insight);
    });
  }

  private async _handleModelUpdated(model: any): Promise<void> {
    const modelName = model?.insightType;
    this.currentModelName = modelName;
    this.loading = true;
    this.error = null;

    if (!modelName) {
      this.resetData();
      this.loading = false;
      return;
    }

    try {
      await this._fetchAndProcessInsightData(modelName);
    } catch (error) {
      console.error('Error fetching insight data:', error);
      this.error = 'Failed to load insight data';
      this.resetData();
    } finally {
      this.loading = false;
    }
  }

  private async _handleModelFocusChange(modelName: string | null): Promise<void> {
    this.currentModelName = modelName;
    this.loading = true;
    this.error = null;

    if (modelName) {
      console.log('Fetching insight data for model:', modelName);
      try {
        this.insightData = await this._insightsService.getInsightsByModelName(modelName);

        if (!this.insightData || this.insightData.length === 0) {
          this.resetData();
          this.error = 'No insight data available';
          return;
        }

        if (!this.insightData[0]) {
          this.resetData();
          this.error = 'Invalid insight data structure';
          return;
        }

        const totalInsights = this.insightData.length;
        const latestInsight = this.insightData[totalInsights - 1];

        this.name        = latestInsight.name || latestInsight.__t;
        this.description = latestInsight.description;
        this.createdAt  = latestInsight.createdAt;
        this.updatedAt  = latestInsight.updatedAt;
        this.isActive    = latestInsight.isActive;

        const processedData = { ...latestInsight };

        // Remove unnecessary properties
        const propsToRemove = [
          '_id', '__t', 'name', 'description', 'createdAt', 'updatedAt', 'id',
          '__v', 'businessId', 'modelName', 'isActive',
        ];
        propsToRemove.forEach(prop => delete processedData[prop]);

        this.insightProperties = this.processInsightData(processedData);

      } catch (error) {
        console.error('Error fetching insight data:', error);
        this.error = 'Failed to load insight data';
        this.resetData();
      } finally {
        this.loading = false;
      }

    } else {
      this.resetData();
      this.loading = false;
    }
  }

  private async _fetchAndProcessInsightData(modelName: string): Promise<void> {
    this.insightData = await this._insightsService.getInsightsByModelName(modelName);

    if (!this.validateInsightData()) {
      return;
    }

    this._setLatestInsightProperties();
    this._processInsightProperties();
  }

  private _setLatestInsightProperties(): void {
    const latestInsight = this.insightData[this.insightData.length - 1];
    this.name = latestInsight.name || latestInsight.__t;
    this.description = latestInsight.description;
    this.createdAt = latestInsight.createdAt;
    this.isActive = latestInsight.isActive;
  }

  private _processInsightProperties(): void {
    const processedData = { ...this.insightData[this.insightData.length - 1] };
    const propsToRemove = [
      '_id', '__t', 'name', 'description', 'createDate', 'id',
      '__v', 'businessId', 'modelName', 'isActive'
    ];
    propsToRemove.forEach(prop => delete processedData[prop]);
    this.insightProperties = this.processInsightData(processedData);
  }

  private validateInsightData(): boolean {
    if (!this.insightData || this.insightData.length === 0) {
      this.resetData();
      this.error = 'No insight data available';
      return false;
    }

    if (!this.insightData[0]) {
      this.resetData();
      this.error = 'Invalid insight data structure';
      return false;
    }

    return true;
  }

  private resetData() {
    this.insightProperties = [];
    this.name = '';
    this.description = '';
    this.isActive = false;
  }

  private processInsightData(insightData: any): InsightProperty[] {
    return Object.entries(insightData).map(([key, value]) => ({
      title: this.formatTitle(key),
      description: '', // You might want to add descriptions based on your needs
      value: value as string | number | undefined,
    }));
  }

  private formatTitle(key: string): string {
    // Convert camelCase to Title Case with spaces
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase())
      .trim();
  }
}
