<div
  class="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 pb-1"
>
  <div
    class="p-4 cursor-pointer"
    (click)="toggleExpanded()"
  >
    <div class="flex justify-between items-start">
      <div>
        <h3 class="text-lg font-semibold text-grey-900">
          {{ insightModel().name }}
        </h3>
      </div>
      <!-- Chevron icon that rotates -->
      <svg
        class="w-5 h-5 transform transition-transform duration-200"
        [class.rotate-180]="expanded"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>

    <div class="mt-2 flex gap-2">
      <div class="text-sm text-grey-600">
        Fields: {{ insightModel().schemaFields.length }}
      </div>
    </div>
  </div>

  <!-- Schema Fields Table with animation -->
  <div [@expandCollapse]="expanded ? 'expanded' : 'collapsed'">
    <div class="overflow-hidden m-4 rounded-md border border-grey-300">
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-grey-200">
          <thead class="bg-grey-50">
            <tr>
              <th
                scope="col"
                class="px-3 py-2 text-left text-xs font-medium text-grey-500 uppercase tracking-wider"
              >
                Field Name
              </th>
              <th
                scope="col"
                class="px-3 py-2 text-left text-xs font-medium text-grey-500 uppercase tracking-wider"
              >
                Type
              </th>
              <th
                scope="col"
                class="px-3 py-2 text-left text-xs font-medium text-grey-500 uppercase tracking-wider"
              >
                Required
              </th>
              <th
                scope="col"
                class="px-3 py-2 text-left text-xs font-medium text-grey-500 uppercase tracking-wider"
              >
                Array
              </th>
              <th
                scope="col"
                class="px-3 py-2 text-left text-xs font-medium text-grey-500 uppercase tracking-wider"
              >
                Default
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-grey-200">
            @for (field of insightModel().schemaFields; track field) {
            <tr class="hover:bg-grey-50">
              <td
                class="px-3 py-2 whitespace-nowrap text-sm font-medium text-grey-900"
              >
                {{ field.field }}
              </td>
              <td class="px-3 py-2 whitespace-nowrap text-sm text-grey-600">
                <span
                  class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium"
                  [ngClass]="{
                    'bg-primary-100 text-primary-800': field.type === 'String',
                    'bg-green-100 text-green-800': field.type === 'Number',
                    'bg-yellow-100 text-yellow-800': field.type === 'Boolean',
                    'bg-purple-100 text-purple-800': field.type === 'ObjectID',
                    'bg-pink-100 text-pink-800': field.type === 'Date',
                    'bg-grey-100 text-grey-800': ![
                      'String',
                      'Number',
                      'Boolean',
                      'ObjectID',
                      'Date'
                    ].includes(field.type)
                  }"
                >
                  {{ field.type }}
                  @if (field.arrayElementType) {
                  <span class="ml-1">of {{ field.arrayElementType }}</span>
                  }
                </span>
              </td>
              <td class="px-3 py-2 whitespace-nowrap text-sm text-grey-600">
                @if (field.isRequired) {
                <svg
                  class="w-5 h-5 text-success-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                } @else {
                <span class="text-grey-400">-</span>
                }
              </td>
              <td class="px-3 py-2 whitespace-nowrap text-sm text-grey-600">
                @if (field.isArray) {
                <svg
                  class="w-5 h-5 text-primary-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                } @else {
                <span class="text-grey-400">-</span>
                }
              </td>
              <td class="px-3 py-2 whitespace-nowrap text-sm text-grey-600">
                @if (field.defaultValue !== undefined) {
                <span class="font-mono text-xs">{{ field.defaultValue }}</span>
                } @else {
                <span class="text-grey-400">-</span>
                }
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
