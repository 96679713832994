import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, firstValueFrom, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { BrowserService } from './browser.service';
import { PaymentService } from './payment.service';

interface AuthState {
  email: string;
  isLoggedIn: boolean;
  isSubscribed: boolean;
  role: 'user' | 'admin';
}

interface JwtVerificationResponse {
  email: string;
  role: 'user' | 'admin';
}

interface MagicLinkResponse {
  message: string;
  magicLink: string;
}

interface VerificationResponse {
  token?: string;
  user?: any;
}

interface AdminVerificationResponse extends VerificationResponse {
  authorized: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private readonly _API_URL = environment.apiUrl;
  private readonly _STORAGE_KEY = 'auth_state';

  private readonly _defaultState: AuthState = {
    email: '',
    isLoggedIn: false,
    isSubscribed: false,
    role: 'user'
  };

  private _authStateSubject = new BehaviorSubject<AuthState>(this._loadInitialState());
  authState$ = this._authStateSubject.asObservable();

  constructor(
    private _router: Router,
    private _httpClient: HttpClient,
    private _browserService: BrowserService,
    private _paymentService: PaymentService
  ) {
    // Initialize auth state when service is created
    this._initializeAuthState();
  }

  ngOnDestroy() {
    this._authStateSubject.complete();
  }

  getLatestAuthState(): AuthState {
    return this._authStateSubject.value;
  }

  private _loadInitialState(): AuthState {
    try {
      const savedState = this._browserService.getLocalStorage(this._STORAGE_KEY);
      return savedState ? JSON.parse(savedState) : this._defaultState;
    } catch {
      return this._defaultState;
    }
  }

  private async _initializeAuthState() {
    // Try to verify token on service initialization
    try {
      await this.loginWithToken();
    } catch {
      this._updateAuthState(this._defaultState);
    }
  }

  private _updateAuthState(state: Partial<AuthState>) {
    const currentState = this._authStateSubject.value;
    const newState = { ...currentState, ...state };
    this._authStateSubject.next(newState);
    this._browserService.setLocalStorage(this._STORAGE_KEY, JSON.stringify(newState));
  }

  async sendMagicLink(email: string) {
    try {
      const response = await firstValueFrom(
        this._httpClient.post<MagicLinkResponse>(
          `${this._API_URL}/api/auth/request-otp-link`,
          { email },
          { withCredentials: true }
        )
      );
      return response;
    } catch (error) {
      console.error('Error sending magic link:', error);
      this._updateAuthState({ email: '', isLoggedIn: false });
      throw error;
    }
  }

  async loginWithToken(): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this._httpClient.get<JwtVerificationResponse>(
          `${this._API_URL}/api/auth/me`,
          {
            withCredentials: true,
            headers: {
              'X-Requested-With': 'XMLHttpRequest'
            }
          }
        ).pipe(
          catchError(error => {
            if (error.status === 401) {
              return of(null);
            }
            throw error;
          })
        )
      );

      if (response) {
        this._updateAuthState({
          email: response.email,
          isLoggedIn: true,
          role: response.role
        });
        await this.updateSubscriptionStatus();
        return true;
      }

      this._updateAuthState(this._defaultState);
      return false;
    } catch (error) {
      this._updateAuthState(this._defaultState);
      throw error;
    }
  }

  async loginWithOTP(email: string, otp: string) {
    try {
      const response = await firstValueFrom(
        this._httpClient.post<VerificationResponse>(
          `${this._API_URL}/api/auth/verify-otp`,
          { email, otp },
          { withCredentials: true }
        )
      );

      this._updateAuthState({
        email,
        isLoggedIn: true
      });

      await this.updateSubscriptionStatus();
      return response;
    } catch (error) {
      console.error('Error verifying OTP:', error);
      this._updateAuthState(this._defaultState);
      throw error;
    }
  }

  async isAdmin(): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this._httpClient.get<AdminVerificationResponse>(
          `${this._API_URL}/api/admin/auth`,
          { withCredentials: true }
        )
      );

      return response.authorized;

    } catch (error) {
      console.error('Error verifying admin:', error);
    }

    return false;
  }

  async loginWithPassword(email: string, password: string) {
    try {
      const response = await firstValueFrom(
        this._httpClient.post<VerificationResponse>(
          `${this._API_URL}/api/auth/admin/login`,
          { email, password },
          { withCredentials: true }
        )
      );

      if (response) {
        this._updateAuthState({
          email,
          isLoggedIn: true,
          role: 'admin'
        });
      }

      return response;

    } catch (error) {
      console.error('Error verifying OTP:', error);
      this._updateAuthState(this._defaultState);
      throw error;
    }
  }

  async logout() {
    try {
      await firstValueFrom(
        this._httpClient.post(
          `${this._API_URL}/api/auth/logout`,
          {},
          { withCredentials: true }
        )
      );

      this._updateAuthState(this._defaultState);
      await this._router.navigate(['/']);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  }

  async updateSubscriptionStatus() {
    try {
      const { subscribed } = await this._paymentService.getUserSubscription(this.getLatestAuthState().email);
      this._updateAuthState({ isSubscribed: subscribed });
    } catch (error) {
      console.error('Error updating subscription status:', error);
    }
  }
}
