import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ConsultantService } from '~/app/services/consultant/consultant.admin.service';
import { Consultant, ConsultantInput, ConsultantUpdate } from '~/app/services/consultant/consultant.types';

interface ConsultantForm {
  _id: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  visualPrompt: FormControl<string>;
}

@Component({
  selector: 'app-consultant-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule
  ],
  templateUrl: './consultant-form.component.html',
  styleUrl: './consultant-form.component.scss'
})
export class ConsultantFormComponent implements OnInit, OnDestroy {
  private _consultantService = inject(ConsultantService);
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);

  isEditing = false;
  currentConsultant = this._consultantService.selectedConsultant$;

  consultantForm = new FormGroup<ConsultantForm>({
    _id: new FormControl('', { nonNullable: true }),
    name: new FormControl('', { nonNullable: true }),
    description: new FormControl('', { nonNullable: true }),
    visualPrompt: new FormControl('', { nonNullable: true })
  });

  async ngOnInit() {
    if (!this.currentConsultant()) {
      const id = this._route.snapshot.paramMap.get('id');
      if (id) {
        const loadedConsultant = await this._consultantService.getConsultantById(id);
        if (loadedConsultant) {
          this.setConsultantForm(loadedConsultant);
        }
      }
    } else {
      this.setConsultantForm(this.currentConsultant() as Consultant);
    }
  }

  ngOnDestroy() {
    this._consultantService.setSelectedConsultant(null);
  }

  setConsultantForm(consultant: Consultant) {
    this.consultantForm.patchValue({
      _id: consultant._id,
      name: consultant.name,
      description: consultant.description,
      visualPrompt: consultant.visualPrompt || ''
    });

    this.isEditing = true;
  }

  async onSubmit() {
    if (!this.consultantForm.valid) return;

    const formData = this.consultantForm.getRawValue();

    if (!this.isEditing) {
      // Create new consultant
      const newConsultant: ConsultantInput = {
        name: formData.name,
        description: formData.description,
        visualPrompt: formData.visualPrompt || undefined
      };

      const newConsultantResponse = await this._consultantService.createConsultant(newConsultant);
      if (!newConsultantResponse) {
        this.notifyUser('Failed to create consultant');
        return;
      }

      this.notifyUser('Consultant created successfully', 'success');
      setTimeout(async () => {
        await this._router.navigate(['../', newConsultantResponse._id], { relativeTo: this._route });
      }, 4000);

      this._consultantService.setSelectedConsultant(newConsultantResponse);
    } else {
      // Edit existing consultant
      const updateData: ConsultantUpdate = {
        name: formData.name,
        description: formData.description,
        visualPrompt: formData.visualPrompt || undefined
      };

      const editResponse = await this._consultantService.editConsultant(formData._id, updateData);
      if (!editResponse) {
        this.notifyUser('Failed to edit consultant');
        return;
      }

      this._consultantService.setSelectedConsultant(editResponse);
      this.notifyUser('Consultant saved successfully', 'success');
    }
  }

  showToast = false;
  toastConfig = {
    message: '',
    type: 'error' as 'error' | 'success'
  }

  notifyUser(message: string, type: 'error' | 'success' = 'error') {
    this.showToast = true;
    this.toastConfig = { message, type };
    setTimeout(() => {
      this.showToast = false;
      this.toastConfig = { message: '', type: 'error' };
    }, 3000);
  }
}
