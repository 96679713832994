<router-outlet></router-outlet>

@if (!isEditingOrCreating()) {
<div class="container mx-auto p-4">
  <!-- Header -->
  <div class="flex justify-between items-center mb-6">
    <h1 class="text-2xl font-bold">Assistants</h1>
    <div class="flex items-center gap-2">
      <button
        class="px-4 py-2 text-sm font-medium text-secondary-default bg-primary-default rounded-md hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        (click)="addNewAssistant()"
      >
        Add Assistant
      </button>
      <button
        class="px-4 py-2 text-sm font-medium text-grey-700 bg-white border border-grey-300 rounded-md hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        [disabled]="loading()"
        (click)="refreshData()"
      >
        <lucide-angular
          [img]="refresh"
          class="h-4"
          [class.animate-spin]="loading()"
        />
      </button>
    </div>
  </div>

  <!-- Error State -->
  @if (error()) {
    <app-error-display [error]="error() || ''" (retry)="refreshData()"/>
  }

  <!-- Loading State -->
  @if (loading()) {
  <div class="flex justify-center p-4">
    <lucide-angular [img]="refresh" class="animate-spin" />
  </div>
  } @else {
  <!-- Empty State -->
  @if (assistants().length === 0) {
    <app-empty-state title="No Assistants Found" (retry)="refreshData()" />
  } @else {
  <!-- Assistants List -->
  <div class="grid gap-4 px-4">
    @for (assistant of assistants(); track assistant._id) {
    <div
      class="bg-white rounded-lg shadow-lg hover:shadow-md transition-all duration-200"
    >
      <div class="p-6">
        <div class="flex justify-between items-start">
          <div class="space-y-2">
            <h3 class="text-xl font-bold text-grey-900">
              {{ assistant.name }}
            </h3>
            <p class="italic text-xs text-grey-600">{{ assistant.description }}</p>

            <div class="flex items-center font-medium gap-2 text-sm text-grey-500">
              <span>Insight Model: {{ assistant.targetInsightModelName }}</span>
            </div>
          </div>

          <div class="flex gap-2">
            <button
              (click)="editAssistant(assistant)"
              class="text-grey-400 hover:text-primary-600 transition-colors"
            >
              <lucide-angular
                [img]="edit"
                class="h-4"
              />
            </button>
            <button
              (click)="deleteAssistant(assistant._id)"
              class="text-grey-400 hover:text-danger-600 transition-colors"
            >
              <lucide-angular
                [img]="delete"
                class="h-4"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    }
  </div>
  } }
</div>
}
