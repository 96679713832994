import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { take, map, from, switchMap } from 'rxjs';
import { isPlatformServer } from '@angular/common';

export const adminGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);

  // During SSR, allow the route to render without checking admin status
  if (isPlatformServer(platformId)) {
    return true;
  }

  if (await authService.isAdmin()) {
    return true;
  } else {
    router.navigate(['/admin/login']);
    return false;
  }
};

export const adminRedirectGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  // First, trigger a token check and wait for it
  return from(authService.loginWithToken()).pipe(
    // After token check, get latest auth state
    switchMap(() => authService.authState$),
    take(1),
    map(authState => {

      if (authState.isLoggedIn) {

        if (authState.role === 'admin') {
          return router.createUrlTree(['/admin/dashboard']);
        }

        return router.createUrlTree(['/dashboard']);
      }

      return true;
    })
  );
};

