<section class="flex flex-col py-2 min-w-[240px] w-[569px] max-md:max-w-full gap-4">
  @if (loading) {
    <div class="flex flex-col items-center justify-center py-8 space-y-4">
      <!-- You might want to replace this with a proper loading spinner component -->
      <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-grey-900"></div>
      <p class="text-sm text-zinc-500">Loading insights...</p>
    </div>
  } @else if (error) {
    <div class="flex flex-col space-y-4">
      @if (currentModelName) {
        <h2 class="font-bold text-lg">{{ currentModelName | camelCaseToSpaces }}</h2>
        <p class="text-sm">
          Get started by by messaging in the chat.
        </p>
      }
    </div>
  } @else if (!currentModelName) {
    <div class="flex flex-col items-center justify-center py-8 space-y-4">
      <div class="rounded-full bg-zinc-100 p-4">
        <!-- You might want to use an icon here -->
        <span class="text-zinc-400">?</span>
      </div>
      <p class="text-sm text-zinc-500">Select an insight type to begin</p>
    </div>
  } @else if (insightProperties.length === 0) {
    <div class="flex flex-col items-center justify-center py-8 space-y-4">
      <div class="rounded-full bg-zinc-100 p-4">
        <!-- You might want to use an icon here -->
        <span class="text-zinc-400">0</span>
      </div>
      <p class="text-sm text-zinc-500">No properties available for this insight</p>
    </div>
  } @else {
    <h2 class="text-lg font-bold">{{ name }}</h2>

    @if (description) {
      <p class="text-sm text-zinc-500">{{ description }}</p>
    }

    @for (property of insightProperties; track property.title; let i = $index) {
      <app-insight-property-card
        [cardId]="'insight-card-' + i"
        [title]="property.title"
        [value]="property.value"
        [description]="property.description"
        [class.mt-6]="i > 0">
      </app-insight-property-card>
    }
  }
</section>
