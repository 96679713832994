<div class="p-6 max-w-2xl mx-auto">
  @if (showToast) {
    <div
      class="px-4 py-2 rounded my-2"
      [ngClass]="toastConfig.type === 'success' ?
        'bg-success-50 text-success-700 border border-success-500' :
        'bg-danger-50 text-danger-700 border border-danger-500'"
    >
      {{ toastConfig.message }}
    </div>
  }

  <div class="mb-4">
    <button [routerLink]="['../']" class="text-xs text-primary-default hover:underline">
      Back
    </button>
  </div>

  <form [formGroup]="consultantForm" (ngSubmit)="onSubmit()" class="space-y-8">
    <!-- Name Field -->
    <div class="space-y-2">
      <label for="name" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        Name
      </label>
      <input
        id="name"
        formControlName="name"
        placeholder="Enter consultant name"
        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
    </div>

    <!-- Description Field -->
    <div class="space-y-2">
      <label for="description" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        Description
      </label>
      <textarea
        id="description"
        formControlName="description"
        placeholder="Enter description"
        rows="3"
        class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      ></textarea>
    </div>

    <!-- Visual Prompt Field -->
    <div class="space-y-2">
      <label for="visualPrompt" class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        Visual Prompt
      </label>
      <textarea
        id="visualPrompt"
        formControlName="visualPrompt"
        placeholder="Enter visual prompt (optional)"
        rows="4"
        class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      ></textarea>
    </div>

    <!-- Submit Button -->
    <div class="flex justify-end">
      <button
        type="submit"
        [disabled]="!consultantForm.valid || consultantForm.pristine"
        class="inline-flex bg-primary-default text-secondary-default items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
      >
        {{ isEditing ? 'Save Changes' : 'Create Consultant' }}
      </button>
    </div>
  </form>
</div>
