<div style="margin: 2rem;">
  <hlm-dialog (closed)="closeDialog()" 
    style="padding: 20px; background: white; border-radius: 10px; max-width: 400px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
    
    <!-- Heading -->
    <h2 style="text-align: center; font-size: 1.5rem; font-weight: bold; margin-bottom: 1.5rem; color: rgb(0, 122, 255);">
      Let's onboard your business!
    </h2>

    <!-- Form -->
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      
      <!-- Business Name -->
      <div style="margin-bottom: 2rem;">
        <hlm-hint style="display: block; margin-bottom: 5px; color: #555; font-size: 0.9rem;">
          Enter your business name
        </hlm-hint>
        <hlm-form-field>
          <input hlmInput type="text" formControlName="name" placeholder="Business Name" 
            style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; font-size: 1rem;">
        </hlm-form-field>
      </div>

      <!-- Business Description -->
      <div style="margin-bottom: 2rem;">
        <hlm-hint style="display: block; margin-bottom: 5px; color: #555; font-size: 0.9rem;">
          Briefly describe your business
        </hlm-hint>
        <hlm-form-field>
          <textarea hlmInput formControlName="description" placeholder="Business Description" 
            style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; font-size: 1rem;"></textarea>
        </hlm-form-field>
      </div>

      <!-- Buttons -->
      <div style="display: flex; justify-content: space-between; gap: 1rem; width: 100%;">
        <button type="submit" [disabled]="form.invalid" 
          style="background-color: #007bff; color: white; padding: 10px 20px; border: none; cursor: pointer; border-radius: 5px; flex: 1;">
          Submit
        </button>
        <button type="button" (click)="closeDialog()" 
          style="background-color: transparent; color: black; padding: 10px 20px; border: 1px solid #ccc; cursor: pointer; border-radius: 5px; flex: 1;">
          Cancel
        </button>
      </div>

    </form>
  </hlm-dialog>
</div>
