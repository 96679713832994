<!-- This code was generated by Builder.io -->
<nav class="flex flex-col justify-between w-14 bg-white border border-solid border-zinc-200 min-h-[588px] max-md:hidden">
  <div class="flex flex-col justify-center p-2 w-full border-b border-zinc-200">
    <button class="flex gap-2 justify-center items-center px-3 w-10 h-10 bg-white rounded-md border border-solid border-zinc-200 min-h-[40px]">
      <img src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/927791ca787874ac979df09d59ff6a57e02bad3ad200e1f448a078c5bdab6f7c?apiKey=2657c647a72a40e5bd9f8e334f049b96&" alt="" class="object-contain self-stretch my-auto w-4 aspect-square">
    </button>
  </div>

  @if (menuItems) {
    <app-menu [menuItems]="menuItems"></app-menu>
  }

  <div class="flex flex-col p-2 w-full">
    <button class="flex gap-2 justify-center items-center p-2.5 w-full rounded-full min-h-[40px]">
      <img src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/21464c266f2c8f83cd0c4dcdc1c51973341abd735cc94ade30e766fdc26f31e9?apiKey=2657c647a72a40e5bd9f8e334f049b96&" alt="" class="object-contain self-stretch my-auto w-5 aspect-square">
    </button>
    <button class="flex gap-2 justify-center items-center p-2.5 mt-1 w-full rounded-full min-h-[40px]">
      <img src="https://cdn.builder.io/api/v1/image/assets/2657c647a72a40e5bd9f8e334f049b96/36939c3b8d291c4f3355db833810339fe419f6f0267940d8eba4e7f10675050d?apiKey=2657c647a72a40e5bd9f8e334f049b96&" alt="" class="object-contain self-stretch my-auto w-5 aspect-square">
    </button>
  </div>

</nav>
