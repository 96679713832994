import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../base.component';
import { LucideAngularModule, Menu } from 'lucide-angular';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TitleService } from '../../services/title/title.service';
import { HeaderMenuComponent } from '../header/header-menu/header-menu.component';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    LucideAngularModule,
    RouterLink,
    HeaderMenuComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  isMenuOpen = false;
  currentTitle: string = '';
  private _clickListener?: () => void;
  protected readonly authState$;
  readonly menuIcon = Menu;
    
  constructor(
    private readonly _authService: AuthService,
    private readonly _titleService: TitleService,
    private readonly _elementRef: ElementRef,
    private readonly _renderer: Renderer2
  ) {
    super();
    this.authState$ = this._authService.authState$;
  }

  ngOnInit() {
    this._titleService.title$.subscribe({
      next: (title) => {
        this.currentTitle = title.replace(/^Blueprint\s\|\s/, '');
      },
      error: (err) => console.error('Error receiving title:', err)
    });
  }

  override ngOnDestroy() {
    this.removeClickListener();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      this.startClickOutsideListener();
    } else {
      this.removeClickListener();
    }
  }

  private startClickOutsideListener() {
    this._clickListener = this._renderer.listen('document', 'click', (event) => {
      if (this.isMenuOpen && !this._elementRef.nativeElement.contains(event.target)) {
        this.isMenuOpen = false;
        this.removeClickListener();
      }
    });
  }

  private removeClickListener() {
    if (this._clickListener) {
      this._clickListener();
      this._clickListener = undefined;
    }
  }
}
