import { Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LucideAngularModule, User, Bot, Check, Edit, Cross, X } from 'lucide-angular';
import { Message } from '../../../types/message';
import { AssistantService } from '../../../services/assistant/assistant.user.service';
import { Assistant } from '../../../types/assistant';
import { AuthService, Role } from '../../../services/auth.service';
import { BaseComponent } from '../../base.component';
import { ChatService } from '~/app/services/chat.service';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, DatePipe],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent extends BaseComponent {
  // lucide icons
  readonly UserIcon = User;
  readonly BotIcon = Bot;
  readonly CheckIcon = Check;
  readonly EditIcon = Edit;
  readonly CancelIcon = X;

  @Input() message!: Message;
  @Input() latestMessage!: boolean;
  @Input() currentAssistant!: Assistant;

  role!: Role;
  isEditing = false;

  constructor(
    private assistantService: AssistantService,
    private authService: AuthService,
    private chatService: ChatService
  ) {
    super();
    this.subscribe(this.authService.authState$, authState => this.role = authState.role);
  }

  getAssistantColors(assistantId: string) {
    return this.assistantService.getAssistantColors(assistantId);
  }

  async editMessage(message: string) {
    const original = this.message.message;
    this.message.message = message;
    this.chatService.editMessage({ original, edited: message });
    this.isEditing = false;
  }
  
  isValidDate(date: any): boolean {
    return !isNaN(new Date(date).getTime());
  }
}
