import { APP_INITIALIZER, ApplicationConfig, EnvironmentInjector, inject, PLATFORM_ID, provideZoneChangeDetection, runInInjectionContext } from '@angular/core';
import { provideRouter } from '@angular/router';
import {provideTranslateService, TranslateLoader} from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '../environments/environment';

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './i18n/', '.json');

function initializeBrowserFeatures(injector: EnvironmentInjector) {
  return () => {
    return runInInjectionContext(injector, () => {
      const platformId = inject(PLATFORM_ID);

      if (isPlatformBrowser(platformId)) {
        // Handle unhandled promise rejections
        window.addEventListener('unhandledrejection', (event) => {
          console.error('Unhandled promise rejection:', event.reason);
        });

        // Handle runtime errors
        window.addEventListener('error', (event) => {
          console.error('Runtime error:', {
            message: event.message,
            filename: event.filename,
            lineno: event.lineno,
            colno: event.colno,
            error: event.error
          });
        });
      }
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withFetch()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeBrowserFeatures,
      deps: [EnvironmentInjector],
      multi: true
    },
    provideNgxStripe(environment.stripePublishableKey),
    provideTranslateService({
      defaultLanguage: 'en',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    })
  ],
};
