import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, firstValueFrom, Observable, pipe, throwError } from 'rxjs';
import { Business } from './business.types';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  private readonly _API_URL = environment.apiUrl;
  
  private _focusedBusinessSubject = new BehaviorSubject<Business | null>(null);

  get focusedBusiness$(): Observable<Business | null> {
    return this._focusedBusinessSubject.asObservable();
  }

  setFocusedBusiness(business: Business): void {
    this._focusedBusinessSubject.next(business);
  }

  constructor(
    private _httpClient: HttpClient
  ) { }

  async getUserBusinesses(): Promise<any> {
    const response = await firstValueFrom(
      this._httpClient.get<Business[]>(`${this._API_URL}/api/v1/business`, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error getting user businesses:', error);
            return throwError(() => error);
          })
        )
    );
    
    const business = response[0];
    this.setFocusedBusiness(business);

    return response;
  }

}
