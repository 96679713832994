import { Injectable } from '@angular/core';
import { Assistant, assistants } from '../../types/assistant';
import { COLOR_SCHEMES, DEFAULT_COLOR } from '../../types/color-scheme';
import { Message } from '../../types/message';
import { BehaviorSubject } from 'rxjs';
import { CamelCaseToSpacesPipe } from '~/app/pipes/camel-case-to-spaces.pipe';

@Injectable({
  providedIn: 'root'
})
export class AssistantService {
  private assistants = new BehaviorSubject<Assistant[]>([]);
  assistants$ = this.assistants.asObservable();

  constructor() {
    this.loadAssistants();
  }

  async loadAssistants() {
    // TODO: replace this with API call
    this.assistants.next(assistants);
  }

  getAssistantById(assistantId: string) {
    return this.assistants.value.find(({ id }) => id === assistantId);
  }

  getAssistantColors(assistantId: string) {
    const assistant = this.getAssistantById(assistantId);
    return COLOR_SCHEMES[assistant?.color || DEFAULT_COLOR];
  }

  async getAssistantHistory(insightType: string | null): Promise<Message[]> {
    // TODO: replace this with API call to get most recent conversation with assistant based on insightType
    if (!insightType) {
      return [
        {
          role: "bot",
          message: "Hello! I'm your personal business assistant.",
          timestamp: new Date()
        },
        {
          role: "bot",
          message: "Once you pick an area of your business to focus on we can get started.",
          timestamp: new Date
        }
      ];
    }

    const history: Message[] = [
      {
        role: "bot",
        message: `Let's dive in!`,
        timestamp: new Date()
      },
      {
        role: "bot",
        message: `Are you ready to talk about your ${new CamelCaseToSpacesPipe().transform(insightType)}?`,
        timestamp: new Date()
      }
    ];
    return history;
  }

  async getLastUsedAssistant() {
    // TODO: replace this with API call
    const randomNumber = Math.floor(Math.random() * this.assistants.value.length);
    return this.assistants.value[randomNumber];
  }
}
