import { Component, computed, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RouterModule,
  Router,
  ActivatedRoute,
  NavigationEnd,
} from '@angular/router';
import {
  LucideAngularModule,
  EditIcon,
  RefreshCwIcon,
  Trash2Icon,
} from 'lucide-angular';
import { ConsultantService } from '~/app/services/consultant/consultant.admin.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs/operators';
import type { Consultant } from '~/app/services/consultant/consultant.types';
import { EmptyStateComponent } from '../components/empty-state/empty-state.component';
import { ErrorDisplayComponent } from '../components/error-display/error-display.component';

@Component({
  selector: 'app-admin-consultants',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LucideAngularModule,
    EmptyStateComponent,
    ErrorDisplayComponent,
  ],
  templateUrl: './admin-consultants.component.html',
})
export class AdminConsultantsComponent implements OnInit {
  // Private dependencies
  private readonly _consultantService = inject(ConsultantService);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  // Public icons for template
  readonly icons = {
    refresh: RefreshCwIcon,
    edit: EditIcon,
    delete: Trash2Icon,
  };

  // Public signals for template
  readonly consultants = computed(() => this._consultantService.data());
  readonly isLoading = computed(() => this._consultantService.loading());
  readonly error = computed(() => this._consultantService.error());

  private checkEditingState(): boolean {
    const childRoute = this._route.firstChild;
    return (
      !!childRoute &&
      (childRoute.snapshot.url[0]?.path === 'new' ||
        !!childRoute.snapshot.paramMap.get('id'))
    );
  }

  readonly isEditingOrCreating = toSignal(
    this._router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(() => this.checkEditingState()),
      startWith(this.checkEditingState()) // Use startWith instead of merge
    ),
    { initialValue: this.checkEditingState() }
  );

  async ngOnInit() {
    await this.refresh();
  }

  async refresh(forceRefresh = true) {
    try {
      await this._consultantService.getConsultants(forceRefresh);
    } catch (error) {
      // Error handling is managed by the service
    }
  }

  createNew() {
    this._router.navigate(['./new'], { relativeTo: this._route });
  }

  edit(consultant: Consultant) {
    this._consultantService.setSelectedConsultant(consultant);
    this._router.navigate(['./', consultant._id], { relativeTo: this._route });
  }

  async delete(id: string) {
    if (await this.confirmDelete()) {
      await this._consultantService.deleteConsultant(id);
      await this.refresh();
    }
  }

  private confirmDelete(): Promise<boolean> {
    return Promise.resolve(
      window.confirm('Are you sure you want to delete this consultant?')
    );
  }
}
