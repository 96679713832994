import { Component } from '@angular/core';
import { BuilderContext } from '@builder.io/sdk-angular';
import { environment } from '../environments/environment';
import { TranslateService } from "@ngx-translate/core";
import { HeaderComponent } from './components/header/header.component';
import { RouterOutlet } from '@angular/router';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'plan-app';
  currentTitle: string = '';

  constructor(
    private builderContext: BuilderContext,
    private translate: TranslateService
  ) {
    this.builderContext.apiKey = environment.builderApiKey;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
}
